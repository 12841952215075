import { useState, useEffect } from "react";

export const useViewportWidth = () => {
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    
    const handleResize = e => setViewportWidth(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
        //eslint-disable-next-line
    }, [])

    return viewportWidth;
}