import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// Contexts
import { GlobalProvider } from './data/GlobalContext';

// Styles
import './scss/bootstrap-theme.scss';
import './scss/hamburger-theme.scss';
import './scss/react-toastify-theme.scss';
import './scss/base.scss';
import './scss/utility.scss';
import './scss/svg.scss';

ReactDOM.render(
  <React.StrictMode>
    <GlobalProvider>
      <App />
    </GlobalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
