import React, { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';

// Actions
import {
    UPDATE_DATA,
    UPDATE_LAST_SEEN
} from '../data/actions/PropertyActions';

// Hooks
import useAxiosGet from '../hooks/useAxiosGet';

// Utility
import { isRecentlyPublished } from '../utility/IsRecentlyPublished';
import { formatPublished } from '../utility/formatPublished';

// Components
import PropertyGallery from '../components/sections/PropertyGallery';
import InnerPageContent from '../components/sections/InnerPageContent';
import LastSeenProperties from '../components/sections/LastSeenProperties';
import PropertyExtras from '../components/PropertyExtras';
import PropertyPrice from '../components/PropertyPrice';
import InquiryForm from '../components/InquiryForm';
import MortgageCalculator from '../components/MortgageCalculator';
import Loading from '../components/Loading';
import Video from '../components/Video';

// Context
import { PropertyContext } from '../data/PropertyContext';

// Styles
import '../scss/property.scss';

const Property = ({ id }) => {
    const { data, lastSeen, updatePropertyState } = useContext(PropertyContext);
    const { isLoading, response } = useAxiosGet(`${process.env.REACT_APP_API_PROPERTIES}/${id}`);

    useEffect(() => {
        console.log(response);
        if (response) {
            const isOfferNew = isRecentlyPublished(response.data.published);
            const propertyData = { ...response.data, propertyImages: Object.values(response.data.propertyImages), newOffer: isOfferNew }
            updatePropertyState(UPDATE_DATA, propertyData);
        }

        return () => updatePropertyState(UPDATE_DATA, {
            designations: [],
            heatingTypes: [],
            interiorTypes: [],
            viewDirections: [],
        });
        //eslint-disable-next-line
    }, [response])

    useEffect(() => {
        if (!lastSeen.includes(id)) updatePropertyState(UPDATE_LAST_SEEN, id);
        //eslint-disable-next-line
    }, [])

    return (
        <main className="property">
            {isLoading ? null : <Helmet>
                <meta property="og:title" content={`${data.title ? data.title : 'Имот'} - Перфект Консулт`} />
                {/* {data.propertyImages.length ? <meta property="og:image" content={`${data.propertyImages[0]}`} /> : null} */}
                <meta property="twitter:title" content={`${data.title ? data.title : 'Имот'} - Перфект Консулт`} />
                {/* {data.propertyImages.length ? <meta property="twitter:image" content={`${data.propertyImages[0]}`} /> : null} */}
                <title>{`${data.title ? data.title : 'Имот'} - Перфект Консулт`}</title>
            </Helmet>}
            {isLoading ? <Loading /> : <PropertyGallery images={data.propertyImages} title={data.title} id={data.id} published={data.published} oldPrice={data.oldPrice} newOffer={data.newOffer} topOffer={data.topOffer} />}
            {isLoading ? null : <InnerPageContent asideChildren={(
                <>
                    <div className="property__price d-none d-xl-block">
                        <div className="property__price-type">{data.type}</div>
                        <PropertyPrice currentPrice={data.price} oldPrice={data.oldPrice} />
                    </div>
                    {data.id ? <InquiryForm page="Страница Детайли" title="Направете Запитване" description="За да направите запитване, моля попълнете и изпратете формата." placeholder={`Здравейте, искам да направя запитване към обява с № ${data.id} (${data.type + ', '}${data.designations.length ? (data.designations.join(', ') + ', ') : ''}${data.propertyType ? (data.propertyType + ', ') : ''}${data.sizeTotal + 'm², '}${'€' + data.price + ', '}${data.areaName + ', '}${data.subAreaName}). Моля, свържете се с мен!`} messageRows="5" /> : null}
                    {data.price > 2000 || data.type === 'Продава' ? <MortgageCalculator propertyPrice={data.price} /> : null}
                </>
            )}>
                <div className="property__info d-xl-none">
                    <div className="property__heading">
                        <PropertyExtras oldPrice={data.oldPrice} newOffer={data.newOffer} topOffer={data.topOffer} />
                        <h1 className="property__title">{data.title}</h1>
                        <div className="property__other">
                            <div className="mr-sm-2">Обява №: {id}</div>
                            <div>Публикувана: <time dateTime={data.published}>{formatPublished(data.published)}</time></div>
                        </div>
                    </div>
                    <div className="property__price">
                        <div className="property__price-type">{data.type}</div>
                        <PropertyPrice currentPrice={data.price} oldPrice={data.oldPrice} />
                    </div>
                </div>
                <div className="property__details">
                    <h2 className="property__details-heading">Детайли</h2>
                    <ul className="property__details-list ul-clean">
                        <li><span>Район:</span> {data.areaName}</li>
                        <li><span>Подрайон:</span> {data.subAreaName}</li>
                        {data.designations.length ? <li><span>Предназначение:</span> {data.designations.join(', ')}</li> : null}
                        {data.buildingType ? <li><span>Строителство:</span> {data.buildingType}</li> : null}
                        {data.buildStage ? <li><span>Етап на строителство:</span> {data.buildStage}</li> : null}
                        {data.sizeTotal ? <li><span>Квадратура:</span> {data.sizeTotal}m²</li> : null}
                        {data.buildYear ? <li><span>Година:</span> {data.buildYear}</li> : null}
                        {data.floor ? <li><span>Етаж:</span> {data.floor}{data.maxFloors ? ` от ${data.maxFloors}` : ''}</li> : null}
                        {data.interiorTypes.length ? <li><span>Обзавеждане:</span> {data.interiorTypes.join(', ')}</li> : null}
                        {data.heatingTypes.length ? <li><span>Отопление:</span> {data.heatingTypes.join(', ')}</li> : null}
                        {data.viewDirections.length ? <li><span>Изложение:</span> {data.viewDirections.join(', ')}</li> : null}
                        {data.garage ? <li><span>Гараж:</span> Да</li> : null}
                        {data.parking ? <li><span>Паркомясто:</span> Да</li> : null}
                        {data.elevator ? <li><span>Асансьор:</span> Да</li> : null}
                    </ul>
                </div>
                {data.description ? <div className="property__description">
                    <h2 className="property__description-heading">Описание</h2>
                    <p className="property__description-paragraph">{data.description}</p>
                </div> : null}
                {data.videoUrl ? <div className="property__video">
                    <Video url={data.videoUrl} controls={true} />
                </div> : null}
            </InnerPageContent>}
            <LastSeenProperties dontShowId={data.id} />
        </main>
    );
}

export default Property;
