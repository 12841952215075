import React, { useEffect, useContext } from 'react';

// Actions
import {
    UPDATE_DATA,
} from '../../data/actions/NewPropertiesActions';

// Hooks
import useAxiosGet from '../../hooks/useAxiosGet';

// Context
import { NewPropertiesContext } from '../../data/NewPropertiesContext';
import { PropertyContext } from '../../data/PropertyContext';

// Utility
import { addNewOfferData } from '../../utility/addNewOfferData';

// Components
import SectionHeading from '../SectionHeading';
import CardSlider from '../CardSlider';
import PropertyCard from '../PropertyCard';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Styles
import '../../scss/new-properties.scss';

const NewProperties = () => {
    const { data, perPage, sort, order, updateNewPropertiesState } = useContext(NewPropertiesContext);
    const { lastSeen } = useContext(PropertyContext);
    const { response } = useAxiosGet(`${process.env.REACT_APP_API_PROPERTIES}?perPage=${perPage}&sort=${sort}&order=${order}`);

    const slickSettings = {
        draggable: false,
        fade: false,
        lazyLoad: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 6000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1619,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1239,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };

    useEffect(() => {
        if (response) {
            const propertiesData = addNewOfferData(response.data.data).filter(property => property.newOffer === true);
            updateNewPropertiesState(UPDATE_DATA, propertiesData);
        }
        //eslint-disable-next-line
    }, [response]);

    if (data.length) {
        return (
            <section className="new-properties">
                <Container>
                    <Row>
                        <Col>
                            <SectionHeading className="text-center" heading="Нови Имоти" subHeading="Виж някои от нашите последни оферти." />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CardSlider settings={slickSettings} linkText="Виж Всички" linkUrl="/properties?newOffer=true">
                                {data.map(property => {
                                    return <PropertyCard
                                        key={property.id}
                                        id={property.id}
                                        type={property.type}
                                        price={property.price}
                                        oldPrice={property.oldPrice}
                                        image={property.image}
                                        title={property.title}
                                        description={property.description}
                                        sizeTotal={property.sizeTotal}
                                        buildingType={property.buildingType}
                                        designations={property.designations}
                                        published={property.published}
                                        newOffer={property.newOffer}
                                        topOffer={property.topOffer}
                                        isSeen={lastSeen.includes(property.id)}
                                    />
                                })}
                            </CardSlider>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    } else {
        return null
    }
}

export default NewProperties;
