import {
    UPDATE_DATA,
    UPDATE_FILTERS_SIZE,
    UPDATE_FILTERS_PRICE,
    UPDATE_FILTERS_YEAR,
    UPDATE_FILTERS_TYPES,
    UPDATE_FILTERS_CONSTRUCTION,
    UPDATE_FILTERS_HEATING,
    UPDATE_FILTERS_FURNISHING,
    UPDATE_FILTERS_FACING,
    UPDATE_FILTERS_STAGE,
    UPDATE_FILTERS_OTHER,
    UPDATE_SELECTED_AREA,
    UPDATE_SELECTED_SUB_AREA,
    UPDATE_OFFER_TYPE,
    UPDATE_PAGE,
    UPDATE_PER_PAGE,
    UPDATE_SORT,
    UPDATE_ORDER,
    UPDATE_SEARCH,
    CLEAR_FILTERS
} from '../actions/PropertiesActions';

const PropertiesReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_DATA:
            return {
                ...state,
                data: action.payload.data,
                total: action.payload.total || 0,
                toItem: action.payload.to || 0,
                currentPage: action.payload.current_page || 1,
                lastPage: action.payload.last_page || 1,
            }
        case UPDATE_FILTERS_SIZE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    size: action.payload
                }
            }
        case UPDATE_FILTERS_PRICE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    price: action.payload
                }
            }
        case UPDATE_FILTERS_YEAR:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    year: action.payload
                }
            }
        case UPDATE_FILTERS_TYPES:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    types: action.payload
                }
            }
        case UPDATE_FILTERS_CONSTRUCTION:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    construction: action.payload
                }
            }
        case UPDATE_FILTERS_HEATING:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    heating: action.payload
                }
            }
        case UPDATE_FILTERS_FURNISHING:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    furnishing: action.payload
                }
            }
        case UPDATE_FILTERS_FACING:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    facing: action.payload
                }
            }
        case UPDATE_FILTERS_STAGE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    stage: action.payload
                }
            }
        case UPDATE_FILTERS_OTHER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    other: action.payload
                }
            }
        case UPDATE_SELECTED_AREA:
            return {
                ...state,
                selectedArea: typeof action.payload === 'object' ? action.payload : { id: action.payload, name: returnAreaName(action.payload) }
            }
        case UPDATE_SELECTED_SUB_AREA:
            return {
                ...state,
                selectedSubAreas: action.payload
            }
        case UPDATE_OFFER_TYPE:
            return {
                ...state,
                type: action.payload
            }
        case UPDATE_PAGE:
            return {
                ...state,
                page: action.payload
            }
        case UPDATE_PER_PAGE:
            return {
                ...state,
                perPage: typeof action.payload === 'object' ? action.payload : { id: action.payload, name: `${action.payload}` }
            }
        case UPDATE_SORT:
            return {
                ...state,
                sort: typeof action.payload === 'object' ? action.payload : { id: action.payload, name: returnSortName(action.payload) }
            }
        case UPDATE_ORDER:
            return {
                ...state,
                order: typeof action.payload === 'object' ? action.payload : { id: action.payload, name: returnOrderName(action.payload) }
            }
        case UPDATE_SEARCH:
            return {
                ...state,
                search: action.payload
            }
        case CLEAR_FILTERS:
            return {
                ...state,
                filters: {
                    construction: [],
                    facing: [],
                    furnishing: [],
                    heating: [],
                    stage: [],
                    other: [],
                    types: [],
                    size: ["", ""],
                    price: ["", ""],
                    year: ["", ""],
                },
                selectedSubAreas: [],
                type: ''
            }
        default:
            return state
    }
}

export default PropertiesReducer;

const returnAreaName = id => {
    switch (id) {
        case 1:
            return 'Пловдив град'
        case 2:
            return 'Пловдив област'
        default:
            return ''
    }
}

const returnSortName = id => {
    switch (id) {
        case 'published':
            return 'Публикувано'
        case 'size_total':
            return 'Квадратура'
        case 'price':
            return 'Цена'
        case 'build_year':
            return 'Година'
        default:
            return ''
    }
}

const returnOrderName = id => {
    switch (id) {
        case 'asc':
            return 'Възходящо'
        case 'desc':
            return 'Низходящо'
        default:
            return ''
    }
}