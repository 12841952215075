import React from 'react';
import ReactPlayer from 'react-player/lazy';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';

// Styles
import '../scss/video.scss';

const Video = ({ url, controls = false }) => {
    return (
        <div className="video">
            <ReactPlayer className="react-player" url={url} controls={controls} playing={true} light playIcon={<FontAwesomeIcon icon={faYoutube}/>} width="100%" height="100%" />
        </div>
    );
}

export default Video;
