import React, { useState, useEffect, useCallback } from 'react';
//eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

// Components
import CustomCheckbox from '../CustomCheckbox';
import RadioFilter from '../RadioFilter';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Assets
import AddImagePlaceholder from '../../assets/images/add-image-placeholder.png';
import { ReactComponent as LoadingCirclesSvg } from '../../assets/images/loading-circles.svg';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

// Styles
import '../../scss/add-property-form.scss';

const AddPropertyForm = () => {
    // Images Form Logic ---------------------------------------->
    const [images, setImages] = useState([]);
    const toastOptions = { position: 'top-center', autoClose: 5000 };
    const MAX_IMG_COUNT = 10;
    const MAX_IMG_SIZE = 2621440;

    // Google Authentication

    const handleAddImage = useCallback(e => {
        const currentImagesIds = images.map(item => item.id);
        const uploadedImages = [...e.target.files];
        const uploadedImagesAddId = uploadedImages.map(img => { return { id: img.lastModified + img.size, img } });
        const uploadedImagesNoDuplicates = uniqByKeepFirst(uploadedImagesAddId, item => item.id);
        const uploadedImagesNotInState = uploadedImagesNoDuplicates.filter(item => !currentImagesIds.includes(item.id));
        const uploadedImagesOnlyAllowedSize = uploadedImagesNotInState.filter(item => item.img.size <= MAX_IMG_SIZE);

        // Notifies the user if any of the images is above the max size limit.
        if (uploadedImagesNotInState.filter(item => item.img.size > MAX_IMG_SIZE).length) {
            const uploadedImagesAboveSizeLimit = uploadedImagesAddId.filter(item => item.img.size > MAX_IMG_SIZE).map(item => item.img.name);
            if (uploadedImagesAboveSizeLimit.length === 1) {
                toast.warn(`Снимка ${uploadedImagesAboveSizeLimit} надвишава ${(MAX_IMG_SIZE / 1024) / 1024}Mb!`, toastOptions);
            } else {
                toast.warn(`Снимките ${uploadedImagesAboveSizeLimit.join(', ')} надвишават ${(MAX_IMG_SIZE / 1024) / 1024}Mb!`, toastOptions);
            }
        }
        // Notifies the user if any of the images is already added.
        if (uploadedImagesAddId.filter(item => currentImagesIds.includes(item.id)).length) {
            console.log('Already Added');
            toast.warn('Снимката вече съществува!', toastOptions);
        }
        // Notifies the user if the image upload limit is reached.
        if (uploadedImagesOnlyAllowedSize.length >= MAX_IMG_COUNT - images.length) {
            console.log('upload limit is reached');
            toast.warn(`Достигнат лимит от ${MAX_IMG_COUNT} снимки!`, toastOptions);
        }

        setImages(currImages => [...currImages, ...uploadedImagesOnlyAllowedSize.slice(0, MAX_IMG_COUNT - currImages.length)]);

        //eslint-disable-next-line
    }, [images]);

    // Event Handler for Deleting Images
    const handleDeleteImage = e => {
        const deleteButtonValue = e.target.value * 1;
        setImages(currImages => currImages.filter(item => item.id !== deleteButtonValue));
    }

    // Unique By function that keeps only the first item from dublicate pair
    const uniqByKeepFirst = (arr, key) => {
        const seen = new Set();
        return arr.filter(item => {
            const k = key(item);
            return seen.has(k) ? false : seen.add(k);
        });
    }

    // Convert file to base64 string
    // const fileToBase64 = (file) => {
    //     return new Promise(resolve => {
    //         var reader = new FileReader();
    //         // Read file content on file loaded event
    //         reader.onloadend = () => {
    //             resolve(reader.result);
    //         };
    //         // Convert data to base64 
    //         reader.readAsDataURL(file);
    //     });
    // };

    // Converts all images to base64 and combines them in objects with name and data ready for sending
    // const imageAttachmentsToBase64 = async () => {
    //     return Promise.all(images.map(async img => {
    //         return {
    //             name: img.img.name,
    //             data: await fileToBase64(img.img).then(result => result)
    //         }
    //     }))
    // }

    // Contact Form Logic ---------------------------------------->
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [learnedFrom, setLearnedFrom] = useState({});
    const [message, setMessage] = useState('');
    const [consent, setConsent] = useState(false);
    const [consentError, setConsentError] = useState(false);

    const learnedFromOptions = [
        {
            id: 1,
            name: 'Google'
        },
        {
            id: 2,
            name: 'Facebook'
        },
        {
            id: 3,
            name: 'LinkedIn'
        },
        {
            id: 4,
            name: 'Препоръка'
        },
        {
            id: 5,
            name: 'Друг Сайт'
        },
        {
            id: 6,
            name: 'Друго'
        },
    ]

    const toastID = React.useRef(null);
    const notifyToast = () => toastID.current = toast(<div className="d-flex"><LoadingCirclesSvg className="mr-1 ml-0" /> Съобщението се изпраща...</div>, { position: 'top-center', autoClose: false });
    const updateToast = (type, message) => toast.update(toastID.current, { type, render: message, position: 'top-center', autoClose: 5000 });

    const handleSubmit = async (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('message', message);
        formData.append('learnedFrom', learnedFrom.name);

        images.forEach(item => {
            formData.append('images[]', item.img);
        });

        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        if (consent) {
            notifyToast();

            axios.post(process.env.REACT_APP_API_EMAIL_ADD_PROPERTY, formData, { headers })
                .then(resp => {
                    console.log(resp);
                    updateToast('success', 'Заявката e изпратена успешно!');
                    setName('');
                    setPhone('');
                    setEmail('');
                    setMessage('');
                    setImages([]);
                }).catch(err => {
                    console.log('ADD PROPERTY ERROR:');
                    console.log(err);
                    updateToast('error', 'Възникна грешка при изпращането!');
                })
        } else {
            setConsentError(true);
        }
    }

    useEffect(() => {
        if (consent) {
            setConsentError(false);
        }
    }, [consent])

    const hanldeLearnedFrom = obj => {
        setLearnedFrom(obj);
    }

    return (
        <section className="add-property-form">
            <Container>
                <Row>
                    <Col>
                        <form className="add-property-form__form" onSubmit={handleSubmit} encType="multipart/form-data">
                            <div className="add-property-form__images-wrapper">
                                <h3 className="add-property-form__heading">Прикачете снимки <small>*Максимум {MAX_IMG_COUNT} снимки. Снимки с размер до {(MAX_IMG_SIZE / 1024) / 1024}Mb. Препоръчително хоризонтални снимки.</small></h3>
                                <div className="add-property-form__add-images">
                                    {images ? images.map(item => {
                                        return (
                                            <figure key={item.id} className="add-property-form__added-image-figure" tabIndex="0">
                                                <button className="add-property-form__delete-image" type="button" value={item.id} onClick={handleDeleteImage}><FontAwesomeIcon icon={faTrashAlt} /></button>
                                                <div className="add-property-form__added-image-name">{item.img.name}</div>
                                                <img className="add-property-form__added-image-img" src={URL.createObjectURL(item.img)} alt={item.img.name} />
                                            </figure>
                                        )
                                    }) : null}
                                    {images.length < 10 ? <label className="add-property-form__add-images-label" style={{ backgroundImage: `url('${AddImagePlaceholder}')` }}>
                                        <input className="add-property-form__add-images-input" type="file" name="images" accept=".jpg, .jpeg, .png" multiple onChange={handleAddImage} />
                                    </label> : null}
                                </div>
                            </div>

                            <div className="add-property-form__contacts-wrapper">
                                <h3 className="add-property-form__heading">Контакти</h3>
                                <div className="add-property-form__contacts-top">
                                    <input type="name" name="name" value={name} onChange={e => setName(e.target.value)} placeholder="Вашите Имена" required />
                                    <input type="tel" name="phone" value={phone} onChange={e => setPhone(e.target.value)} placeholder="Телефон" title="(XX) XXX XXXX / +XXX/XXX XXX XXX / 08X XXX XXX / 08X-XXX-XXX / 08XXXXXXX" pattern="^[0-9-+\s()]*$" required />
                                    <input type="email" name="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Имейл" required />
                                    <RadioFilter title={learnedFrom.name ? learnedFrom.name : 'Как разбрахте за нас?'} initialState={learnedFrom} options={learnedFromOptions} onSelect={hanldeLearnedFrom} />
                                </div>
                                <div className="add-property-form__contacts-bottom">
                                    <textarea name="message" value={message} onChange={e => setMessage(e.target.value)} placeholder="Здравейте, искам да обявя за продажба / под наем този имот ..." rows="5" required />
                                    <div className="add-property-form__contacts-consent-send">
                                        <CustomCheckbox title={(<>Приемам <Link to="/terms-and-conditions">Условията за ползване на Перфект Консулт</Link> и <Link to="/privacy-policy">Декларацията за поверителност</Link></>)} initSelected={consent} onCheck={() => setConsent(true)} onUncheck={() => setConsent(false)} />
                                        {consentError ? <div className="inquiry-form__error">Моля попълнете!</div> : null}
                                        <button className="btn btn--orange" type="submit"><FontAwesomeIcon icon={faPaperPlane} />Изпрати</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default AddPropertyForm;
