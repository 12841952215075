import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import LastSeenProperties from '../components/sections/LastSeenProperties';
import InnerPageTop from '../components/sections/InnerPageTop';
import InnerPageContent from '../components/sections/InnerPageContent';
import ServicePageCard from '../components/ServicePageCard';
import ServiceCard from '../components/ServiceCard';
import IconStepCard from '../components/IconStepCard';
import InquiryForm from '../components/InquiryForm';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faKey,
    faStar,
    faHouseUser,
    faCalendarAlt,
    faChartLine,
    faWrench,
    faMoneyBillWave,
    faCoins,
    faHotel,
    faInfoCircle,
    faChartBar,
    faSearchPlus,
    faMoneyCheckAlt,
    faUsers,
    faClipboardList,
    faUserTie,
    faHandshake
} from '@fortawesome/free-solid-svg-icons';

// Styles
import '../scss/services.scss';

const ForInvestors = () => {
    return (
        <main className="services for-investors">
            <Helmet>
                <title>За Инвеститори - Перфект Консулт</title>
            </Helmet>
            <InnerPageTop pageTitle="За Инвеститори" pageDescription="Увеличете шансовете за успешна продажба на вашия имот!" />
            <InnerPageContent asideChildren={(
                <>
                    <InquiryForm page="Страница За Инвеститори" title="Искаш безплатна консултация?" description="Изпрати въпросите си към нас." placeholder={`Здравейте имам въпрос относно услуга.`} messageRows="5" />
                    <ServicePageCard title="Допълнителни услуги" description="Разгледайте комплексните решения, създадени за ваше удобство." btnColor="dark" btnIcon={faStar} btnText="Допълнителни Услуги" btnTo="/services/additional" />
                    <ServicePageCard title="За собственици" description="Разгледайте комплексните решения, създадени за ваше удобство." btnColor="cyan" btnIcon={faHouseUser} btnText="За Собственици" btnTo="/services/for-owners" />
                    <ServicePageCard title="Купуваме директно" description="Най-лесният начин да продадеш имота си в няколко стъпки." btnColor="orange" btnIcon={faHandshake} btnText="Купуваме директно" btnTo="/services/we-buy-directly" />
                    <ServicePageCard title="Управление на Имот" description="Разгледайте комплексните решения, създадени за ваше удобство." btnColor="red" btnIcon={faKey} btnText="Управление на Имот" btnTo="/services/propety-control" />
                </>
            )}>
                <Row>
                    <Col className="col-12 mb-4">
                        <h2 className="mb-4 block-heading block-heading--cyan">Инвестирането в имоти не е за всеки!</h2>
                        <p><b>Да печелим от имоти, докато работим от 8:00 до 17:00ч.</b></p>
                        <p>Мечтал ли си един ден да не се налага да ходиш на работа и въпреки това да изкарваш пари всеки месец? Ако  кажеш “Не”, то тогава ще ти загубим времето. Ако тайно си го желал, отдели няколко минути и си води записки. Ползите от инвестирането в недвижими имоти са многобройни Инвестицията в имоти може да ти осигури нужния стандарт на живот, без да ти се налага да работиш и да придобиеш  активи за които не плащаш. Мислиш за инвестиране в недвижими имоти? Ето как.</p>
                        <p>Ето какво трябва да знаете за ползите от недвижими имоти и защо недвижимите имоти се считат за добра инвестиция:</p>
                    </Col>
                    {propertyInvestment.map((item, index) => (
                        <Col className="col-12 mb-4">
                            <IconStepCard className="icon-step-card--green icon-step-card--wide" number={index + 1} icon={item.icon} title={item.title} description={item.description} />
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col>
                        <p><FontAwesomeIcon className="mr-1" icon={faInfoCircle} />Винаги трябва да имате стратегия.</p>
                        <p><FontAwesomeIcon className="mr-1" icon={faInfoCircle} />Трябва да помислите над това кои ще са бъдещите ви наематели или купувачи.</p>
                        <p><FontAwesomeIcon className="mr-1" icon={faInfoCircle} />Да определите какъв наем или цена искате да получите за имота, в който инвестирате и да пресметнете каква ще е възвръщаемостта на инвестицията.</p>
                        <p><FontAwesomeIcon className="mr-1" icon={faInfoCircle} />Трябва да вземете предвид плюсовете и минусите на определени райони.</p>
                        <p><FontAwesomeIcon className="mr-1" icon={faInfoCircle} />Трябва да вземете предвид плановете за развитието на инфраструктурата.</p>
                        <p><FontAwesomeIcon className="mr-1" icon={faInfoCircle} />Трябва да имате надеждна информация и да виждате “Голямата Картина”.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12 mt-4">
                        <h2 className="block-heading block-heading--orange">Ето стъпките, които ние ще предприемем за вашия успех:</h2>
                    </Col>
                    {stepsYouNeedToTake.map(service => (
                        <Col className="col-12 col-lg-6 mb-4">
                            <ServiceCard icon={service.icon} title={service.title} description={service.description} />
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col>
                        <p><b>Кога започваме?</b></p>
                        <p>Няма да стане с щракване на пръсти. Вашето имотно портфолио, билет към финансовата независимост ви очаква. Ако следваме стъпките тук и постоянно търсим възможности за усъвършенстване, няма начин да не постигнем целта си.</p>
                        <p>Милиони преди вас са го направили. Вие по-лоши ли сте от тях?!</p>
                    </Col>
                </Row>
            </InnerPageContent>
            <LastSeenProperties />
        </main>
    );
}

export default ForInvestors;

const propertyInvestment = [
    {
        icon: faCalendarAlt,
        title: 'Доход от наем',
        description: 'Жилище, бизнес, земя. Купуваш имот, който още първия месец ти носи положителен финансов резултат, независимо дали финансираш сделката с лични или заемни средства. Така си подсигуряваш пасивен доход всеки месец. Ако не си обяснявате как - Вижте т.4.',
    },
    {
        icon: faChartLine,
        title: 'Покачване на стойността',
        description: 'Имотите са убежище на парите ти от инфлация и цените им се покачват в дългосрочен план. Независимо кога купуваш, ако задържиш актива дълго време (15-20 години), очаквай средна доходност от 4-4.5% на годишна база. Статистиката за Европа сочи 4.8% за последните 50 години, а България много по-висока.',
    },
    {
        icon: faWrench,
        title: 'Ремонтирай и продай',
        description: 'Намери имот, който се нуждае от ремонт. Купи го под пазарната му стойност. Хвани инструментите сам или наеми майстори, които да го приведат в нормален за живеене вид. Определи му цената и го продай с печалба. Ако се фокусираш и си дисциплиниран, можеш да правиш поне 3 такива сделки на година и да си докараш 30-45% възвращаемост на вложените средства.',
    },
    {
        icon: faMoneyBillWave,
        title: 'Увеличаване на чистата стойност',
        description: 'Ако закупиш инвестиционен имот със заемни средства, най-често ипотечен кредит, с всяка една вноска увеличаваш чистата стойност на актива си. И то с парите на другите хора. Така след максимум 15 години ще имаш напълно изплатен имот, който си закупил и изплатил с чужди средства.',
    },
    {
        icon: faCoins,
        title: 'Продавай на части',
        description: 'В света на имотите има правило – “колкото е по-голяма е квадратурата на един имот, толкова по-ниска става цената на квадратен метър и обратно”. Умният инвеститор купува на едро и продава на дребно, също както правят много други търговци във всички сфери на икономиката. Купи голям парцел или вече построен имот, раздели го на по-малки, но функционални части и го продай. Смени предназначението му.',
    },
    {
        icon: faHotel,
        title: 'Развий имота',
        description: 'Едно парче земя може да ражда плодове или зеленчуци, но може и да подсигурява покрив на нечий бизнес или семейство. Как? Запустелият парцел може да е основа на хиляди квадрати сграда, триетажната кооперация може да се надстрои с поне една плоча, а към съществуващата стопанска постройка може да се добавят екстра помещения.',
    },
]

const stepsYouNeedToTake = [
    {
        icon: faSearchPlus,
        title: 'Определяме Целта',
        description: 'Каква е сумата, която Ви е необходима да живеете спокойно всеки месец. 1000лв, 2000лв, 5000лв? Това е сумата, която трябва да Ви подсигурим от разумните Ви инвестиции в имоти.'
    },
    {
        icon: faMoneyCheckAlt,
        title: 'Проверяваме Финансите Ви',
        description: 'Всички спестявания, с които разполагате. Ако имате задължения, добре е първо да ги погасите. После правим консултация с наши партньори за да разберете на какъв кредит можете да разчитате и при какви условия. Ако ще правите сделки с имоти, е задължително да знаете възможностите си.'
    },
    {
        icon: faUsers,
        title: 'Създаване на екип',
        description: 'добрите оферти не са под път и над път. Намирането на изгодния имот изисква време, фокус и много работа. Сами не можете, особено когато работите от 8 до 5. Имате два варианта. Първият е да разчитате на семейството или близък приятел. Вторият вариант е да си наемете професионалист-БРОКЕР, който да свърши всичко за вас.'
    },
    {
        icon: faClipboardList,
        title: 'Правим система',
        description: 'Инвестирането в имоти си е бизнес, независимо дали го правите професионално или между другото. Създаваме план за това какво, как, от кого, кога и къде трябва да се свърши. Проектираме на лист хартия всичко от финансирането, търсенето на имота, през огледа, оценката, ремонта, продаването/отдаване, аварийните ситуации и купчината други задачи.'
    },
    {
        icon: faChartBar,
        title: 'Проучваме пазара',
        description: 'Ще придобивате, за да давате под наем или ще купувате, за да препродавате с или без ремонт. И в двата случая, трябва да познавате пазара по-добре от себе си, а ние го познаваме. Напълно наясно сме какви са продажните и наемни цени, количеството и качеството на търсене и предлагане, данъци и такси, цена на строително-монтажни дейности, цена на материали и обзавеждане.'
    },
    {
        icon: faUserTie,
        title: 'Отдаваме се на каузата',
        description: 'Както вече отбелязахме, инвестирането в имоти е бизнес и изисква професионален подход. Като всяко едно начинание, началото ще е трудно, хаотично, изнервящо, скъпо и отнема време- именно това ще спечелите, ако ни се доверите.'
    },
]