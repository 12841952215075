import React from 'react';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Styles
import '../../scss/inner-page-content.scss';

const InnerPageContent = ({ children, asideChildren }) => {
    return (
        <section className="inner-page-content">
            <Container>
                <Row>
                    <Col className="inner-page-content__main col-12 col-xl-8">
                        {children}
                    </Col>
                    {asideChildren ? <Col className="col-12 col-xl-4">
                        <aside className="inner-page-content__aside">
                            {asideChildren}
                        </aside>
                    </Col> : null}
                </Row>
            </Container>
        </section>
    );
}

export default InnerPageContent;
