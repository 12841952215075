import {
    UPDATE_DATA,
    UPDATE_LAST_SEEN
} from '../actions/PropertyActions';

const PropertyReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_DATA:
            return {
                ...state,
                data: action.payload
            }
        case UPDATE_LAST_SEEN:
            return {
                ...state,
                lastSeen: [...state.lastSeen, action.payload]
            }
        default:
            return state
    }
}

export default PropertyReducer;