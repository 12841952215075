import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import LastSeenProperties from '../components/sections/LastSeenProperties';
import InnerPageTop from '../components/sections/InnerPageTop';
import InnerPageContent from '../components/sections/InnerPageContent';
import ServicePageCard from '../components/ServicePageCard';
import InquiryForm from '../components/InquiryForm';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faStar,
    faHandshake,
    faKey,
    faUserTie,
    faPlus
} from '@fortawesome/free-solid-svg-icons';

// Styles
import '../scss/services.scss';

const ForOwners = () => {
    return (
        <main className="services">
            <Helmet>
                <title>За Собственици - Перфект Консулт</title>
            </Helmet>
            <InnerPageTop pageTitle="За Собственици" pageDescription="Увеличете шансовете за успешна продажба на вашия имот!" />
            <InnerPageContent asideChildren={(
                <>
                    <InquiryForm page="Страница За Собственици" title="Искаш безплатна консултация?" description="Изпрати въпросите си към нас." placeholder={`Здравейте имам въпрос относно услуга.`} messageRows="5" />
                    <ServicePageCard title="Допълнителни услуги" description="Разгледайте комплексните решения, създадени за ваше удобство." btnColor="dark" btnIcon={faStar} btnText="Допълнителни Услуги" btnTo="/services/additional" />
                    <ServicePageCard title="Купуваме директно" description="Най-лесният начин да продадеш имота си в няколко стъпки." btnColor="orange" btnIcon={faHandshake} btnText="Купуваме директно" btnTo="/services/we-buy-directly" />
                    <ServicePageCard title="Управление на Имот" description="Разгледайте комплексните решения, създадени за ваше удобство." btnColor="red" btnIcon={faKey} btnText="Управление на Имот" btnTo="/services/propety-control" />
                    <ServicePageCard title="За инвеститори" description="Разгледайте комплексните решения, създадени за ваше удобство." btnColor="green" btnIcon={faUserTie} btnText="За инвеститори" btnTo="/services/for-investors" />
                </>
            )}>
                <Row>
                    <Col>
                        <h2 className="mb-2">Kолко Струва Имота Ми</h2>
                        <p>Сигурно сте любопитни каква е стойността на имота Ви, независимо дали е за продажба или наем. Ние ще ви изготвим БЕЗПЛАТНА оценка.</p>
                        <p>Оценка на база характеристики и критерий?</p>
                        <p>Или консултация за максимална цена на недвижим имот?</p>
                        <p>Винаги е важно да знаете колко реално струва имотът ви при сегашните пазарни условия. интернет порталите са пълни с некоректни и фалшиви обяви, има твърде много оферти и липсва достатъчно информация, за да се ориентирате правилно. Екип от професионалисти ще изготви анализ на база реални сделки и конкурентни имоти, за да получите точна информация. Ние постигаме успех при покупко-продажбите в кратки срокове на реална пазарна цена. Процесът и осъществяването на покупко-продажба на недвижими имоти изисква специфични познания и подготовка. За да оптимизирате шансовете си за реална продажба и да сключите сделка, която удовлетворява вашите потребности. Перфект Консулт ви съветва да се доверите на специалистите в бранша.</p>
                    </Col>
                </Row>
                <Row className="advantages-list">
                    <Col className="col-12 col-md-6 mb-2 mb-md-0 advantages-list__col">
                        <h2 className="advantages-list__heading color-cyan">За Продавачите</h2>
                        <ul className="ul-clean">
                            {forSale.map(item => (
                                <li className="advantages-list__item mb-4">
                                    <h3><span className="color-cyan"><FontAwesomeIcon icon={faPlus} /></span>{item.advantage}</h3>
                                    <p>{item.description}</p>
                                </li>
                            ))}
                        </ul>
                    </Col>
                    <Col className="col-12 col-md-6 advantages-list__col">
                        <h2 className="advantages-list__heading color-green">За Наемодателите</h2>
                        <ul className="ul-clean">
                            {forRent.map(item => (
                                <li className="advantages-list__item mb-4">
                                    <h3><span className="color-green"><FontAwesomeIcon icon={faPlus} /></span>{item.advantage}</h3>
                                    <p>{item.description}</p>
                                </li>
                            ))}
                        </ul>
                    </Col>
                </Row>
            </InnerPageContent>
            <LastSeenProperties />
        </main>
    );
}

export default ForOwners;

const forSale = [
    {
        advantage: 'Спестяваме ви време',
        description: 'Брокерите ни имат време и компетенцията да се справят с огледи, срещи, да отговарят на всички запитвания за имота и дузина други задължения, които да ви  отменят в работата по продажбата на имота.'
    },
    {
        advantage: 'Умеем да продаваме',
        description: 'Брокерите ни могат да оценят имота ви и да предложат необходимите подобрения, за да вземете максималното от стойността му.'
    },
    {
        advantage: 'Цена',
        description: 'Най-добре е да изберем цена около пазарната, защото ако е много завишена, купувачите може да подминат офертата ви, а ако е много намалена – може да започнат да се чудят защо е така и дали имотът няма проблем.'
    },
    {
        advantage: 'Познаваме добре всички квартали',
        description: 'Запознати сме конкуренцията в собственият ви квартал. Знаем за всеки един имот, с всичките му характеристики, които е на пазара.'
    },
    {
        advantage: 'Опит в маркетинга',
        description: 'Брокерите ни знаят най-добрият начин да рекламират жилището ви, за да привлекат купувачи.'
    },
    {
        advantage: 'Имаме достъп до купувачи',
        description: 'Заради предлаганите от нас услуги, професионалните контакти и собствената база данни, брокерите ни могат да ви помогнат да достигнете до широк кръг от клиенти.'
    },
    {
        advantage: 'Умеем да преговаряме',
        description: 'С години опит в сделките и отличното познаване на пазара, брокерите ни могат да ви помогнат да изповядате сделката съобразно исканата от вас цена и при вашите условия.'
    },
    {
        advantage: 'Грижим се за цялата документация',
        description: 'Продажбата на жилище изисква подготовката на купища документация, която понякога е сложна за разбиране. Брокерите ни се ангажират с подготовката и изясняването на всичко по документалната част.'
    },
    {
        advantage: 'Осигуряваме връзка с наши партньори',
        description: 'Брокерите ни могат да ви насочат към кредитни консултанти, експерт- оценители, нотариуси, адвокати, с които работим от дълго време и знаем, че ще ви свършат работа.'
    },
    {
        advantage: 'Съветваме ви дори след изповядване на сделката',
        description: 'Ние ще останем ваш доверен партньор дори след като приключим със сделката. Винаги ще сме насреща, ако изникнат някакви въпроси.'
    },
]

const forRent = [
    {
        advantage: 'Спестяваме ви време',
        description: 'Ако дадете достъп на брокерите ни до вашия имот, те имат компетенциите да се справят с огледи, срещи, да отговарят на всички запитвания за имота и дузина други задължения, които да ви  отменят в работата по отдаването на имота.'
    },
    {
        advantage: 'Най-добрата ЦЕНА',
        description: 'Ако се доверите на нас, ще получите максималната пазарна цена.'
    },
    {
        advantage: 'Познаваме квартала ви',
        description: 'Запознати сме с конкуренцията в собственият ви квартал. Нашите брокери знаят за всеки един имот, със сходни на вашия характеристики, които са на пазара.'
    },
    {
        advantage: 'Знаем как да рекламираме',
        description: 'Брокерите ни прекарват много голяма част от времето си в изготвяне на реклама даваща максимално информация за вашия имот, така че потенциалните наематели да придобиете ясна представа за това, което предлагате.'
    },
    {
        advantage: 'Търсим според критериите',
        description: 'Нашите брокери много внимателно отсяват имотите, които предлагат на своите клиенти, според техните критерии, така че те никога не биха довели наематели, които не искат да наемат вашия имот.'
    },
    {
        advantage: 'Умеем да преговаряме',
        description: 'Ние умеем да преговаряме вместо вас и го правим по най- добрия начин. Винаги защитаваме вашите интереси и ще ви дадем това, което искате да спечелите от вашия имот.'
    },
    {
        advantage: 'Наематели-мечта',
        description: 'Ние умеем да преговаряме вместо вас и го правим по най- добрия начин. Винаги защитаваме вашите интереси и ще ви дадем това, което искате да спечелите от вашия имот.'
    },
    {
        advantage: 'Подготвената от нас документация осигурява сигурност',
        description: 'Вие трябва да сте сигурни, че ще получите имота си в състоянието, в което сте го отдали. Нив гарантират за това със ставен от нас договор и опис на вашето имущество.'
    },
    {
        advantage: 'Нашият сертификат-вашата гаранция',
        description: 'При заплащане на нашата първа комисионна от ваша страна, вие получавате сертификат, с който ние ви гарантираме, че ако наемателят, който сме намерили за вашето жилище се изнесе преди указания срок на договора - 1 година, ние ще ви намерим нов наемател напълно безплатно.'
    },
    {
        advantage: 'Съветваме ви дори след наемането',
        description: 'Ние ще останем ваш доверен партньор дори след намирането на наематели. Винаги сме насреща, ако изникнат някакви въпроси.'
    },
]