import React from 'react';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styles
import '../scss/icon-step-card.scss';

const IconStepCard = ({ className, number = 0, icon, title = '', description = '' }) => {
    return (
        <div className={`icon-step-card${className ? ' ' + className : ''}`}>
            {number ? <div className="icon-step-card__number">{number}</div> : null}
            {icon ? <div className="icon-step-card__icon-wrapper">
                <FontAwesomeIcon icon={icon} />
            </div> : null}
            <div className="icon-step-card__content-wrapper">
                {title ? <h3 className="icon-step-card__title">{title}</h3> : null}
                <div className="icon-step-card__description">{description}</div>
            </div>
        </div>
    );
}

export default IconStepCard;
