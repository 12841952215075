import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import InnerPageTop from '../components/sections/InnerPageTop';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const TermsAndConditions = () => {
    return (
        <main className="terms-and-conditions">
            <Helmet>
                <title>Условия за Ползване - Перфект Консулт</title>
            </Helmet>
            <InnerPageTop pageTitle="Условия за Ползване" pageDescription="Общи условия за осъществяване на посредническа дейност при сделки с недвижими имоти от Перфект Консулт 2009 ЕООД." />
            <section className="my-6">
                <Container>
                    <Row>
                        <Col>
                            <h2 className="mb-2">I. Предмет</h2>
                            <p>1. С настоящите Общи условия за осъществяване на посредническа дейност при сделки с недвижими имоти от Перфект Консулт 2009 ЕООД, (наричани по-долу за кратко „Общи условия”), се определят взаимоотношенията между Перфект Консулт 2009 ЕООД, като професионален посредник при сделки с недвижими имоти, (дружеството, наричано по-долу за краткост Перфект Консулт), и потребителите на услуги(наричани още клиенти или възложители) по повод осъществяването на посредническа дейност при сделки с недвижими имоти на територията на Република България, възникващи по повод осъществяването на тази дейност и при предоставянето на тези услуги.</p>
                            <p>2. Адресът за кореспонденция на Перфект Консулт е: <a className="link" href="//goo.gl/maps/MSBCDXidgro9kENe9" title="Адрес за кореспонденция" target="_blank" rel="nofollow noopener noreferrer">ул. "Фредерик Жолио-Кюри" 18, ет. 1, офис 6, 4000, Пловдив</a>, e-mail: <a className="link" href="mailto:perfectconsult2009@abv.bg" title="Емайл за кореспонденция" >perfectconsult2009@abv.bg</a></p>
                            <p>3. Настоящите Общи условия (в цялост или части от тях) се прилагат винаги и във всички случаи, когато между Перфект Консулт и физическо или юридическо лице, (наричано „Потребител на услуга” или „Възложител”, или „Клиент”) се осъществи контакт по повод предоставянето на информация, свързана с търсене и предлагане на недвижими имоти или се договарят условия по сключването на Възлагателна поръчка (договор) за посредничество при осъществяване на сделка с недвижим имот, или винаги когато има сключена Възлагателна поръчка (договор) за посредничество.</p>
                            <h2 className="mb-2 pt-2">II. Значение На Използваните Специфични Термини</h2>
                            <p>4. Значението на използваните и посочени по-долу термини и определения в Общите условия, Договорите за поръчка, сключвани с клиентите на Перфект Консулт и другите документи, използвани от Перфект Консулт по повод осъществяването на посредничество при сделки с имоти, е следното:</p>
                            <p>4.1. „Клиент на Перфект Консулт” (наричан в тези Общи условия още „Потребител на услуга” или „Възложител”, или „Клиент”) е физическо или юридическо лице, което получава информация от Перфект Консулт, свързана с търсене и предлагане на недвижими имоти по повод осъществяваната от Перфект Консулт посредническа дейност при сделки с недвижими имоти на територията на Република България и/или е обвързано по договор с Перфект Консулт.</p>
                            <p>4.2. Под „Възлагателна поръчка за посредничество” се разбира Договорът, с който клиентите възлагат на Перфект Консулт, а Перфект Консулт приема да им посредничи при осъществяването на сделка с недвижим имот, срещу заплащане на комисионно възнаграждение. Термините „Поръчка за посредничество” или „Договор за посредничество”, „Договор-поръчка”, „Договор-поръчка за посредничество”, „Поръчка”, „Договор”, използвани в настоящите „Общи условия” или във Възлагателните поръчки за посредничество, или при водене на разговори, преговори и кореспонденция между Перфект Консулт и потребителите по повод сключването на Възлагателна поръчка за посредничество и/или по повод ползването на права, и/или изпълнението на задължения по вече сключена Възлагателна поръчка за посредничество, независимо дали са използвани в пълен или кратък член, в единствено или множествено число, имат значението на „Възлагателна поръчка за посредничество”, посочено по-горе.</p>
                            <p>4.3. Под „Сделка” се разбира сключването с посредничеството на Перфект Консулт на предварителен и/или окончателен договор за покупко-продажба на недвижим имот (осигурен имот в смисъла по-долу) или Договор за наем на недвижим имот (осигурен имот), между две страни, едната от които или и двете страни е/са клиент/и на Перфект Консулт. По сключения договор трябва да има постъпило плащане на част или на цялата продажна цена, или наемната цена на имота.</p>
                            <p>4.4. За „Продажна цена на имот” се приема сумата, посочена в Предварителния договор за покупко-продажба, а ако такъв договор липсва, сумата посочена в окончателния договор за покупко-продажба на имота.</p>
                            <p>4.5. За „Наемна цена на имот” се приема сумата, определяща месечния наем на имота, съгласно Договора за наем.</p>
                            <p>4.6. Под „Комисионно възнаграждение” (или Посредническо възнаграждение), се разбира договореното при условията на Възлагателна поръчка за посредничество възнаграждение на Перфект Консулт за предоставени услуги.</p>
                            <p>4.7. „Осигурен клиент” (купувач, наемател) е физическо или юридическо лице, посочено в Протокол за оглед или свързано с него лице по смисъла на настоящите Общи условия, което е присъствало на оглед на имот, организиран от Перфект Консулт и/или е получило информация от Перфект Консулт, съгласно условията на Раздел IX от тези Общи условия. Осигуреният клиент може да няма сключена Възлагателна поръчка с Перфект Консулт, както и да бъде клиент на друг посредник, с когото Перфект Консулт има бизнес отношения. В този смисъл Осигурения клиент не е задължително да бъде записан в базата данни на Перфект Консулт, (да се представлява от Перфект Консулт).</p>
                            <p>4.8. „Осигурен имот” е имот, описан в Протокол за оглед, организиран от Перфект Консулт и/или имот, за който потребителят е получил информация от Перфект Консулт при условията на Раздел IX от Общите условия. Не е задължително собственикът (или собствениците) на Осигурения имот да има/т сключена Възлагателна поръчка с Перфект Консулт. Собственикът на осигурения имот може да бъде клиент на друг посредник, с когото Перфект Консулт има бизнес отношения. В този смисъл Осигурения имот не е задължително да е записан в базата данни на Перфект Консулт, (да се предлага от Перфект Консулт).</p>
                            <p>4.9. „Протокол за оглед”, (в ед. или мн. число) е служебен документ, удостоверяващ датата и часа на провеждане на оглед на недвижим имот, който документ съдържа основни индивидуализиращи характеристики на имота, лични данни на клиентите на Перфект Консулт и лицата присъствали на огледа.</p>
                            <p>4.10. „Оглед” или „Оглед на недвижим имот” е организирана от Перфект Консулт, по искане на Осигурения клиент, среща на територията на Осигурения имот, за който имот клиентът предварително е получил информация от Перфект Консулт при условията на Раздел IX от тези Общи условия, която среща се осъществява с цел запознаване на място с характеристиките и състоянието на имота по повод намерения на клиента за осъществяването на сделка с Осигурения недвижим имот.</p>
                            <p>4.11. „Обратна разписка” има смисъла на изпратено от пощенска служба, куриерска служба, нотариална кантора или от web или mail (пощенски или интернет) сървър на Перфект Консулт, писмено уведомление до Перфект Консулт, удостоверяващо датата и часа на получаването на документи и/или предоставяне на информация, по реда предвиден с тези Общи условия.</p>
                            <p>4.12. За „Обичайно комисионно възнаграждение” на Перфект Консулт по смисъла на Раздел VII от Общите условия, се приема 3% от продажната цена, (но не по-малко от 600 Евро) - при сделки за покупко-продажба и 50% от месечния наем, (но не по-малко от 100 Евро) - при сделки с наеми. Посоченият размер на обичайното възнаграждение е без ДДС.</p>
                            <p>4.13. За „Обичайни разноски” по изпълнение на Възлагателна поръчка се приемат 30% от стойността на възнаграждението по нея или от Обичайното комисионно възнаграждение. Ако възнаграждението не е договорено като определена сума, размерът му се изчислява като процент върху цената на търсения или предлаган имот, според поръчката.</p>
                            <p>4.14. Под "Обещание за сделка" от раздел VIII се разбира изразено писмено намерение от клиент на Перфект Консулт за осъществяване на сделка при определени цена и условия.</p>
                            <p>4.15. "Договорено възнаграждение" е посочената в договора за поръчка твърда сума или процент върху действително договорената продажна или наемна цена на имота. При търсене на неустойка за неизпълнение на клауза за изключителни права, прекратяване на поръчка или осъществена сделка без знанието на Перфект Консулт, договорено възнаграждение, като процент, се определя върху цената на имота според поръчката.</p>
                            <h2 className="mb-2 pt-2">III. Сключване На Възлагателни Поръчки За Посредничество</h2>
                            <p>5. По повод осъществяване на своята дейност като професионален посредник при сделки с недвижими имоти, Перфект Консулт сключва с клиентите си Възлагателни поръчки за посредничество при продажба, покупка, отдаване под наем и наемане на недвижими имоти или управление на собственост при отдаване на имоти под наем на територията на Република България. В договорите за поръчка Перфект Консулт се посочва като страна ИЗПЪЛНИТЕЛ, а клиентът като страна ВЪЗЛОЖИТЕЛ.</p>
                            <p>6. Възлагателните поръчки за посредничество се изготвят на български език или български и английски език или български и руски език.</p>
                            <p>7. В случай, че Възлагателната поръчка за посредничество е изготвена на български и английски език или български и руски език, и бъде установено различие в текста или възникне спор при тълкуването на съдържанието, за меродавен се счита и се прилага българският текст. Това правило важи също за всички анекси, споразумения, протоколи и други документи, свързани с Възлагателната поръчка за посредничество.</p>
                            <p>8. В случай, че някоя от клаузите на Възлагателната поръчка за посредничество и/или тези Общи Условия, по каквато и да е причина бъде обявена за нищожна или неприложима, това не може да бъде отнесено към останалите клаузи на Възлагателната поръчка за посредничество и/или на тези Общи условия, а същите се прилагат при максимално близко спазване на изначално заложените условия, права, задължения и отговорности.</p>
                            <p>9. ВЪЗЛОЖИТЕЛЯТ, подписал Възлагателна поръчка за посредничество при продажба на имот, декларира, че е собственик на имота и действа със съгласието на останалите съсобственици, (или ако не е собственик, че има валидна представителна власт за сключването на поръчката и пълномощното му не е нищожно или оттеглено), че всички останали собственици са съгласни с продажбата на имота, че имотът има необходимите за продажбата документи за собственост, както и че имотът няма тежести (ипотеки, възбрани, искови молби, учредени вещни права и др.), освен изрично посочените в договора и че няма обективни причини, които пречат за продажбата на имота.</p>
                            <p>9а. Може да бъде сключена Възлагателна поръчка за посредничество с възложител, който не е собственик на имота и не е официално упълномощен от собственика, при условие че възложителят идентифицира (посочи) собственика, декларира лично пред Перфект Консулт съгласието на собственика за осъществяване на сделка при посочените параметри и верността на подадената информация за сключването на поръчката, и се задължи лично да осигури необходимите документи за сделката, както и присъствието на собственика (или надлежно упълномощен негов пълномощник) при воденето на преговори и подписването на документи. В тези случаи, Възложителят-несобственик поема лично задължението за заплащане на възнаграждение и/или евентуални обезщетение, лихва и/или неустойка, дължими на Перфект Консулт при изпълнение на поръчката, както и отговорността от неизпълнението на сделката по вина на собственика, или неговия пълномощник и заплащането на произтичащите от това неизпълнение обезщетения, лихви и/или неустойки.</p>
                            <p>9б. Предвид възможностите за конкретно договаряне при водене на преговори, сделка с имот може да бъде извършена за цена и условия, различни от първоначално обявените в договора за поръчка. Това обстоятелство не може да бъде основание за оспорване на дължимост, размер и падеж на възнаграждението по договора.</p>
                            <p>10. В случаите, когато възложителите по сключен договор за посредничество са повече от един, техните задължения по договора, (включително за заплащането на възнаграждение, обезщетение, лихва и неустойка), са солидарни.</p>
                            <p>11. Перфект Консулт може да сключва с клиентите си Възлагателни поръчки за посредничество, (както и свързаните с тях споразумения, анекси и други документи, свързани с осъществяването на посредническа дейност), от разстояние, като използва електронна поща, куриерска или пощенска пратка, или факс. В този случай за дата на сключване на договора (документа) се счита датата на получаването от Перфект Консулт на подписан от клиента (възложителя) оригинален екземпляр от документа или на подписано копие от него, в случай че бъде използвана процедурата за сключване на договори от разстояние, чрез електронна поща или факс. В тези случаи страните се задължават да предоставят една на друга оригинала на документа не по-късно от 7 дена след сключването му, освен ако ситуацията не налага и е възможен по-кратък срок или в договора е договорен друг срок.</p>
                            <p>12. Перфект Консулт може да откаже сключването на възлагателна поръчка или да прекрати работата по нея в следните случаи:</p>
                            <p>12.1. ако поставените от Възложителя изисквания и/или условия противоречат на постигнатите договорености, съгласно поръчката и тези „Общи условия”;</p>
                            <p>12.2. ако поставените от Възложителя изисквания и/или условия не са съобразени с пазарната конюнктура;</p>
                            <p>12.3. ако Възложителят прояви некоректно или неуважително отношение към Перфект Консулт или към служител/и на Перфект Консулт.</p>
                            <h2 className="mb-2 pt-2">IV. Предоставяне На Изключителни Права (Ексклузивитет)</h2>
                            <p>13. При договорени изключителни посреднически права за продажба на недвижим имот, Възложителят поема основното задължение да не предлага имота за продажба, да не продава имота си сам, чрез трето лице или чрез друг посредник, както и да не провежда огледи в имота, без присъствието на представител на Перфект Консулт. В този смисъл имотът може да бъде продаден само с посредничеството на Перфект Консулт и ако имотът бъде продаден с предварителен или окончателен договор в срока на сключената Възлагателна поръчка за посредничество, поради естеството на ексклузивните права се счита, че имотът е продаден с посредничеството на Перфект Консулт, с всички произтичащи от това права, задължения, отговорности и последици от тази презумпция.</p>
                            <p>14. При договорени изключителни посреднически права за отдаване под наем на недвижим имот, Възложителят поема основното задължение да не предлага и/или отдава имота под наем сам, чрез трето лице или чрез друг посредник, както и да не провежда огледи в имота, без присъствието на представител на Перфект Консулт. В този смисъл имотът може да бъде отдаден под наем само с посредничеството на Перфект Консулт и ако имотът бъде отдаден под наем в срока на сключената Възлагателна поръчка за посредничество, поради естеството на ексклузивните права се счита, че имотът е отдаден под наем с посредничеството на Перфект Консулт, с всички произтичащи от това права, задължения, отговорности и последици от тази презумпция.</p>
                            <p>15. Когато при условията на сключена възлагателна поръчка за посредничество, са договорени изключителни посреднически права за покупка на недвижим имот, Възложителят поема основното задължение да не търси имот за покупка, (сам, чрез трето лице или чрез друг посредник), и да не провежда огледи за закупуване на имот, без присъствието на представител на Перфект Консулт. В този смисъл Възложителят може да закупи недвижим имот, само с посредничеството на Перфект Консулт и ако в срока и при условията на сключената Възлагателната поръчка за посредничество, закупи недвижим имот, чрез предварителен или окончателен договор, поради естеството на ексклузивните права се счита, че имотът е закупен с посредничеството на Перфект Консулт, с всички произтичащи от това права, задължения, отговорности и последици от тази презумпция.</p>
                            <p>16. Когато при условията на сключена възлагателна поръчка за посредничество, са договорени изключителни посреднически права за наемане на недвижим имот, Възложителят поема основното задължение да не търси имот под наем, (сам, чрез трето лице или чрез друг посредник), и да не провежда огледи за наемане на имот без присъствието на представител на Перфект Консулт. В този смисъл Възложителят може да наеме недвижим имот, само с посредничеството на Перфект Консулт и ако в срока и при условията на Възлагателната поръчка за посредничество, наеме недвижим имот, поради естеството на ексклузивните права се счита, че имотът е нает с посредничеството на Перфект Консулт, с всички произтичащи от това права, задължения, отговорности и последици от тази презумпция.</p>
                            <h2 className="mb-2 pt-2">V. Провеждане На Огледи На Недвижими Имоти</h2>
                            <p>17. Перфект Консулт организира и провежда огледи на недвижими имоти с клиенти, само при сключена/и Възлагателна/и поръчка/и за посредничество. Изключенията се договарят изрично.</p>
                            <p>18. Преди провеждането на огледите Перфект Консулт изпраща до клиентите уведомления за организираните огледи, съдържащи информация за деня и часа на огледа, и обозначение на имота, посредством номер от базата данни.</p>
                            <p>19. За провеждането на организираните от Перфект Консулт огледи на недвижими имоти се съставя Протокол/и за оглед/и. Протоколът за оглед може да има значение и на договор за посредничество, при изрично договорени условия в него.</p>
                            <p>20. Протоколът за оглед е неразделна част от Възлагателната поръчка за посредничество и документира следните фактически отношения и обстоятелства, от съществено значение за осъществяването на предмета на Възлагателната поръчка за посредничество:</p>
                            <p>20.1. С подписването на Протокола за оглед, клиентът на Перфект Консулт по Възлагателна поръчка за покупка или наемане на недвижим имот, потвърждава че имотът/те, предмет на огледа, му е/са осигурен/и от Перфект Консулт и имат/т значението на Осигурен/и имот/и по смисъла на Възлагателната поръчка и тези Общи условия, а клиентът приема статута на Осигурен клиент за този/тези имот/и.</p>
                            <p>20.2. С подписването на Протокола за оглед, клиентът на Перфект Консулт по Възлагателна поръчка за продажба или отдаване под наем на недвижим имот, потвърждава, че потенциалния/те купувач/и или наемател/и, доведен/и на оглед на имота му, е/са осигурен/и от Перфект Консулт, в качеството му/им на Осигурен/и клиент/и с посредничеството на Перфект Консулт.</p>
                            <p>21. Протоколът за огледа/ите се води в един екземпляр. Клиентите на Перфект Консулт имат право да получат заверен препис и/или извлечение от него с подаване на писмено заявление.</p>
                            <p>22. В случаите, когато възложителят по сключена Възлагателната поръчка за посредничество при продажба или отдаване под наем на недвижим имот не присъства лично на огледа и не е изпратил упълномощен представител, протоколът за огледа се подписва от представителя (АГЕНТА) на Перфект Консулт, присъстващ на огледите и осигурения купувач или наемател, като това се счита достатъчно за документирането и доказването на огледа с Осигурен/и клиент/и в смисъла по-горе.</p>
                            <p>23. В случаите, когато възложителят по сключена Възлагателната поръчка за посредничество при покупка или наемане на недвижим имот не присъства лично на огледа, а е изпратил свой представител, този факт се отбелязва в протокола за оглед и същият се подписва от представителя на ВЪЗЛОЖИТЕЛЯ и представителя (АГЕНТА) на Перфект Консулт, като това се счита достатъчно за документирането и доказването огледа на Осигурения/те имот/и в смисъла по-горе, ако към момента на узнаването за огледа ВЪЗЛОЖИТЕЛЯТ не оспори или оттегли представителната власт на представителя. Ако след оспорването ВЪЗЛОЖИТЕЛЯТ осъществи сделка с имота, възражението губи юридическа стойност.</p>
                            <p>24. При попълване на Протокол за оглед е възможно обозначението и/или адреса на имота, предмет на огледа, да бъде описано различно от обозначението и адреса му според документа за собственост, поради примерно посочените по-долу или подобни на тези обстоятелства:</p>
                            <p>24.1. Настоящият административен адрес на имота е променен, а в документа за собственост присъства стария адрес.</p>
                            <p>24.2. Имотът по документи е с различен статут от фактическия и служителят на Перфект Консулт към момента на провеждането на огледа не е знаел и/или не е могъл да знае за това несъответствие.</p>
                            <p>24.3. Имотът е описан според видимите му белези и характеристики по време и към момента на провеждане на огледа, поради непредставянето и/или липса на валидни документи за собственост при сключването на Възлагателната поръчка за посредничество или при провеждането на огледа.</p>
                            <p>24.4. Служителят на Перфект Консулт е въведен в заблуждение, относно съществените характеристики на имота, необходими за точната му индивидуализация.</p>
                            <p>25. Разминаването в описанието на имота според документа за собственост и описанието, посочено в Протокола за огледите, поради причините, упоменати по-горе или поради подобни на тях причини, не може да бъде основание за оспорване на документираните чрез Протокола факти и обстоятелства, доказващи осигуряването на имота и/или клиента, чрез посредничеството на Перфект Консулт.</p>
                            <p>26. По аналогия, горното заключение трябва да се отнася и за евентуално несъответствие между описанието на имота според Възлагателната поръчка за посредничество и документа за собственост, поради горепосочените или подобни на тях причини.</p>
                            <h2 className="mb-2 pt-2">VI. Сключване На Сделки С Недвижими Имоти</h2>
                            <p>27. В качеството си на посредник по сделки с недвижими имоти, Перфект Консулт участва като страна посредник-гарант при сключването на предварителния договор за покупко-продажба на имота или договорът за наем на имота.</p>
                            <p>28. Предварителният договор или договорът за наем се съставя в съответния брой еднообразни екземпляри, един от които е за посредника по сделката.</p>
                            <p>29. Като посредник по сключените договори, Перфект Консулт гарантира, че волята на страните за сключването на Предварителен договор за покупко-продажба на недвижим имот и/или Договор за наем на недвижим имот към момента на сключването му/им не е опорочена и че волеизявленията в съдържанието на подписаните документи отговоря напълно на фактическите отношения между страните.</p>
                            <p>30. Перфект Консулт поема задължението, с оглед своята компетентност на посредник, да съдейства на страните за намирането на взаимноизгодни решения при възникнали спорове и за изглаждането на противоречия, но не може да гарантира изпълнението на поетите от страните задължения по сключените договори и да бъде отговорно за неизпълнението им.</p>
                            <p>31. При неизпълнение на уговорките по сключените предварителни и/или Окончателни договори или договори за наем, страните могат да търсят правата си по общия исков ред, ако не са постигнали споразумение по между си.</p>
                            <p>32. Като посредник, Перфект Консулт може да приема и съхранява суми, представляващи депозит и/или капаро по сделки, на основание Декларации за намерения, Депозитни споразумения, Предварителни договори, Договори за наем и управление и/или други документи, при условията договорени с клиента в тези документи.</p>
                            <h2 className="mb-2 pt-2">VII. Посреднически Възнаграждения</h2>
                            <p>33. Възложителят дължи възнаграждение при условията, посочени в сключения договор-Възлагателна поръчка за посредничество. Възнаграждението е дължимо винаги, когато Възложителят сключи сделка с Осигурен имот или Осигурен клиент, независимо дали Перфект Консулт е изпълнило останалите акцесорни задължения по договора за поръчка - последните само спомагат изпълнението на поръчката и не могат да бъдат задължителни за осъществяването на сделката, като краен резултат.</p>
                            <p>34. Размерът, срокът и начините на плащане на Възнаграждението за извършване на посредническата услуга се определя, съгласно сключения договор за посредничество.</p>
                            <p>35. ВЪЗЛОЖИТЕЛЯТ-продавач или наемодател на имот дължи посоченото в договора за посредничество възнаграждение и в случаите когато:</p>
                            <p>35.1. след прекратяването на договора за посредничество сключи сделка с Осигурен клиент.</p>
                            <p>35.2. имотът е съсобствен, независимо от това, че останалите съсобственици не са страна по договора за посредничество. В този смисъл Възложителят по договора за посредничество дължи изцяло договореното възнаграждение, без значение квотата му от собствеността върху имота.</p>
                            <p>35.3. сключи сделка за имота със свързано с Осигурения клиент лице, по смисъла на §1, ал.1 от Търговския закон или със свързано лице по смисъла на тези Общи условия, а именно: трето лице, в чиято полза действа Осигуреният клиент, или лице, с което Осигуреният клиент е в близки, приятелски или колегиални отношения, или лице, на което Осигуреният клиент е кредитор или длъжник, или лице, присъствало като придружител на Осигурения клиент при провеждането на огледите на имота, или лице изискало от Перфект Консулт и получило от името на Осигурения клиент, информация за имота по повод осъществяването на посредническа сделка, която информация му е предоставена по реда, предвиден в Раздел IX от Общите условия.</p>
                            <p>35.4. сключи с Осигурения клиент или със свързано лице по смисъла на тези Общи условия, друг вид сделка, имаща вещноправен ефект върху имота (замяна, дарение, учредяване или продажба на право на строеж, или учредяване право на ползване, продажба на Едноличен търговец или Търговско дружество, в които Осигуреният имот се явява актив и др.), или сключи сделка с Осигурения клиент за друг свой имот, (или имот собственост на свързано лице), находящ се в една и същата сграда с имота предмет на поръчката.</p>
                            <p>36. Ако ВЪЗЛОЖИТЕЛ по договор за посредничество при продажба и/или покупка на имот, сключи Договор за наем с Осигурен клиент или Осигурен имот, той дължи обичайното възнаграждението на ИЗПЪЛНИТЕЛЯ за подобен вид посреднически сделки за наеми. Ако след сключването на договор за наем, ВЪЗЛОЖИТЕЛЯТ сключи сделка по покупко-продажба със същия Осигурен клиент или Осигурен имот, платеното възнаграждение по сделката за наем се приспада от дължимото възнаграждение по сделката за продажба и/или покупка, ако ползването на имота е продължило по-малко от 6 месеца.</p>
                            <p>37. Ако ВЪЗЛОЖИТЕЛЯТ по договор за посредничество при отдаване под наем или наемане на имот, сключи предварителен и/или окончателен договор за покупко-продажба с Осигурен клиент или Осигурен имот, независимо дали преди това е сключил Договор за наем за същия имот или със същия клиент, той дължи обичайното възнаграждение на ИЗПЪЛНИТЕЛЯ за подобен вид посреднически сделки по покупко-продажби, като евентуално платеното възнаграждение по сделката за наем се приспада от дължимата сума, ако ползването на имота е продължило по-малко от 6 месеца.</p>
                            <p>38. Възложителят купувач или наемател дължи посоченото в договора за посредничество възнаграждение и в случаите когато:</p>
                            <p>38.1. след прекратяването на договора за посредничество, сключи сделка с Осигурен имот.</p>
                            <p>38.2. сключи сделка с Осигурен имот чрез свързано лице, по смисъла на §1, ал.1 от Търговския закон или чрез свързано лице по смисъла на тези Общи условия лице, а именно: трето лице, действащо в полза на Възложителя, или лице, в чиято полза действа възложителят, или лице, с което възложителят е в близки, приятелски или колегиални отношения, или лице, на което Възложителят е кредитор или длъжник, или лице, присъствало като придружител на Възложителя при провеждането на огледите на имота, или лице изискало от Перфект Консулт и получило от името на Възложителя, информация за имота по повод осъществяването на посредническа сделка, която информация му е предоставена при условията на Раздел IX.</p>
                            <p>38.3. сключи лично или чрез свързано лице по смисъла на тези Общи условия, друг вид сделка, имаща вещно-правен ефект върху Осигурения имот (замяна, дарение, учредяване или продажба на право на строеж, или учредяване право на ползване, продажба на Едноличен търговец или Търговско дружество, в които Осигуреният имот се явява актив и др.), или сключи сделка за друг имот, находящ се в една и същата сграда с осигурения имот, ако собственикът на имотите е един и същ.</p>
                            <p>39. Предвид приетите от страните отношения, съгласно Раздел IV от тези Общи условия, при договорени изключителни посреднически права за осъществяване на сделка с недвижим имот, Възложителят дължи договореното възнаграждение, ако в срока на договора за посредничество, сключи сделка по предмета на този договор, без да е необходимо доказване дали сделката е осъществена с посредничеството на Перфект Консулт.</p>
                            <p>40. ВЪЗЛОЖИТЕЛЯТ по договор за посредничество дължи допълнително възнаграждение по споразумение, ако възложи на ИЗПЪЛНИТЕЛЯ действия, свързани с ремонт и поддръжка на имота, заличаване на тежести върху имота, осигуряване на документи и преписи от документи, изготвяне на нотариални актове по свързани сделки, участие в преговори по свързани сделки, водене на преговори с кредитори, сключване на договори, разрешаване на спорове между съсобственици, представителство и други действия от сходен характер.</p>
                            <p>41. Винаги когато размерът на дължимото възнаграждение не може да бъде установен или доказан с писмен договор за възлагателна поръчка, се приема че Договорът за поръчка е устен, а за дължимо възнаграждение се приема обичайното възнаграждение на Перфект Консулт за подобен вид посреднически сделки.</p>
                            <p>42. Винаги когато падежът на дължимото посредническо възнаграждение не може да бъде установен или доказан с писмен договор за възлагателна поръчка, се приема, че падежът на възнаграждението е както следва:</p>
                            <p>42.1. по сделки за покупка или продажба на имоти - на датата на сключване на предварителен договор за покупко-продажба, а ако не е сключен такъв договор, на датата на подписване на окончателен договор за покупко-продажба на имота, преди вписването на същия.</p>
                            <p>42.2. по сделки за наеми на имоти - на датата на сключване на Договора за наем.</p>
                            <p>43. Перфект Консулт може да договаря с клиенти различни от обичайните комисионни възнаграждения, както и да договаря различни по размер възнаграждения в зависимост от вида на имота, пазарната конюнктура, фактическата и правна сложност на сделката, както и да работи без комисионно възнаграждение по определени сделки, с оглед корпоративни и конюнктурни съображения, когато това е изрично посочено в информацията за предлаганите имоти или договорено с клиента.</p>
                            <h2 className="mb-2 pt-2">VIII. Отговорности</h2>
                            <p>44. Ако Възложителят не изпълни обещание за сделка или се откаже от поръчката, Перфект Консулт има право да търси направените обичайни разноски по изпълнението на поръчката.</p>
                            <p>45. Ако ВЪЗЛОЖИТЕЛЯТ забави изплащането на възнаграждение по договор за поръчка, той дължи неустойка върху главницата в размер на 0.5% за всеки просрочен ден, но не повече от 10% от размера на възнаграждението.</p>
                            <p>46. Ако ВЪЗЛОЖИТЕЛЯТ сключи сделка с Осигурен клиент или Осигурен имот и забави изплащането на възнаграждение по договор за поръчка повече от 30 дена от датата на падежа, или откаже да плати уговореното възнаграждение, или плати само част от него, Перфект Консулт има право да иска заплащане на договореното възнаграждение в пълен обем, заедно с неустойка 30% от размера на дължимото възнаграждение.</p>
                            <h2 className="mb-2 pt-2">IX. Предоставяне На Информация. Защита На Личните Данни</h2>
                            <p>47. По повод осъществяването на своята дейност, Перфект Консулт събира, обработва, анализира и предоставя информация, свързана с търсене и предлагане на недвижими имоти с цел осъществяването на посреднически сделки с недвижими имоти.</p>
                            <p>48. Всяка информация за предлагане на имот се записва под уникален номер на оферта в базата данни на Перфект Консулт и съдържа основни индивидуализиращи данни за имота, като: вид, адрес, име, тел., e-mail на собственика (или негов представител), описание, обявена цена, снимков материал, които данни са относими към номера на офертата.</p>
                            <p>49. Всяка информация за търсене на имот се записва под уникален номер на заявка в базата данни на Перфект Консулт и съдържа следните основни индивидуализиращи данни на клиента, заявил търсенето: име, тел., e-mail, изисквания и цена, които данни са относими към номера на заявката.</p>
                            <p>50. Една и съща информация за търсене или предлагане на имот може да бъде записана с номер в базата данни на Перфект Консулт само веднъж.</p>
                            <p>51. Перфект Консулт има право да събира, обработва и предоставя информация, (част от която или цялата, представлява информация за лични данни по см. на чл.2 от Закона за защита на личните данни), в качеството си на регистриран администратор на лични данни под № 47878 в Електронния регистър към Комисията за защита на личните данни.</p>
                            <p>52. По повод дейността си на професионален посредник при сделки с недвижими имоти, Перфект Консулт събира и предоставя на потребителите информация, която може да съдържа: уникален номер на оферта и/или заявка, точно местоположение на предлаганите имоти, снимков и видео материал за имотите, информация относно собствеността върху имотите, относно документите за собственост върху имотите и други документи, необходими за осъществяването на сделката, лични данни на потребителите като: три имена, номер, дата на издаване, място на издаване на лична карта, ЕГН и постоянен адрес.</p>
                            <p>53. Потребителите, сключили Възлагателни поръчки за посредничество, се съгласяват, че Перфект Консулт може да събира и предоставя информация в смисъла по горе.</p>
                            <p>54. Перфект Консулт предоставя на клиента горепосочената информация чрез разговор и/или писмено - на ръка, чрез куриерска пратка, по електронна поща (e-mail) или писмо, като информацията, дадена на клиента в писмен вид и/или на материален носител, се предоставя с Протокол за предоставяне на информация.</p>
                            <p>55. Протоколът за предоставяне на информация съдържа данни за дължимото от клиента посредническо възнаграждение при осъществяване на сделка с осигурения имот.</p>
                            <p>56. Когато информацията се предава на ръка, тя се счита за получена с подписването на Протокола за предоставяне на информация, освен ако в Протокола или поръчката не е уговорено друго.</p>
                            <p>57. Когато информацията се предоставя с куриерска пратка или електронна поща, (на материален носител или файл), пощата винаги се придружава от Протокол за предоставяне на информация, а информацията се счита за получена на датата и часа, отбелязани в издадената (изпратената) Обратната разписка за получаването на Протокола.</p>
                            <p>58. Клиентът, получил информация по електронна поща, изпраща автоматично Обратна разписка за получаването й към сървъра на Перфект Консулт, и за дата и час на получаването й се счита датата и часът, посочени от пощенския или интернет сървър на Перфект Консулт.</p>
                            <p>59. Клиентът има право да откаже информацията или да възрази срещу условията за получаването й, както изпрати писмено Уведомление към Перфект Консулт. В случай, че това не бъде направено към момента на получаването на информацията, се счита че КЛИЕНТЪТ приема предоставената информация и условията за предоставянето й.</p>
                            <p>60. В случаи, когато по различни причини, липсва Протокол за предоставяне на информация, предоставянето на информация на клиента се удостоверява и доказва чрез съдържанието на писмената кореспонденция между клиента и Перфект Консулт, водена по електронна поща (e-mail), писма или факс.</p>
                            <p>61. В Протокол за предоставяне на информация и/или в Протокол за оглед, и/или при водене на кореспонденция с клиент, за обозначаването на Осигурен имот и/или Осигурен клиент, може да бъде посочен само номерата на офертата и/или заявката от базата данни на Перфект Консулт, като това се счита достатъчно за индивидуализацията на имота и/или клиента.</p>
                            <h2 className="mb-2 pt-2">X. Уреждане На Спорове. Приложим Закон</h2>
                            <p>62. За установяването и доказването на отношенията клиент - посредник и/или на статуса Осигурен клиент, Осигурен имот, или отношенията по Предоставяне на информация, могат да бъдат използвани Възлагателните поръчки за посредничество, Протоколите за оглед, сключените Предварителни договори, Споразумения, Депозитни разписки, Протоколите за предоставяне на информация, както и писмената кореспонденция, кореспонденцията по електронна поща (e-mail) м/у Перфект Консулт и клиента, идентификация на клиенти по IP адрес, обратни разписки, откази и уведомления, свързани с предоставянето на информация, извлечения от базата данни на Перфект Консулт и друга документация, изразяваща волята на страните по повод предоставяните услуги от Перфект Консулт.</p>
                            <p>63. При оспорване на относимост между номер на оферта и данни за имот или между номер на заявка и данни за клиент, относимостта се доказва с разпечатка-извлечение от базата данни на Перфект Консулт.</p>
                            <p>64. Неизпълнението на задълженията на страните по сключените договори и/или по настоящите Общи условия ще бъде удостоверявано с отправянето на покани и уведомления на посочените електронни адреси, а ако липсват такива, на посочените пощенски адреси в договора.</p>
                            <p>65. Всякакви спорове по изпълнението на Възлагателните поръчки за посредничество и/или Общите условия, включително споровете за тяхното тълкуване, недействителност, неизпълнение или прекратяване, както и спорове свързани с попълване на празноти в Договорите за посредничество, другите свързани с дейността на Перфект Консулт документи и/или тези Общи условия или привеждането им в съответствие с нововъзникнали обстоятелства, ще се уреждат по съгласие на страните, или ако последното се окаже невъзможно, ще се отнасят за решаване от компетентния съд.</p>
                            <h2 className="mb-2 pt-2">XI. Заключителни Разпоредби</h2>
                            <p>66. Във всички случаи, при които във Възлагателните поръчки за посредничество и/ или в анексите към тях, и/или в споразуменията и уведомленията към тях, и/или в тези „Общи условия”, е предвидено черпене на права и изпълнение или погасяване на задължения, след прекратяването на правоотношенията на страните по сключена възлагателна поръчка, страните не могат, при неизпълнение, да се позовават на обстоятелството, че правоотношенията им вече са прекратени, както и на същото основание да оспорват дължимост, размер и падеж на договорено възнаграждение или неустойка. В тези случаи Перфект Консулт може да се позовава на клаузата за неустойка по т.48 от тези "Общи условия", независимо от прекратяването на договора за поръчка.</p>
                            <p>66А. При сключване на Договор за посредничество с КЛИЕНТА, Перфект Консулт може да договаря различни Общи условия от настоящите.</p>
                            <p>67. Настоящите Общи условия са одобрени от Управителя на Перфект Консулт 2009 ЕООД на 18.01.2019 г. и с измененията и допълненията към тях са публикувани, на официалната интернет страница на Перфект Консулт на адрес: <a className="link" href="//perfectconsult.bg/" title="Официална Интернет Страница">www.perfectconsult.bg</a></p>
                            <p>68. Всички изменения и/или допълнения на Общите условия се одобряват и оповестяват по реда на предходната точка.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
}

export default TermsAndConditions;
