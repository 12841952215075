// React Components
import React from 'react';
//eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

// Components
import Social from '../Social';
import Newsletter from '../Newsletter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Styles
import '../../scss/footer.scss';

// Footer
const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__main text-center text-sm-left">
                <Container>
                    <Row>
                        <Col className="col-12 col-md-4 col-lg-3 col-xxl-2 order-1 order-xxl-0 mb-3 mb-md-0">
                            <h4 className="mb-2 mb-sm-3">Контакти</h4>
                            <ul className="ul-clean footer__list">
                                <li><a className="link footer__phone" href={`tel:${process.env.REACT_APP_PHONE_HREF}`} title="Обадете ни се"><FontAwesomeIcon icon={faPhoneAlt} />{process.env.REACT_APP_PHONE}</a></li>
                                <li><a className="link" href={`mailto:${process.env.REACT_APP_EMAIL}`} title="Пишете ни">{process.env.REACT_APP_EMAIL}</a></li>
                                <li><a className="link" href={process.env.REACT_APP_GMAPS_HREF} title="Посетете ни" target="_blank" rel="nofollow noopener noreferrer">{process.env.REACT_APP_ADDRESS}</a></li>
                            </ul>
                        </Col>
                        <Col className="col-12 col-xxl-6 mb-md-4 mb-xxl-0 order-0 order-xxl-1">
                            <Row>
                                <Col className="col-12 col-sm-6 col-md-3 mb-3 mb-md-0">
                                    <h4 className="mb-2 mb-sm-3">Навигация</h4>
                                    <ul className="ul-clean footer__list">
                                        <li><Link className="link" to="/">Начало</Link></li>
                                        <li><Link className="link" to="/properties">Имоти</Link></li>
                                        <li><Link className="link" to="/services">Услуги</Link></li>
                                        <li><Link className="link" to="/blog">Блог</Link></li>
                                        <li><Link className="link" to="/contacts">Контакти</Link></li>
                                    </ul>
                                </Col>
                                <Col className="col-12 col-sm-6 col-md-3 mb-3 mb-md-0">
                                    <h4 className="mb-2 mb-sm-3">Услуги</h4>
                                    <ul className="ul-clean footer__list">
                                        <li><Link className="link" to="/services/for-owners">За Собственици</Link></li>
                                        <li><Link className="link" to="/services/we-buy-directly">Купуваме Директно</Link></li>
                                        <li><Link className="link" to="/services/propety-control">Управление на Имот</Link></li>
                                        <li><Link className="link" to="/services/for-investors">За Инвеститори</Link></li>
                                    </ul>
                                </Col>
                                <Col className="col-12 col-sm-6 col-md-3 mb-3 mb-md-0">
                                    <h4 className="mb-2 mb-sm-3">За Нас</h4>
                                    <ul className="ul-clean footer__list">
                                        <li><Link className="link" to="/reviews">Отзиви</Link></li>
                                        <li><Link className="link" to="/careers">Кариери</Link></li>
                                        <li><Link className="link" to="/why-perfect-consult">Защо Перфект Консулт</Link></li>
                                    </ul>
                                </Col>
                                <Col className="col-12 col-sm-6 col-md-3 mb-3 mb-md-0">
                                    <h4 className="mb-2 mb-sm-3">Информация</h4>
                                    <ul className="ul-clean footer__list">
                                        <li><Link className="link" to="/terms-and-conditions">Условия за Ползване</Link></li>
                                        <li><Link className="link" to="/privacy-policy">Декларация за Поверителност</Link></li>
                                        <li><Link className="link" to="/sitemap">Карта на Сайта</Link></li>
                                        <li><Link className="link" to="/support">Проблеми със Сайта</Link></li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="col-12 col-md-8 col-lg-9 col-xxl-4 order-2">
                            <Row>
                                <Col className="col-12 col-lg-8 col-xxl-12 order-lg-1 order-xxl-0">
                                    <h4 className="mb-2 mb-sm-3">Запишете се за нашия бюлетин</h4>
                                    <Newsletter className="mb-3 mb-lg-0 mb-xxl-3" />
                                </Col>
                                <Col className="col-12 col-lg-4 col-xxl-12 order-lg-0 order-xxl-1">
                                    <h4 className="mb-2 mb-sm-3">Последвайте ни</h4>
                                    <Social className="footer__social" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="footer__bottom">
                <Container>
                    <Row className="align-items-center">
                        <Col className="col-12 col-md-6 text-center text-md-left mb-1 mb-md-0">
                            <div className="footer__bottom-copyright mb-0">© 2020 Perfect Consult - All Rights Reserved</div>
                        </Col>
                        <Col className="col-12 col-md-6 text-center text-md-right">
                            <div className="footer__bottom-author mb-0">Designed & Developed by <a title="Staniel Petrov LinkedIn Profile" href={process.env.REACT_APP_DEVELOPER_WEBSITE} target="_blank" rel="nofollow noopener noreferrer">{process.env.REACT_APP_DEVELOPER_NAME}</a></div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </footer>
    )
}

export default Footer