// React Components
import React from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVectorSquare } from '@fortawesome/free-solid-svg-icons';
import { faCube } from '@fortawesome/free-solid-svg-icons';

// Style
import '../scss/icon-features.scss';

const IconFeatures = props => {
    switch (props.type) {
        case 'area':
            return (
                <div className="icon-feature">
                    <div className="icon-feature__name">Площ</div>
                    <div className="icon-feature__icon-value">
                        <div className="icon-feature__icon"><FontAwesomeIcon icon={faVectorSquare} /></div>
                        <div className="icon-feature__value">{props.value}m²</div>
                    </div>
                </div>
            );
        case 'material':
            return (
                <div className="icon-feature">
                    <div className="icon-feature__name">Строителство</div>
                    <div className="icon-feature__icon-value">
                        <div className="icon-feature__icon"><FontAwesomeIcon icon={faCube} /></div>
                        <div className="icon-feature__value">{props.value}</div>
                    </div>
                </div>
            );
        default: return null;
    }
}

export default IconFeatures;
