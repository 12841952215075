// React Components
import React, { useEffect, useContext } from 'react';

// Actions
import {
    UPDATE_DATA
} from '../../data/actions/FeaturedPropertiesActions';

// Hooks
import useAxiosGet from '../../hooks/useAxiosGet';

// Utility
import { addNewOfferData } from '../../utility/addNewOfferData';

// Context
import { FeaturedPropertiesContext } from '../../data/FeaturedPropertiesContext';
import { PropertyContext } from '../../data/PropertyContext';

// Components
import Slider from '../Slider';
import Button from '../Button';
import PropertyCard from '../PropertyCard';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Style
import '../../scss/hero.scss';

// Assets
import HeroBgImg from "../../assets/images/hero-background.jpg";

const HeroSection = () => {
    const { data, filters, page, perPage, sort, order, updateFeaturedPropertiesState } = useContext(FeaturedPropertiesContext);
    const { lastSeen } = useContext(PropertyContext);
    const { response } = useAxiosGet(`${process.env.REACT_APP_API_PROPERTIES}?page=${page}&perPage=${perPage}&sort=${sort}&order=${order}${filters.other.includes('featured') ? '&topOffer=true' : ''}`);

    const slickSettings = {
        draggable: false,
        fade: false,
        lazyLoad: false,
        infinite: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 6000,
        pauseOnHover: true
    };
        
    useEffect(() => {
        if (response) {
            const featuredPropertiesData = addNewOfferData(response.data.data).filter(property => property.newOffer === true);
            updateFeaturedPropertiesState(UPDATE_DATA, featuredPropertiesData);
        }
        //eslint-disable-next-line
    }, [response]);

    return (
        <section className="hero" style={{ backgroundImage: `url('${HeroBgImg}')` }}>
            <span className="hero__gradient-top"></span>
            {data.length ? <span className="hero__gradient-right"></span> : null}
            <span className="hero__gradient-bottom"></span>
            <Container className="h-100">
                <Row className="h-100">
                    <Col className="col-xl-8 col-xxl-9 hero__caption">
                        <h2 className="hero__caption-title">Търсите или предлагате имот</h2>
                        <p className="hero__caption-description">Нашите агенти ще ви помогнат! Разгледайте нашите оферти или добавете имот.</p>
                        <div className="hero__caption-buttons">
                            <Button className="btn--cyan mb-2 mb-sm-0 mr-sm-2" to="/properties"><FontAwesomeIcon icon={faHome} />Всички Имоти</Button>
                            <Button className="btn--orange" to="/add-property"><FontAwesomeIcon icon={faPlus} />Добави Имот</Button>
                        </div>
                    </Col>
                    {data.length ? <Col className="d-none d-xl-flex col-xl-4 col-xxl-3 hero__featured-col">
                        <div className="hero__featured">
                            <Slider settings={slickSettings}>
                                {data.map(property => {
                                    return <PropertyCard
                                        key={property.id}
                                        id={property.id}
                                        type={property.type}
                                        price={property.price}
                                        oldPrice={property.oldPrice}
                                        image={property.image}
                                        title={property.title}
                                        description={property.description}
                                        sizeTotal={property.sizeTotal}
                                        buildingType={property.buildingType}
                                        designations={property.designations}
                                        published={property.published}
                                        newOffer={property.newOffer}
                                        topOffer={property.topOffer}
                                        isSeen={lastSeen.includes(property.id)}
                                    />
                                })}
                            </Slider>
                        </div>
                    </Col> : null}
                </Row>
            </Container>
        </section>
    );
}

export default HeroSection;
