import React, { useState, useEffect, memo } from 'react';

// Components
import FormDropdown from './FormDropdown';
import CustomCheckbox from './CustomCheckbox';

const CheckboxFilter = ({ className, title, initialState = [], options = [], onFinish }) => {
    const [selected, setSelected] = useState(initialState);

    const handleCheck = val => {
        if(!selected.indexOf(val) !== -1) {
            setSelected(currSelected => [...currSelected, val]);
        }
    }

    const handleUncheck = val => {
        setSelected(currSelected => currSelected.filter(item => item !== val));
    }

    const handleDelete = () => {
        setSelected([]);
        onFinish([]);
    }

    const handleClose = () => {
        onFinish(selected);
    }

    useEffect(() => {
        setSelected(initialState);
    }, [initialState])

    return (
        <FormDropdown className={className} title={title} type="list" selectionLength={selected.length} onDelete={handleDelete} onClose={handleClose}>
            {options.map(option => {
                return <CustomCheckbox key={option.value} title={option.title} value={option.value} reset={selected.length === 0} initSelected={selected.indexOf(option.value) !== -1} onCheck={handleCheck} onUncheck={handleUncheck} />
            })}
        </FormDropdown>
    );
}

export default memo(CheckboxFilter);
