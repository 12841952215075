import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import LastSeenProperties from '../components/sections/LastSeenProperties';
import InnerPageTop from '../components/sections/InnerPageTop';
import InnerPageContent from '../components/sections/InnerPageContent';
import ServicePageCard from '../components/ServicePageCard';
import ServiceCard from '../components/ServiceCard';
import InquiryForm from '../components/InquiryForm';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Assets
import ColoredLogoBig from '../assets/images/colored-logo-big.png';

// Icons
import {
    faSearchDollar,
    faBalanceScale,
    faChartBar,
    faCrown,
    faStar,
    faCamera,
    faFileSignature,
    faHouseUser,
    faHandshake,
    faKey,
    faUserTie
} from '@fortawesome/free-solid-svg-icons';

// Styles
import '../scss/services.scss';

const Services = () => {
    return (
        <main className="services">
            <Helmet>
                <title>Услуги - Перфект Консулт</title>
            </Helmet>
            <InnerPageTop pageTitle="Услуги" pageDescription="Увеличете шансовете за успешна продажба на вашия имот!" />
            <InnerPageContent asideChildren={(
                <>
                    <InquiryForm page="Страница Услуги" title="Искаш безплатна консултация?" description="Изпрати въпросите си към нас." placeholder={`Здравейте имам въпрос относно услуга.`} messageRows="5" />
                    <ServicePageCard title="Допълнителни услуги" description="Разгледайте комплексните решения, създадени за ваше удобство." btnColor="dark" btnIcon={faStar} btnText="Допълнителни Услуги" btnTo="/services/additional" />
                    <ServicePageCard title="За собственици" description="Разгледайте комплексните решения, създадени за ваше удобство." btnColor="cyan" btnIcon={faHouseUser} btnText="За Собственици" btnTo="/services/for-owners" />
                    <ServicePageCard title="Купуваме директно" description="Най-лесният начин да продадеш имота си в няколко стъпки." btnColor="orange" btnIcon={faHandshake} btnText="Купуваме директно" btnTo="/services/we-buy-directly" />
                    <ServicePageCard title="Управление на Имот" description="Разгледайте комплексните решения, създадени за ваше удобство." btnColor="red" btnIcon={faKey} btnText="Управление на Имот" btnTo="/services/propety-control" />
                    <ServicePageCard title="За инвеститори" description="Разгледайте комплексните решения, създадени за ваше удобство." btnColor="green" btnIcon={faUserTie} btnText="За инвеститори" btnTo="/services/for-investors" />
                </>
            )}>
                <Row>
                    <Col className="d-flex flex-column flex-xxl-row align-items-center justify-content-center">
                        <img className="mb-4 mb-xxl-0" src={ColoredLogoBig} alt="Colored Logo" />
                        <p className="ml-xxl-4">Определянето на адекватна пазарна цена играе важна роля за ефективната продажба. Това е верния старт на процеса продажба. Екипът на Перфект Консулт познава в детайли всичко, което ви предстои, когато вземете решение за продажба на ваша недвижима собственост.</p>
                    </Col>
                </Row>
                <Row>
                    {servicesData.map(service => (
                        <Col className="col-12 col-lg-6 mb-4">
                            <ServiceCard icon={service.icon} title={service.title} description={service.description} />
                        </Col>
                    ))}
                </Row>
            </InnerPageContent>
            <LastSeenProperties />
        </main>
    );
}

export default Services;

const servicesData = [
    {
        icon: faSearchDollar,
        title: 'Професионална Оценка',
        // description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae vel magnam voluptas eius impedit dignissimos minima quasi aliquid, ex accusamus nesciunt odio?'
    },
    {
        icon: faBalanceScale,
        title: 'Консултация с Юрист',
        // description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae vel magnam voluptas eius impedit dignissimos minima quasi aliquid, ex accusamus nesciunt odio?'
    },
    {
        icon: faChartBar,
        title: 'Маркетинг Консултаця',
        // description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae vel magnam voluptas eius impedit dignissimos minima quasi aliquid, ex accusamus nesciunt odio?'
    },
    {
        icon: faCrown,
        title: 'Професионално Представяне',
        // description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae vel magnam voluptas eius impedit dignissimos minima quasi aliquid, ex accusamus nesciunt odio?'
    },
    {
        icon: faStar,
        title: 'Онлайн Промотиране',
        // description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae vel magnam voluptas eius impedit dignissimos minima quasi aliquid, ex accusamus nesciunt odio?'
    },
    {
        icon: faCamera,
        title: 'Професионално Заснемане',
        // description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae vel magnam voluptas eius impedit dignissimos minima quasi aliquid, ex accusamus nesciunt odio?'
    },
    {
        icon: faFileSignature,
        title: 'Съдействие с Документация',
        // description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae vel magnam voluptas eius impedit dignissimos minima quasi aliquid, ex accusamus nesciunt odio?'
    },
]