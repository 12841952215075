import {
    UPDATE_DATA,
    UPDATE_POPULAR_DATA,
    UPDATE_NEWS_DATA,
    UPDATE_ARTICLE,
    UPDATE_LAST_SEEN,
    UPDATE_TAGS,
    UPDATE_PAGE,
    UPDATE_PER_PAGE,
    UPDATE_SORT,
    UPDATE_ORDER,
    UPDATE_SEARCH
} from '../actions/BlogActions';

const BlogReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_DATA:
            return {
                ...state,
                data: action.payload.data,
                total: action.payload.total || 0,
                toItem: action.payload.to || 0,
                currentPage: action.payload.current_page || 1,
                lastPage: action.payload.last_page || 1,
            }
        case UPDATE_POPULAR_DATA:
            return {
                ...state,
                popularData: action.payload.data
            }
        case UPDATE_NEWS_DATA:
            return {
                ...state,
                newsData: action.payload.data
            }
        case UPDATE_TAGS:
            return {
                ...state,
                tags: action.payload
            }
        case UPDATE_ARTICLE:
            return {
                ...state,
                article: action.payload
            }
        case UPDATE_LAST_SEEN:
            return {
                ...state,
                lastSeen: [...state.lastSeen, action.payload]
            }
        case UPDATE_PAGE:
            return {
                ...state,
                page: action.payload
            }
        case UPDATE_PER_PAGE:
            return {
                ...state,
                perPage: typeof action.payload === 'object' ? action.payload : { id: action.payload, name: `${action.payload}` }
            }
        case UPDATE_SORT:
            return {
                ...state,
                sort: typeof action.payload === 'object' ? action.payload : { id: action.payload, name: returnSortName(action.payload) }
            }
        case UPDATE_ORDER:
            return {
                ...state,
                order: typeof action.payload === 'object' ? action.payload : { id: action.payload, name: returnOrderName(action.payload) }
            }
        case UPDATE_SEARCH:
            return {
                ...state,
                search: action.payload
            }
        default:
            return state
    }
}

export default BlogReducer;

const returnSortName = id => {
    switch (id) {
        case 'published_at':
            return 'Публикувано'
        case 'views':
            return 'Популярност'
        default:
            return ''
    }
}

const returnOrderName = id => {
    switch (id) {
        case 'asc':
            return 'Възходящо'
        case 'desc':
            return 'Низходящо'
        default:
            return ''
    }
}