import React, { useContext } from 'react';

// Components
import Slider from '../Slider';

// Context
import { GlobalContext } from '../../data/GlobalContext';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Styles
import '../../scss/reviews-slider.scss';

const ReviewsSlider = () => {
    const { reviews, featuredReviews } = useContext(GlobalContext);

    const reviewsSliderData = reviews.filter(item => featuredReviews.some(featured => featured === item.id));

    const slickSettings = {
        draggable: false,
        fade: false,
        lazyLoad: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 12000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 575.98,
                settings: {
                    arrows: false
                }
            },
        ]
    };

    return (
        <section className="reviews-slider">
            <Container>
                <Row>
                    <Col>
                        <Slider settings={slickSettings}>
                            {reviewsSliderData.map(review => {
                                return (
                                    <div key={review.id}>
                                        <div className="reviews-slider__review">
                                            <q className="reviews-slider__content">{review.content}</q>
                                            <div className="reviews-slider__author">{review.author}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Slider>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default ReviewsSlider;
