import React, { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';

// Actions
import {
    UPDATE_DATA
} from '../data/actions/PropertiesActions';

// Utility
import { addNewOfferData } from '../utility/addNewOfferData';
import { updateStateFromSearch } from '../utility/manageSearchParams';
import { setQueryStringWithoutPageReload } from '../utility/setQueryStringWithoutPageReload';

// Hooks
import useAxiosGet from '../hooks/useAxiosGet';

// Context
import { PropertiesContext } from '../data/PropertiesContext';

// Components
import Loading from '../components/Loading';
import InnerPageTop from '../components/sections/InnerPageTop';
import SearchBar from '../components/sections/SearchBar';
import PropertyListing from '../components/sections/PropertyListing';
import PropertiesControls from '../components/sections/PropertiesControls';
import LastSeenProperties from '../components/sections/LastSeenProperties';

// Styles
import '../scss/properties.scss';

const Properties = ({ location }) => {
    const { search, updatePropertiesState } = useContext(PropertiesContext);
    const { isLoading, response } = useAxiosGet(`${process.env.REACT_APP_API_PROPERTIES}${search}`);
    const searchStrObj = new URLSearchParams(search);

    useEffect(() => {
        updateStateFromSearch(location.search, updatePropertiesState);
        //eslint-disable-next-line
    }, [location])

    useEffect(() => {
        if (response) {
            window.scrollTo(0, 0);
            const propertiesData = { ...response.data, data: searchStrObj.has('newOffer') ? addNewOfferData(response.data.data).filter(property => property.newOffer === true) : addNewOfferData(response.data.data) }
            updatePropertiesState(UPDATE_DATA, propertiesData);
        }
        //eslint-disable-next-line
    }, [response])

    useEffect(() => {
        if (search) {
            setQueryStringWithoutPageReload(search);
        }
        //eslint-disable-next-line
    }, [search])

    return (
        <main className="properties">
            <Helmet>
                <title>Имоти - Перфект Консулт</title>
            </Helmet>
            <InnerPageTop pageTitle="Имоти" pageDescription="Търсете във всички обяви на имоти." />
            <SearchBar />
            {isLoading ? <Loading /> : <PropertyListing />}
            <PropertiesControls />
            <LastSeenProperties />
        </main>
    );
}

export default Properties;
