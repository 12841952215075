import {
    UPDATE_DATA,
} from '../actions/LastSeenActions';

const LastSeenReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_DATA:
            return {
                ...state,
                data: action.payload
            }
        default:
            return state
    }
}

export default LastSeenReducer;