import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

// Components
import FormDropdown from './FormDropdown';
import CustomRadio from './CustomRadio';

const RadioFilter = ({ className, initialState = [], title, options, onSelect = () => {}, onClose = () => {} }) => {
    const [selected, setSelected] = useState(initialState);

    useEffect(() => {
        onSelect(selected);
        //eslint-disable-next-line
    }, [selected])

    const handleClose = useCallback(() => {
        onClose(selected);
        //eslint-disable-next-line
    }, [selected])

    return (
        <FormDropdown className={className} title={title} type="list" onClose={handleClose}>
            {options.map(option => {
                return <CustomRadio key={option.id} title={option.name} value={option.id} initChecked={selected.id === option.id} onCheck={() => setSelected(option)} />
            })}
        </FormDropdown>
    );
}

RadioFilter.propTypes = {
    className: PropTypes.string,
    initialState: PropTypes.object,
    title: PropTypes.string,
    options: PropTypes.array,
    onSelect: PropTypes.func
}

export default RadioFilter;
