import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';

// Styles
import '../scss/custom-radio.scss';

const CustomRadio = ({ className, type, value, title, initChecked, onCheck }) => {
    const [state, setState] = useState({
        selected: false,
        value: value
    });

    const selectedHandler = () => {
        setState({
            ...state,
            selected: true
        });
        onCheck(state.value);
    }

    useEffect(() => {
        if (initChecked) {
            setState({
                ...state,
                selected: true
            });
        } else {
            setState({
                ...state,
                selected: false
            });
        }
        //eslint-disable-next-line
    }, [initChecked])

    return (
        <button className={`custom-radio${className ? ' ' + className : ''}${type === 'button' ? ' custom-radio--button' : ''}${state.selected ? ' selected' : ''}`} onClick={selectedHandler} type="button">
            {type !== 'button' ? <div className="custom-radio__check"></div> : null}
            <div className="custom-radio__title">{title}</div>
        </button>
    );
}

CustomRadio.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    initChecked: PropTypes.bool,
    onCheck: PropTypes.func
}

export default memo(CustomRadio);
