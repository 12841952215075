import React, { useState } from 'react';
import Slider from '../Slider';

// Utility
import { formatPublished } from '../../utility/formatPublished';

// Components
import PropertyExtras from '../PropertyExtras';
import ImageLightbox from '../ImageLightbox';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faImages,
    faHandPointUp
} from '@fortawesome/free-solid-svg-icons';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Styles
import '../../scss/property-gallery.scss';

const PropertyGallery = ({ images = [], title, id, published, oldPrice = false, newOffer = false, topOffer = false }) => {
    const [mainSlider, setMainSlider] = useState(null);
    const [navSlider, setNavSlider] = useState(null);
    const [navCurrent, setNavCurrent] = useState(1);
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [lightboxImageIndex, setLightboxImageIndex] = useState(0);

    const mainSliderSettings = {
        draggable: false,
        fade: true,
        lazyLoad: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
        waitForAnimate: false,
        arrows: false,
        asNavFor: navSlider,
        ref: slider => setMainSlider(slider)
    };

    const navSliderSettings = {
        centerMode: true,
        draggable: false,
        fade: false,
        lazyLoad: false,
        infinite: true,
        speed: 500,
        slidesToShow: images.length > 3 ? 3 : images.length,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        pauseOnHover: true,
        waitForAnimate: false,
        focusOnSelect: true,
        centerPadding: '0',
        asNavFor: mainSlider,
        ref: slider => setNavSlider(slider),
        beforeChange: current => setNavCurrent(current + 1)
    };

    const navInnerStyles = {
        maxWidth: images.length >= 3 ? `100%` : images.length === 2 ? '234px' : ''
    }

    const handleOpenLightbox = (index) => {
        setLightboxImageIndex(index);
        setIsLightboxOpen(true);
    }

    const handleCloseLightbox = () => {
        setIsLightboxOpen(false);
    }

    return (
        <section className={`property-gallery${images.length <= 1 ? ' property-gallery--single' : ''}`}>
            <div className="property-gallery__main">
                <div className="property-gallery__click-icon"><FontAwesomeIcon icon={faHandPointUp} /></div>
                <Slider settings={mainSliderSettings}>
                    {images.map((image, index) => {
                        return (
                            <figure className="property-gallery__figure" key={image} onClick={() => handleOpenLightbox(index)}>
                                <img className="property-gallery__img property-gallery__img--background" src={image} alt={`Slide: ${image}`} />
                                <img className="property-gallery__img property-gallery__img--foreground" src={image} alt={`Slide: ${image}`} />
                            </figure>
                        )
                    })}
                </Slider>
            </div>
            <div className="property-gallery__header">
                <Container>
                    <Row>
                        <Col className="property-gallery__info d-none d-xl-flex col-xl-7 col-xl-8 col-xxl-9">
                            <PropertyExtras oldPrice={oldPrice} newOffer={newOffer} topOffer={topOffer} />
                            <h1 className="property-gallery__info-title">{title}</h1>
                            <div className="property-gallery__info-other">
                                <div className="mr-2">Обява №: {id}</div>
                                <div>Публикувана: <time dateTime={published}>{formatPublished(published)}</time></div>
                            </div>
                        </Col>
                        {images.length !== 1 ? (
                            <Col className="property-gallery__nav col-md-6 col-lg-5 col-xl-4 col-xxl-3 ml-auto ml-xl-0">
                                <div className="property-gallery__nav-inner" style={navInnerStyles}>
                                    <div className="property-gallery__nav-info">
                                        <div className="property-gallery__nav-button"><FontAwesomeIcon className="mr-1" icon={faImages} />Галерия</div>
                                        <div className="property-gallery__nav-index">{navCurrent} от {images.length}</div>
                                    </div>
                                    <Slider settings={navSliderSettings}>
                                        {images.map(image => {
                                            return (
                                                <figure className="property-gallery__figure" key={image}>
                                                    <img className="property-gallery__img" src={image} alt={`Slide: ${image}`} />
                                                </figure>
                                            )
                                        })}
                                    </Slider>
                                </div>
                            </Col>
                        ) : null}
                    </Row>
                </Container>
            </div>
            {images.length ? <ImageLightbox isOpen={isLightboxOpen} images={images} index={lightboxImageIndex} onClose={handleCloseLightbox} /> : null}
        </section>
    );
}

export default PropertyGallery;
