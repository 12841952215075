import React, { useState, useEffect, useRef } from 'react';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

// Style
import '../scss/info-bubble.scss';

const InfoBubble = ({ children }) => {
    const [active, setActive] = useState(false);
    const [infoBubbleX, setInfoBubbleX] = useState({ left: '50%' });
    const [infoBubbleY, setInfoBubbleY] = useState({ top: '50%' });
    const infoBubbleIcon = useRef(null);

    const handleMouseEnter = () => setActive(true);
    const handleMouseLeave = () => setActive(false);

    const calculateInfoBubbleX = iconEl => {
        const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        const iconX = Math.round(iconEl.getBoundingClientRect().x);
        const iconWidth = Math.round(iconEl.getBoundingClientRect().width);
        const scrollbarWidth = window.innerWidth - document.body.scrollWidth;

        if (viewportWidth < 576) {
            return { left: '50%', transform: 'translateX(-50%)' }
        }

        if (iconX > viewportWidth / 2) {
            return { right: (viewportWidth - iconX) - scrollbarWidth };
        } else {
            return { left: iconX + iconWidth };
        }
    }

    const calculateInfoBubbleY = iconEl => {
        const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        const iconY = Math.round(iconEl.getBoundingClientRect().y);
        const iconHeight = Math.round(iconEl.getBoundingClientRect().height);

        if (iconY > viewportHeight / 2) {
            return { bottom: viewportHeight - iconY };
        } else {
            return { top: iconY + iconHeight };
        }
    }

    useEffect(() => {
        if (active) {
            setInfoBubbleX(calculateInfoBubbleX(infoBubbleIcon.current));
            setInfoBubbleY(calculateInfoBubbleY(infoBubbleIcon.current));
        }
    }, [active])

    return (
        <div className={`info-bubble${active ? ' active' : ''}`}>
            <div className="info-bubble__icon" ref={infoBubbleIcon} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <FontAwesomeIcon icon={faInfoCircle} />
            </div>
            <div className="info-bubble__bubble" style={{ ...infoBubbleX, ...infoBubbleY }}>
                {children}
            </div>
        </div>
    );
}

export default InfoBubble;
