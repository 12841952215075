import React from 'react';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons';

// Styles
import '../scss/review-card.scss';

const ReviewCard = ({ author, content }) => {
    return (
        <article className="review-card">
            <div className="review-card__icon"><FontAwesomeIcon icon={faQuoteRight} /></div>
            <header className="review-card__author">{author}</header>
            <blockquote className="review-card__content">{content}</blockquote>
        </article>
    );
}

export default ReviewCard;
