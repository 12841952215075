import React, { useContext, useCallback } from 'react';

// Components
import RadioFilter from '../RadioFilter';

// Context
import { PropertiesContext } from '../../data/PropertiesContext';
import { FiltersContext } from '../../data/FiltersContext';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Styles
import '../../scss/properties-controls.scss';

const PropertiesControls = ({ onFinish = () => { } }) => {
    const { perPage, sort, order, toItem, total, currentPage, lastPage, updatePropertiesState } = useContext(PropertiesContext);
    const { perPageOptions, sortOptions, orderOptions } = useContext(FiltersContext);

    const hanldePerPageUpdate = useCallback(obj => {
        if (perPage !== obj) {
            updatePropertiesState('update-per-page', obj);
            updatePropertiesState('update-page', 1);
            onFinish();
        }
        //eslint-disable-next-line
    }, [perPage])
    
    const hanldeSortUpdate = useCallback(obj => {
        if (sort !== obj) {
            updatePropertiesState('update-sort', obj);
            onFinish();
        }
        //eslint-disable-next-line
    }, [sort])

    const hanldeOrderUpdate = useCallback(obj => {
        if (order !== obj) {
            updatePropertiesState('update-order', obj);
            onFinish();
        }
        //eslint-disable-next-line
    }, [order])
    
    const hanldeNextPage = useCallback(() => {
        if (currentPage <= lastPage) {
            updatePropertiesState('update-page', currentPage + 1);
            onFinish();
        }
        //eslint-disable-next-line
    }, [currentPage])
    
    const hanldePrevPage = useCallback(() => {
        if (currentPage >= 1) {
            updatePropertiesState('update-page', currentPage - 1);
            onFinish();
        }
        //eslint-disable-next-line
    }, [currentPage])

    return (
        <section className="properties-controls">
            <Container>
                <Row>
                    <Col className="mb-6">
                        <div className="properties-controls__wrapper">
                            <div className="properties-controls__total">{toItem ? `${toItem} от ` : ''}{total} имота</div>
                            <div className="properties-controls__radios">
                                <RadioFilter className="per-page" title={`На Страница: ${perPage.name}`} initialState={perPage} options={perPageOptions} onClose={hanldePerPageUpdate} />
                                <RadioFilter className="sort" title={`Подредба: ${sort.name}`} initialState={sort} options={sortOptions} onClose={hanldeSortUpdate} />
                                <RadioFilter className="order" title={order.name} initialState={order} options={orderOptions} onClose={hanldeOrderUpdate} />
                            </div>
                            <div className="properties-controls__nav">
                                <button className="properties-controls__nav-prev" type="button" onClick={hanldePrevPage} disabled={currentPage === 1}><FontAwesomeIcon icon={faArrowLeft} /></button>
                                {currentPage} от {lastPage}
                                <button className="properties-controls__nav-next" type="button" onClick={hanldeNextPage} disabled={currentPage === lastPage}><FontAwesomeIcon icon={faArrowRight} /></button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default PropertiesControls;
