import React, { useContext } from 'react';

// Context
import { PropertiesContext } from '../../data/PropertiesContext';
import { PropertyContext } from '../../data/PropertyContext';

// Components
import PropertyCard from '../../components/PropertyCard';
import NoResults from '../../components/NoResults';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Styles
import '../../scss/property-listing.scss';

const PropertyListing = () => {
    const { data } = useContext(PropertiesContext);
    const { lastSeen } = useContext(PropertyContext);

    return (
        <section className="property-listing">
            <Container className="property-listing__container">
                <Row>
                    {data.length ? null : <Col>
                        <NoResults />
                    </Col>}
                    {data.length ? data.map(property => {
                        return (
                            <Col className="col-12 col-md-6 col-xl-4 col-xxl-3 mb-4" key={property.id}>
                                <PropertyCard
                                    id={property.id}
                                    type={property.type}
                                    price={property.price}
                                    oldPrice={property.oldPrice}
                                    image={property.image}
                                    title={property.title}
                                    description={property.description}
                                    sizeTotal={property.sizeTotal}
                                    buildingType={property.buildingType}
                                    designations={property.designations}
                                    published={property.published}
                                    newOffer={property.newOffer}
                                    topOffer={property.topOffer}
                                    isSeen={lastSeen.includes(property.id)}
                                />
                            </Col>
                        )}) : null}
                </Row>
            </Container>
        </section>
    );
}

export default PropertyListing;
