import React from 'react';

// Components
import Modal from 'react-modal';
import ToastCloseButton from "./ToastCloseButton";

Modal.setAppElement('#portal');

const CustomModal = ({ children, isOpen = false, onClose = () => {} }) => {

    const customModalStyles = {
        overlay: {
            zIndex: '9999',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
            overflow: 'auto',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '1.5rem 1.875rem',
            maxWidth: '576px',
            maxHeight: '90vh',
            borderRadius: '0.5rem',
            border: 'none',
            transform: 'translate(-50%, -50%)'
        }
    };

    return (
        <Modal style={customModalStyles} isOpen={isOpen} onRequestClose={onClose}>
            <ToastCloseButton closeToast={onClose} />
            {children}
        </Modal>
    );
}

export default CustomModal;
