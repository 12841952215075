import React, { useState, useEffect } from 'react';
//eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

// Components
import CustomCheckbox from './CustomCheckbox';

// Assets
import { ReactComponent as LoadingCirclesSvg } from '../assets/images/loading-circles.svg';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

// Styles
import '../scss/newsletter.scss';
import '../scss/button.scss';

const Newsletter = ({ className, onNewsletterSuccess = () => { }, modal = false }) => {
    const [email, setEmail] = useState('');
    const [consent, setConsent] = useState(false);
    const [consentError, setConsentError] = useState(false);

    const toastID = React.useRef(null);
    const notifyToast = () => toastID.current = toast(<div className="d-flex"><LoadingCirclesSvg className="mr-1 ml-0" /> Заявката се изпраща...</div>, { position: 'top-center', autoClose: false });
    const updateToast = (type, message) => toast.update(toastID.current, { type, render: message, position: 'top-center', autoClose: 5000 });

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (consent) {
            notifyToast();

            await axios.post(process.env.REACT_APP_API_EMAIL_NEWSLETTER, {
                email,
            }).then(resp => {
                console.log(resp);
                updateToast('success', 'Успешно записване за бюлетин!');
                onNewsletterSuccess();
            }).catch(err => {
                console.log(err);
                updateToast('error', 'Възникна грешка при изпращането на заявката или този имейл вече е регистриран!');
            });

            setEmail('');
        } else {
            setConsentError(true);
        }
    }

    useEffect(() => {
        if (consent) {
            setConsentError(false);
        }
    }, [consent])

    return (
        <form className={`newsletter${className ? ' ' + className : ''}`} onSubmit={handleSubmit}>
            <input className="newsletter__input flex-grow-1" type="email" name="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Вашият Имейл" required />
            <button className="btn btn--orange newsletter__btn"><FontAwesomeIcon icon={faPaperPlane} />Изпрати</button>
            <div className="newsletter__consent">
                {modal ? <CustomCheckbox title={(<>Приемам <a href="/terms-and-conditions" title="Условията за Ползване">Условията за ползване на Перфект Консулт</a> и <a href="/privacy-policy" title="Декларацията за Поверителност">Декларацията за Поверителност</a></>)} initSelected={consent} onCheck={() => setConsent(true)} onUncheck={() => setConsent(false)} /> : <CustomCheckbox title={(<>Приемам <Link to="/terms-and-conditions" title="Условията за Ползване">Условията за ползване на Перфект Консулт</Link> и <Link to="/privacy-policy" title="Декларацията за Поверителност">Декларацията за Поверителност</Link></>)} initSelected={consent} onCheck={() => setConsent(true)} onUncheck={() => setConsent(false)} />}
                {consentError ? <div className="inquiry-form__error">Моля попълнете!</div> : null}
            </div>
        </form>
    );
}

export default Newsletter;
