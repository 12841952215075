import React, { useReducer, createContext, useEffect } from 'react';

// Utility
import { updateSearchFromState } from '../utility/manageSearchParams';

// Reducers
import PropertiesReducer from '../data/reducers/PropertiesReducer';

export const PropertiesContext = createContext();

export const PropertiesProvider = ({ children, initialState }) => {
    const [state, dispatch] = useReducer(PropertiesReducer, initialState);
    const updatePropertiesState = (type, payload) => { dispatch({ type, payload }) };

    useEffect(() => {
        updateSearchFromState(state, updatePropertiesState);
        //eslint-disable-next-line
    }, [state.page, state.perPage, state.sort, state.order, state.type, state.selectedSubAreas, state.filters])

    return (
        <PropertiesContext.Provider value={{ ...state, updatePropertiesState }}>
            {children}
        </PropertiesContext.Provider>
    )
};