import React, { useEffect, useContext } from 'react';
import sanitizeHtml from 'sanitize-html';

// Utility
import { descriptionShortener } from '../../utility/descriptionShortener';

// Actions
import {
    UPDATE_NEWS_DATA
} from '../../data/actions/BlogActions';

// Hooks
import useAxiosGet from '../../hooks/useAxiosGet';

// Context
import { BlogContext } from '../../data/BlogContext';

// Components
import SectionHeading from '../SectionHeading';
import CardSlider from '../CardSlider';
import ArticleCard from '../ArticleCard';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Styles
import '../../scss/news.scss';

const News = () => {
    const { newsData, updateBlogState } = useContext(BlogContext);
    const { response } = useAxiosGet(`${process.env.REACT_APP_API_BLOG_ARTICLES}?sort=published_at&order=desc&perPage=10&tags[]=News&tags[]=news&tags[]=Новини&tags[]=новини`);

    const slickSettings = {
        draggable: false,
        fade: false,
        lazyLoad: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 6000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1619,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1239,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };

    useEffect(() => {
        if (response) {
            updateBlogState(UPDATE_NEWS_DATA, {
                ...response.data, data: response.data.data.map(article => {
                    return {
                        ...article, content: descriptionShortener(sanitizeHtml(article.content, {
                            exclusiveFilter: (frame) => {
                                return frame.tag === 'h2' || frame.tag === 'h3' || frame.tag === 'h4' || frame.tag === 'h5' || frame.tag === 'h6';
                            }
                        }).replace(/(<([^>]+)>)/gi, '').replace(/(\r\n|\n|\r)/gm, '').replace('  ', ' ').replace('   ', ' '), 160)
                    }
                })
            });
        }
        //eslint-disable-next-line
    }, [response])

    if (newsData.length) {
        return (
            <section className="news">
                <Container>
                    <Row>
                        <Col>
                            <SectionHeading className="text-center" heading="Новини" subHeading="Новини от нашия блог." />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CardSlider settings={slickSettings} linkText="Виж Всички" linkUrl="/blog?sort=published_at&order=desc&tags[]=News&tags[]=news&tags[]=Новини&tags[]=новини">
                                {newsData.map(article => {
                                    return <ArticleCard
                                        key={article.id}
                                        id={article.id}
                                        image={article.thumbnail}
                                        title={article.title}
                                        description={article.content}
                                        published={article.publishedAt}
                                        tags={article.tags}
                                    />
                                })}
                            </CardSlider>
                        </Col>
                    </Row>
                </Container>
            </section >
        );
    } else {
        return null
    }
}

export default News;
