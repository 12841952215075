import {
    UPDATE_FILTERS_SIZE,
    UPDATE_FILTERS_PRICE,
    UPDATE_FILTERS_YEAR,
    UPDATE_FILTERS_TYPES,
    UPDATE_FILTERS_CONSTRUCTION,
    UPDATE_FILTERS_HEATING,
    UPDATE_FILTERS_FURNISHING,
    UPDATE_FILTERS_FACING,
    UPDATE_FILTERS_STAGE,
    UPDATE_FILTERS_OTHER,
    UPDATE_SELECTED_SUB_AREA,
    UPDATE_OFFER_TYPE,
    UPDATE_PAGE,
    UPDATE_PER_PAGE,
    UPDATE_SORT,
    UPDATE_ORDER,
    UPDATE_SEARCH
} from '../data/actions/PropertiesActions';

// Update Search Query from State
export const updateSearchFromState = (state, dispatch) => {
    // Get Array Search Params from State
    const propertyTypes = `${state.filters.types.length ? '&' + state.filters.types.map(val => `propertyTypes[]=${val}`).join('&') : ''}`;
    const buildingTypes = `${state.filters.construction.length ? '&' + state.filters.construction.map(val => `buildingTypes[]=${val}`).join('&') : ''}`;
    const heatingTypes = `${state.filters.heating.length ? '&' + state.filters.heating.map(val => `heatingTypes[]=${val}`).join('&') : ''}`;
    const interiorTypes = `${state.filters.furnishing.length ? '&' + state.filters.furnishing.map(val => `interiorTypes[]=${val}`).join('&') : ''}`;
    const viewDirections = `${state.filters.facing.length ? '&' + state.filters.facing.map(val => `viewDirections[]=${val}`).join('&') : ''}`;
    const buildStages = `${state.filters.stage.length ? '&' + state.filters.stage.map(val => `buildStages[]=${val}`).join('&') : ''}`;
    const subAreas = `${state.selectedSubAreas.length ? '&' + state.selectedSubAreas.map(val => `subAreas[]=${val}`).join('&') : ''}`;

    // Combine Array Search Params into Query using URLSearchParams
    const newSearchParams = new URLSearchParams(`${propertyTypes}${buildingTypes}${heatingTypes}${interiorTypes}${viewDirections}${buildStages}${subAreas}`);

    // Collect and Add Search Options to Query String
    if (state.page !== 1) newSearchParams.set("page", state.page * 1);
    if (state.perPage.id !== 20) newSearchParams.set("perPage", state.perPage.id * 1);
    if (state.sort) newSearchParams.set("sort", state.sort.id);
    if (state.order) newSearchParams.set("order", state.order.id);
    if (state.type === 'buy') newSearchParams.set("forSale", true);
    if (state.type === 'rent') newSearchParams.set("forRent", true);
    if (state.filters.other.includes('featured')) newSearchParams.set("topOffer", true);
    if (state.filters.other.includes('newoffer')) newSearchParams.set("newOffer", true);
    if (state.filters.other.includes('garage')) newSearchParams.set("garage", true);
    if (state.filters.other.includes('parking')) newSearchParams.set("parking", true);
    if (state.filters.other.includes('pets')) newSearchParams.set("pets", true);
    if (state.filters.other.includes('elevator')) newSearchParams.set("elevator", true);
    if (state.filters.other.includes('discount')) newSearchParams.set("onSale", true);
    if (state.filters.other.includes('video')) newSearchParams.set("video", true);
    if (state.filters.size[0] !== '') newSearchParams.set("minSize", state.filters.size[0]);
    if (state.filters.size[1] !== '') newSearchParams.set("maxSize", state.filters.size[1]);
    if (state.filters.price[0] !== '') newSearchParams.set("minPrice", state.filters.price[0]);
    if (state.filters.price[1] !== '') newSearchParams.set("maxPrice", state.filters.price[1]);
    if (state.filters.year[0] !== '') newSearchParams.set("minYear", state.filters.year[0]);
    if (state.filters.year[1] !== '') newSearchParams.set("maxYear", state.filters.year[1]);
    // if (state.selectedArea) newSearchParams.set("area", `${state.selectedArea.id}`);

    // Update Search State
    dispatch(UPDATE_SEARCH, `?${decodeURIComponent(newSearchParams.toString())}`);
}

// Update State from Search Query
export const updateStateFromSearch = (search = '', dispatch) => {
    // Create and store search params object using URLSearchParams
    const searchParams = new URLSearchParams(search);

    // Collect Other Options from Search Params in Array
    let other = [];
    if (searchParams.has('pets')) other.push('pets');
    if (searchParams.has('parking')) other.push('parking');
    if (searchParams.has('garage')) other.push('garage');
    if (searchParams.has('elevator')) other.push('elevator');
    if (searchParams.has('video')) other.push('video');
    if (searchParams.has('onSale')) other.push('discount');
    if (searchParams.has('topOffer')) other.push('featured');
    if (searchParams.has('newOffer')) other.push('newoffer');

    // Update PropertiesContext state from searchParams
    dispatch(UPDATE_FILTERS_OTHER, other);
    dispatch(UPDATE_PAGE, searchParams.has('page') ? searchParams.get('page') : 1);
    dispatch(UPDATE_PER_PAGE, searchParams.has('perPage') ? searchParams.get('perPage') : 20);
    dispatch(UPDATE_SORT, searchParams.has('sort') ? searchParams.get('sort') : 'published');
    dispatch(UPDATE_ORDER, searchParams.has('order') ? searchParams.get('order') : 'desc');
    if (searchParams.has('forSale')) dispatch(UPDATE_OFFER_TYPE, 'buy');
    if (searchParams.has('forRent')) dispatch(UPDATE_OFFER_TYPE, 'rent');
    dispatch(UPDATE_FILTERS_SIZE, [searchParams.has('minSize') ? searchParams.get('minSize') : '', searchParams.has('maxSize') ? searchParams.get('maxSize') : '']);
    dispatch(UPDATE_FILTERS_PRICE, [searchParams.has('minPrice') ? searchParams.get('minPrice') : '', searchParams.has('maxPrice') ? searchParams.get('maxPrice') : '']);
    dispatch(UPDATE_FILTERS_YEAR, [searchParams.has('minYear') ? searchParams.get('minYear') : '', searchParams.has('maxYear') ? searchParams.get('maxYear') : '']);
    dispatch(UPDATE_SELECTED_SUB_AREA, searchParams.has('subAreas[]') ? searchParams.getAll('subAreas[]') : []);
    dispatch(UPDATE_FILTERS_TYPES, searchParams.has('propertyTypes[]') ? searchParams.getAll('propertyTypes[]') : []);
    dispatch(UPDATE_FILTERS_STAGE, searchParams.has('buildStages[]') ? searchParams.getAll('buildStages[]') : []);
    dispatch(UPDATE_FILTERS_FACING, searchParams.has('viewDirections[]') ? searchParams.getAll('viewDirections[]') : []);
    dispatch(UPDATE_FILTERS_HEATING, searchParams.has('heatingTypes[]') ? searchParams.getAll('heatingTypes[]') : []);
    dispatch(UPDATE_FILTERS_FURNISHING, searchParams.has('interiorTypes[]') ? searchParams.getAll('interiorTypes[]') : []);
    dispatch(UPDATE_FILTERS_CONSTRUCTION, searchParams.has('buildingTypes[]') ? searchParams.getAll('buildingTypes[]') : []);
}