// React Components
import React from 'react';

// Styles
import '../scss/slick-slider.scss';

// Components
import Slick from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const Slider = ({ children, settings }) => {
    const newSettings = {
        ...settings,
        prevArrow: <SlickPrevArrow />,
        nextArrow: <SlickNextArrow />,
    }

    return (
        <Slick {...newSettings}>
            {children}
        </Slick>
    )
}

function SlickPrevArrow(arrowProps) {
    const { className, style, onClick } = arrowProps;
    return (
        <div className={className} style={{ ...style }} onClick={onClick}><FontAwesomeIcon icon={faArrowLeft} /></div>
    );
}

function SlickNextArrow(arrowProps) {
    const { className, style, onClick } = arrowProps;
    return (
        <div className={className} style={{ ...style }} onClick={onClick}><FontAwesomeIcon icon={faArrowRight} /></div>
    );
}

export default Slider
