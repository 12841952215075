// React Components
import React from 'react';

// Styles
import '../scss/property-extras.scss';

const PropertyExtras = ({ oldPrice = false, newOffer = false, topOffer = false }) => {
    if (oldPrice || newOffer || topOffer) {
        return (
            <div className="property-extras">
                {oldPrice ? <div className="property-extras__item discount">Намалена Цена</div> : null}
                {newOffer ? <div className="property-extras__item new-offer">Нова Оферта</div> : null}
                {topOffer ? <div className="property-extras__item top-offer">Топ Оферта</div> : null}
            </div>
        );
    } else {
        return null
    }

}

export default PropertyExtras;
