// React Components
import React, { useReducer, createContext } from 'react';

// Contexts Import
import { FiltersProvider } from './FiltersContext';
import { PropertyProvider } from './PropertyContext';
import { PropertiesProvider } from './PropertiesContext';
import { BlogProvider } from './BlogContext';
import { LastSeenProvider } from './LastSeenContext';
import { NewPropertiesProvider } from './NewPropertiesContext';
import { FeaturedPropertiesProvider } from './FeaturedPropertiesContext';

// Reducers
import GlobalReducer from '../data/reducers/GlobalReducer';

export const GlobalContext = createContext();

// Global Provider
export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(GlobalReducer, initialState.global);
    const updateGlobalState = (type, payload) => { dispatch({ type, payload }) };

    return (
        <GlobalContext.Provider value={{ ...state, updateGlobalState }}>
            <FiltersProvider>
                <PropertiesProvider initialState={initialState.properties}>
                    <PropertyProvider initialState={initialState.property}>
                        <BlogProvider initialState={initialState.blog}>
                            <LastSeenProvider initialState={initialState.lastSeen}>
                                <NewPropertiesProvider initialState={initialState.newProperties}>
                                    <FeaturedPropertiesProvider initialState={initialState.featuredProperties}>
                                        {children}
                                    </FeaturedPropertiesProvider>
                                </NewPropertiesProvider>
                            </LastSeenProvider>
                        </BlogProvider>
                    </PropertyProvider>
                </PropertiesProvider>
            </FiltersProvider>
        </GlobalContext.Provider>
    );
}

// Global Initial States
const initialState = {
    global: {
        isModalOpen: false,
        modalContent: null,
        reviews: [
            {
                id: 1,
                author: 'Casino Players',
                content: 'Изключителен професионализъм, за 5-та поредна година ме настаняват не съм имал абсолютно никакви проблеми. Горещо препоръчвам!'
            },
            {
                id: 2,
                author: 'Мариян Гарвански',
                content: 'Отзивчив и коректен персонал, запазили професионализъм в жестоката конкуренция, която имат. Благодаря ви!'
            },
            {
                id: 3,
                author: 'Георги Суетаров',
                content: 'Коректно и професионално обслужване с млад и динамичен екип и професионално отношение към всеки клиент. Достъпна централна локация на офиса в града.'
            },
            {
                id: 4,
                author: 'Венцислав Къчовски',
                content: 'Бързо и коректно обслужване. Предлагат реални имоти и намират това, което наистина искаш!'
            },
            {
                id: 5,
                author: 'Богомил Керефейски',
                content: 'Професионално обслужване, без губене на време.'
            },
            {
                id: 6,
                author: 'Мирела Георгиева',
                content: 'Препоръчвам! Много коректно и бързо обслужване. Вежлива и приятелска атмосфера, леко и внимателно обслужване.'
            },
            {
                id: 7,
                author: 'Владимир Христов',
                content: 'Страхотно отношение и внимание към клиента! 😊'
            },
            {
                id: 8,
                author: 'Красимира Костова',
                content: 'Добри професионалисти. много съм доволна.'
            },
            {
                id: 9,
                author: 'Деян Георгиев',
                content: 'Препоръчвам горещо. Висок професионализъм, коректно отношение.'
            },
            {
                id: 10,
                author: 'Гео Георгиев',
                content: 'Много коректни и добри професионалисти. Действат бързо и не ти губят времето. Горещо препоръчвам!'
            },
            {
                id: 11,
                author: 'Иван Тошев',
                content: 'Препоръчвам. Много съм доволен.'
            },
            {
                id: 12,
                author: 'Явор Каменов',
                content: 'Препоръчвам! Професионално отношение!'
            },
            {
                id: 13,
                author: 'Елица Кехайова',
                content: 'Коректни, предлагащи само това, което отговаря на търсенето. Професионално отношение, благодарение на тях спестих доста време.'
            },
            {
                id: 14,
                author: 'Ивелина Димитрова',
                content: 'Коректно и професионално отношение, компетентни и отзивчиви служители!'
            },
            {
                id: 15,
                author: 'Антон Петков',
                content: 'Много сме доволни от агенцията, Благодаря много на нашия агент Никол, която изключително бързо ни помогна да си намерим подходящо жилище.'
            },
            {
                id: 16,
                author: 'Х. Бозев',
                content: 'Препоръчвам! Перфектно обслужване от Перфект Консулт!'
            },
            {
                id: 17,
                author: 'Валентина Георгиева',
                content: 'Доволна съм от бързата и адекватна помощ от страна на агентката, с която работих ме. Благодаря Никол Димитрова.'
            },
            {
                id: 18,
                author: 'Силвена Дикова',
                content: 'Горещо препоръчвам! Коректно отношение към клиентите.'
            },
            {
                id: 19,
                author: 'Румяна Проданова',
                content: 'Препоръчвам! Намериха ни жилище точно по нашите предпочитания. Благодаря от ❤️ Никол Димитрова!'
            },
            {
                id: 20,
                author: 'Веселин Русев',
                content: 'Препоръчвам! Намериха жилище точно по нашите изисквания! Благодарим на Георги Минчев!'
            },
            {
                id: 21,
                author: 'Красимир Иванов',
                content: 'Много съм доволен от услугите им, работил съм с тях и за покупка и за отдаване под наем. Добро отношение и професионализъм!'
            },
            {
                id: 22,
                author: 'Никол Стоименова',
                content: 'Страхотен персонал, усмихнати и позитивни хора. Обслужването е на професионално ниво! Благодаря ви за коректността!'
            },
            {
                id: 23,
                author: 'Дойчо Дойчев',
                content: 'Препоръчвам агенцията, намерихме си жилище с тяхна помощ.'
            },
            {
                id: 24,
                author: 'Kadri Dumba',
                content: 'Изключително коректни! Отново ще се доверя на услугите ви!'
            },
            {
                id: 25,
                author: 'Станимир Колов',
                content: 'Професионално отношение! Препоръчвам с 2 ръце!'
            },
            {
                id: 26,
                author: 'Маргарита Иванова',
                content: 'Изключително сме доволни от професионализма им! Препоръчваме на всеки, който не е наясно с пазара на недвижимите имоти, както и ние не бяхме преди да свържем с тях!'
            },
            {
                id: 27,
                author: 'Милойка Христева',
                content: 'Силно препоръчвам изключително коректна фирма. Доверих им се и не съжалявам! ❤️'
            },
            {
                id: 28,
                author: 'Кремена Маринова',
                content: 'Коректно отношение и съдействие до финализиране на сделката! Препоръчваме агент Бонка Стойчева!'
            },
            {
                id: 29,
                author: 'Габриела Иванова',
                content: 'Обслужване и качество на ниво. Страхотни сте! 🙂'
            },
            {
                id: 30,
                author: 'Николета Н.',
                content: 'Страхотни са! Препоръчвам! Екип от хора който знаят какво правят.'
            },
        ],
        featuredReviews: [2, 10, 15, 22, 26, 27, 29],
    },
    properties: {
        data: [],
        filters: {
            types: [],
            size: ['', ''],
            price: ['', ''],
            year: ['', ''],
            stage: [],
            construction: [],
            furnishing: [],
            heating: [],
            facing: [],
            other: []
        },
        selectedArea: {
            id: 1,
            name: 'Пловдив град'
        },
        selectedSubAreas: [],
        type: '',
        sort: {
            id: 'published',
            name: 'Публикувано'
        },
        order: {
            id: 'desc',
            name: 'Нисходящо'
        },
        page: 1,
        perPage: {
            id: 20,
            name: '20'
        },
        currentPage: 1,
        lastPage: 1,
        toItem: 1,
        total: null,
        search: '?sort=published&order=desc'
    },
    property: {
        data: {
            designations: [],
            heatingTypes: [],
            interiorTypes: [],
            viewDirections: [],
        },
        lastSeen: JSON.parse(localStorage.getItem('lastSeen') || '[]')
    },
    lastSeen: {
        data: []
    },
    newProperties: {
        data: [],
        filters: {
            other: ['newoffer']
        },
        perPage: 10,
        sort: 'published',
        order: 'desc'
    },
    featuredProperties: {
        data: [],
        filters: {
            other: ['featured']
        },
        page: 1,
        perPage: 20,
        sort: 'published',
        order: 'desc',
        loading: false
    },
    blog: {
        data: [],
        popularData: [],
        newsData: [],
        tags: [],
        article: {
            title: '',
            thumbnail: '',
            publishedAt: '',
            content: '',
            tags: []
        },
        lastSeen: JSON.parse(localStorage.getItem('lastSeenArticles') || '[]'),
        sort: {
            id: 'published_at',
            name: 'Публикувано'
        },
        order: {
            id: 'desc',
            name: 'Низходящо'
        },
        page: 1,
        perPage: {
            id: 20,
            name: '20'
        },
        currentPage: 1,
        lastPage: 1,
        toItem: 1,
        total: null,
        search: '?sort=published_at&order=desc'
    }
}
