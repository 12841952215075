// React Components
import React, { useState, useEffect } from "react";
//eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
// import { toast } from 'react-toastify';
import { Helmet } from "react-helmet";

// Components
import Header from "./components/sections/Header";
import Footer from "./components/sections/Footer";
import OnReroute from "./components/OnReroute";
import ToastCloseButton from "./components/ToastCloseButton";
import Newsletter from "./components/Newsletter";
import CustomModal from "./components/CustomModal";

// Pages
import Home from "./pages/Home";
import Properties from "./pages/Properties";
import Property from "./pages/Property";
import AddProperty from "./pages/AddProperty";
import Services from "./pages/Services";
import AdditionalServices from "./pages/AdditionalServices";
import ForOwners from "./pages/ForOwners";
import WeBuyDirectly from "./pages/WeBuyDirectly";
import PropertyControl from "./pages/PropertyControl";
import ForInvestors from "./pages/ForInvestors";
import Reviews from "./pages/Reviews";
import Careers from "./pages/Careers";
import WhyPerfectConsult from "./pages/WhyPerfectConsult";
import Blog from "./pages/Blog";
import BlogArticle from "./pages/BlogArticle";
import Contacts from "./pages/Contacts";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Sitemap from "./pages/Sitemap";
import Support from "./pages/Support";
import NotFound from "./pages/NotFound";
// import UnderConstruction from "./pages/UnderConstruction";

// Styles
import "./scss/app.scss";

const App = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const openNewsletterModal = () => {
    if (JSON.parse(localStorage.getItem('subscribe-to-newsletter-modal')) === null) {
      setTimeout(() => {
        setModalOpen(true);
        localStorage.setItem('subscribe-to-newsletter-modal', JSON.stringify('seen'));
      }, 3000)
    }
  }

  useEffect(() => {
    openNewsletterModal();

    // if (JSON.parse(localStorage.getItem('site-under-construction-notification')) === null) {
    //   setTimeout(() => {
    //     toast.error('Някои страници и функции в сайта са в процес на изграждане.', { position: 'top-center', autoClose: 10000, onClose: () => openNewsletterModal() });
    //     localStorage.setItem('site-under-construction-notification', JSON.stringify('seen'));
    //   }, 2000)
    // } else {
    //   openNewsletterModal();
    // }
  }, []);

  const handleCloseModal = () => setModalOpen(false);

  return (
    <div className="app">
      <Helmet>
        <meta property="og:title" content="Перфект Консулт - Недвижими Имоти" />
        <meta property="og:image" content="//test.perfectconsult.bg/og-image_600x315.jpg" />
        <meta property="twitter:title" content="Перфект Консулт - Недвижими Имоти" />
        <meta property="twitter:image" content="//test.perfectconsult.bg/og-image_600x315.jpg" />
        <title>Перфект Консулт - Недвижими Имоти</title>
      </Helmet>
      <Router basename="/">
        <OnReroute />
        <Header />

        <Switch>
          <Route path="/" exact render={() => <Home />} />
          <Route path="/properties" exact render={(props) => <Properties {...props} />} />
          <Route path="/properties/:id" render={(props) => <Property {...props} id={props.match.params.id * 1} />} />
          <Route path="/add-property" render={(props) => <AddProperty {...props} />} />
          <Route path="/services" exact render={(props) => <Services {...props} />} />
          <Route path="/services/additional" exact render={(props) => <AdditionalServices {...props} />} />
          <Route path="/services/for-owners" exact render={(props) => <ForOwners {...props} />} />
          <Route path="/services/we-buy-directly" exact render={(props) => <WeBuyDirectly {...props} />} />
          <Route path="/services/propety-control" exact render={(props) => <PropertyControl {...props} />} />
          <Route path="/services/for-investors" exact render={(props) => <ForInvestors {...props} />} />
          <Route path="/reviews" exact render={(props) => <Reviews {...props} />} />
          <Route path="/careers" exact render={(props) => <Careers {...props} />} />
          <Route path="/why-perfect-consult" exact render={(props) => <WhyPerfectConsult {...props} />} />
          <Route path="/blog" exact render={(props) => <Blog {...props} />} />
          <Route path="/blog/:id" exact render={(props) => <BlogArticle {...props} id={props.match.params.id * 1} />} />
          <Route path="/contacts" exact render={() => <Contacts />} />
          <Route path="/terms-and-conditions" exact render={() => <TermsAndConditions />} />
          <Route path="/privacy-policy" exact render={() => <PrivacyPolicy />} />
          <Route path="/sitemap" exact render={() => <Sitemap />} />
          <Route path="/support" exact render={() => <Support />} />
          <Route render={(props) => <NotFound {...props} />} />
        </Switch>

        <Footer />
      </Router>

      <CustomModal isOpen={modalOpen} onClose={handleCloseModal}>
        <h3>Запишете се за нашия бюлетин</h3>
        <p className="mb-2">Запишете се за нашия бюлетин ако желаете да получавате известия за обяви, новини и топ оферти от нас на посоченият от вас имейл адрес.</p>
        <Newsletter onNewsletterSuccess={handleCloseModal} modal={true} />
      </CustomModal>

      <ToastContainer closeButton={ToastCloseButton} />
    </div>
  );
}

export default App;
