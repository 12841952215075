import React from 'react';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ToastCloseButton = ({ closeToast, type }) => {
    return (
        <button className={`Toastify__close-button${' Toastify__close-button--' + type}`} type="button" aria-label="close" onClick={closeToast}>
            <FontAwesomeIcon icon={faTimes} />
        </button>
    )
}

export default ToastCloseButton