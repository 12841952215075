import React from 'react';

// Styles
import '../scss/property-price.scss'

const PropertyPrice = ({ currentPrice = 0, oldPrice = 0 }) => {
    return (
        <div className="property-price">
            <div className={`property-price__current${oldPrice ? ' discount' : ''}`}>€{currentPrice}</div>
            {oldPrice ? <div className="property-price__old">€{oldPrice}</div> : null}
        </div>
    );
}

export default PropertyPrice;
