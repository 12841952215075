import React, { useState, useEffect } from 'react';

// Components
import InfoBubble from './InfoBubble';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendarAlt,
    faEuroSign,
    faPercent,
} from '@fortawesome/free-solid-svg-icons';

// Styles
import '../scss/mortgage-calculator.scss';

const MortgageCalculator = ({ propertyPrice }) => {
    const [price, setPrice] = useState(propertyPrice);
    const [depositPercentage, setDepositPercentage] = useState(0.20);
    const [deposit, setDeposit] = useState(Math.round(propertyPrice * 0.20));
    const [gpr, setGpr] = useState(0.026);
    const [years, setYears] = useState(30);
    const [monthly, setMonthly] = useState(0);

    /**
    * Calculates the Monthly Payment amount.
    * @param priceVal - The price of the property.
    * @param depositVal - The deposit amount.
    * @param yearsVal - The loan period in years.
    * @param gprVal - The yearly interest rate precent.
    */
    const calculateMonthlyPayment = (priceVal, depositVal, yearsVal, gprVal) => {
        const monthlyInterestRate = (gprVal / 12).toFixed(6) * 1;
        const numberOfPayments = (yearsVal * 12);
        const principalLoanAmount = (priceVal - depositVal);
        const i = (Math.pow((1 + monthlyInterestRate), numberOfPayments)).toFixed(6) * 1;
        const monthlyPaymentAmount = Math.round(principalLoanAmount * ((monthlyInterestRate * i) / (i - 1)));

        setMonthly(isNaN(monthlyPaymentAmount) || !isFinite(monthlyPaymentAmount) ? 0 : monthlyPaymentAmount);
    }

    const handlePriceChange = e => {
        if ((e.target.value * 1) <= 10000000) {
            setPrice((e.target.value * 1));
            setDeposit(Math.round((e.target.value * 1) * depositPercentage));
        }
    };

    const handleDepositPercentageChange = e => {
        if ((e.target.value * 1) <= 100) {
            setDepositPercentage(((e.target.value * 1) / 100).toFixed(6) * 1);
            setDeposit(Math.round(price * ((e.target.value * 1) / 100)));
        }
    };
    const handleDepositChange = e => {
        if ((e.target.value * 1) <= price) {
            setDeposit(e.target.value * 1);
            setDepositPercentage(((e.target.value * 1) / price).toFixed(6) * 1);
        }
    };

    const handleGprChange = e => {
        if ((e.target.value * 1) <= 100) {
            setGpr((e.target.value * 1) / 100);
        }
    }

    const handleYearsChange = e => {
        if ((e.target.value * 1) <= 50) {
            setYears(e.target.value * 1);
        }
    }

    useEffect(() => {
        calculateMonthlyPayment(price, deposit, years, gpr);
    }, [price, deposit, years, gpr])

    return (
        <section className="mortgage-calculator">
            <h3 className="mortgage-calculator__heading">Ипотечен Калкулатор</h3>
            <div className="mortgage-calculator__wrapper">
                <div className="mortgage-calculator__field mortgage-calculator__price">
                    <label className="mortgage-calculator__label">
                        <div className="mortgage-calculator__label-text">Цена</div>
                        <div className="mortgage-calculator__label-icon"><FontAwesomeIcon icon={faEuroSign} /></div>
                        <input className="mortgage-calculator__input" type="number" name="price" min="1" max="10000000" value={(price).toString().replace(/^0+[0-9]/, '')} onChange={handlePriceChange} />
                    </label>
                </div>
                <div className="mortgage-calculator__field mortgage-calculator__deposit">
                    <label className="mortgage-calculator__label">
                        <div className="mortgage-calculator__label-text">Депозит / <label className="mortgage-calculator__label-small"><span className="mortgage-calculator__label-small-icon"><FontAwesomeIcon icon={faPercent} /></span><input className="mortgage-calculator__input-small" type="number" name="deposit-percentage" min="0" max="100" step="0.1" value={((depositPercentage * 100).toFixed(1) * 1).toString().replace(/^0+[0-9]/, '')} onChange={handleDepositPercentageChange} /></label></div>
                        <div className="mortgage-calculator__label-icon"><FontAwesomeIcon icon={faEuroSign} /></div>
                        <input className="mortgage-calculator__input" type="number" name="deposit" min="0" max="10000000" value={(deposit).toString().replace(/^0+[0-9]/, '')} onChange={handleDepositChange} />
                    </label>
                </div>
                <div className="mortgage-calculator__field mortgage-calculator__years">
                    <label className="mortgage-calculator__label">
                        <div className="mortgage-calculator__label-text">Срок / Години</div>
                        <div className="mortgage-calculator__label-icon"><FontAwesomeIcon icon={faCalendarAlt} /></div>
                        <input className="mortgage-calculator__input" type="number" name="years" min="1" max="100" value={(years).toString().replace(/^0+[0-9]/, '')} onChange={handleYearsChange} />
                    </label>
                </div>
                <div className="mortgage-calculator__field mortgage-calculator__gpr">
                    <label className="mortgage-calculator__label">
                        <div className="mortgage-calculator__label-text">ГПР / <InfoBubble><p><strong>Закона за Ипотечните Кредити на Потербители</strong></p><p><strong>Чл. 29. (1)</strong> Годишният процент на разходите (ГПР) по кредита изразява общите разходи по кредита за потребителя, настоящи или бъдещи, изразени като годишен процент, изчислен на годишна база от общия размер на предоставения кредит.</p></InfoBubble></div>
                        <div className="mortgage-calculator__label-icon"><FontAwesomeIcon icon={faPercent} /></div>
                        <input className="mortgage-calculator__input" type="number" name="gpr" min="0.1" max="100" step="0.1" value={((gpr * 100).toFixed(1) * 1).toString().replace(/^0+[0-9]/, '')} onChange={handleGprChange} />
                    </label>
                </div>
            </div>
            <div className="mortgage-calculator__monthly mt-2">
                <span className="payment">€{monthly}</span>
                <span>/ на месец</span>
            </div>
        </section>
    );
}

export default MortgageCalculator;
