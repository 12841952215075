import React, { useContext } from 'react';

// Context
import { LastSeenContext } from '../../data/LastSeenContext';
import { PropertyContext } from '../../data/PropertyContext';

// Components
import SectionHeading from '../SectionHeading';
import CardSlider from '../CardSlider';
import PropertyCard from '../PropertyCard';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Styles
import '../../scss/last-seen-properties.scss';

const LastSeenProperties = ({ dontShowId }) => {
    const { data } = useContext(LastSeenContext);
    const { lastSeen } = useContext(PropertyContext);

    const slickSettings = {
        draggable: false,
        fade: false,
        lazyLoad: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 6000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1619,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1239,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };

    const filteredLastSeen = data.filter(property => property.id !== dontShowId);

    if (filteredLastSeen.length) {
        return (
            <section className={`last-seen-properties${filteredLastSeen.length <= 4 ? ' pb-4' : ''}`}>
                <Container>
                    <Row>
                        <Col>
                            <SectionHeading className="text-center" heading="Последно Разгледани" />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CardSlider settings={slickSettings}>
                                {filteredLastSeen.map(property => {
                                    return <PropertyCard
                                        key={property.id}
                                        id={property.id}
                                        type={property.type}
                                        price={property.price}
                                        oldPrice={property.oldPrice}
                                        image={property.image}
                                        title={property.title}
                                        description={property.description}
                                        designations={property.designations}
                                        newOffer={property.newOffer}
                                        topOffer={property.topOffer}
                                        isSeen={lastSeen.includes(property.id)}
                                    />
                                })}
                            </CardSlider>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    } else {
        return null
    }

}

export default LastSeenProperties;
