// React Components
import React from 'react';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';

// Styles
import '../scss/social.scss'

const Social = ({ className }) => {
    return (
        <div className={`social${className ? ' ' + className : ''}`}>
            <a className="social__link" href={process.env.REACT_APP_SOCIAL_FACEBOOK} title="Facebook" target="_blank" rel="nofollow noopener noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>
            <a className="social__link" href={process.env.REACT_APP_SOCIAL_INSTAGRAM} title="Instagram" target="_blank" rel="nofollow noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
            <a className="social__link" href={process.env.REACT_APP_SOCIAL_LINKEDIN} title="LinkedIn" target="_blank" rel="nofollow noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
            <a className="social__link" href={process.env.REACT_APP_SOCIAL_YOUTUBE} title="YouTube" target="_blank" rel="nofollow noopener noreferrer"><FontAwesomeIcon icon={faYoutube} /></a>
        </div>
    );
}

export default Social;
