import React from 'react';

// Components
import InquiryForm from '../InquiryForm';
import Social from '../Social';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Assets
import ContactFormMap from '../../assets/images/map.png';

// Styles
import '../../scss/contact-form.scss';

const ContactForm = () => {
    return (
        <section className="contact-form">
            <Container>
                <Row>
                    <Col>
                        <div className="contact-form__wrapper">
                            <div className="contact-form__map">
                                <a className="contact-form__map-link" href={process.env.REACT_APP_GMAPS_HREF} target="_blank" rel="nofollow noopener noreferrer">
                                    <figure className="contact-form__map-figure">
                                        <img className="contact-form__map-img" src={ContactFormMap} alt="Visit Us" />
                                    </figure>
                                </a>
                            </div>
                            <div className="contact-form__form">
                                <InquiryForm page="Страница Контакти" title="Пишете ни или ни посетете" />
                                <div className="contact-form__info">
                                    <ul className="contact-form__info-list ul-clean">
                                        <li><span>Телефон: </span><a className="link" href={`tel:${process.env.REACT_APP_PHONE_HREF}`} title="Обадете ни се">{process.env.REACT_APP_PHONE}</a></li>
                                        <li><span>Имейл: </span><a className="link" href={`mailto:${process.env.REACT_APP_EMAIL}`} title="Пишете ни">{process.env.REACT_APP_EMAIL}</a></li>
                                        <li><span>Адрес: </span><a className="link" href={process.env.REACT_APP_GMAPS_HREF} title="Посетете ни" target="_blank" rel="nofollow noopener noreferrer">{process.env.REACT_APP_ADDRESS}</a></li>
                                    </ul>
                                    <div className="contact-form__info-social">
                                        <div>Последвайте ни:</div>
                                        <Social />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default ContactForm;
