// React Components
import React from 'react';
//eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

// Utility
import { formatPublished } from '../utility/formatPublished';

// Styles
import '../scss/article-card.scss';

// Assets
import noImage from '../assets/images/noimage.jpg';

const ArticleCard = ({ id, title = '', description = '', image, published, tags = [] }) => (
    <article className="article-card">
        <Link className="article-card__link" to={`/blog/${id}`} title={title}>
            <figure className="article-card__figure">
                <img className="article-card__image" src={image ? process.env.REACT_APP_API_BLOG_IMAGES + image : noImage} alt={title} loading="lazy" />
            </figure>
            <div className="article-card__info">
                <div className="article-card__title">{title}</div>
                <div className="article-card__description">{description}</div>
                {published || tags.length ? <div className="article-card__details">
                    {published ? <div className="article-card__date">
                        <div className="article-card__label">Дата:</div>
                        <time className="article-card__value" dateTime={published}>{formatPublished(published)}</time>
                    </div> : null}
                    {tags.length ? <div className="article-card__category">
                        <div className="article-card__label">Тагове:</div>
                        <div className="article-card__value">{tags.join(', ')}</div>
                    </div> : null}
                </div> : null}
            </div>
        </Link>
    </article>
);

export default ArticleCard;
