import React, { useReducer, createContext, useEffect } from 'react';

// Utility
import { updateSearchFromBlogState } from '../utility/manageSearchParamsBlog';

// Reducers
import BlogReducer from '../data/reducers/BlogReducer';

export const BlogContext = createContext();

export const BlogProvider = ({ children, initialState }) => {
    const [state, dispatch] = useReducer(BlogReducer, initialState);

    const updateBlogState = (type, payload) => { dispatch({ type, payload }) }

    useEffect(() => {
        if(state.lastSeen.length) localStorage.setItem('lastSeenArticles', JSON.stringify(state.lastSeen))
    }, [state.lastSeen])

    useEffect(() => {
        updateSearchFromBlogState(state, updateBlogState);
        //eslint-disable-next-line
    }, [state.tags, state.page, state.perPage, state.sort, state.order])

    return (
        <BlogContext.Provider value={{ ...state, updateBlogState }}>
            {children}
        </BlogContext.Provider>
    )
};