import React from 'react';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styles
import '../scss/promo-service-pack.scss';

const PromoServicePack = ({ className, icon, title = '', subTitle = '', description = '', items = [], disclaimer = '', packPrice = '', btnText = '', onBtnClick = () => { } }) => {
    return (
        <div className={`promo-service-pack${className ? ' ' + className : ''}`}>
            {icon ? <div className="promo-service-pack__icon"><FontAwesomeIcon icon={icon} /></div> : null}
            <h3 className={`promo-service-pack__title${subTitle ? ' mb-0' : ' mb-2'}`}>{title}</h3>
            {subTitle ? <div className="promo-service-pack__sub-title mb-2">{subTitle}</div> : null}
            {items.length ? <ul className="promo-service-pack__items ul-clean">
                {items.map(item => <li key={item}>{'+ ' + item}</li>)}
            </ul> : null}
            <button className={`btn btn--red promo-service-pack__button${packPrice ? ' btn--compact' : ''}`} type="button" onClick={() => onBtnClick(title, items, packPrice)}>{packPrice ? (<span>{packPrice + ' лв.'}</span>) : ''}{btnText}</button>
            {disclaimer ? <div className="promo-service-pack__disclaimer">{disclaimer}</div> : null}
        </div>
    );
}

export default PromoServicePack;
