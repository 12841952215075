import React, { useContext, useEffect, memo } from 'react';
//eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';

// Utility
import { descriptionShortener } from '../utility/descriptionShortener';

// Actions
import {
    UPDATE_POPULAR_DATA
} from '../data/actions/BlogActions';

// Hooks
import useAxiosGet from '../hooks/useAxiosGet';

// Context
import { BlogContext } from '../data/BlogContext';

// Components
import Loading from '../components/Loading';

// Styles
import '../scss/blog-popular.scss';

const BlogPopular = () => {
    const { popularData, updateBlogState } = useContext(BlogContext);
    const { isLoading, response } = useAxiosGet(`${process.env.REACT_APP_API_BLOG_ARTICLES}?sort=views&perPage=5`);

    useEffect(() => {
        if (response) {
            window.scrollTo(0, 0);
            updateBlogState(UPDATE_POPULAR_DATA, {
                ...response.data, data: response.data.data.map(article => {
                    return { ...article, content: descriptionShortener(sanitizeHtml(article.content, {
                        exclusiveFilter: (frame) => {
                            return frame.tag === 'h2' || frame.tag === 'h3' || frame.tag === 'h4' || frame.tag === 'h5' || frame.tag === 'h6';
                        }
                    }).replace(/(<([^>]+)>)/gi, '').replace(/(\r\n|\n|\r)/gm, '').replace('  ', ' ').replace('   ', ' '), 80) }
                })
            });
        }
        //eslint-disable-next-line
    }, [response])

    return (
        <section className="blog-popular">
            <h2 className="blog-popular__heading">Популярни Статии</h2>
            {isLoading ? <Loading /> : popularData.map((article, index) => {
                return (
                    <article className="blog-popular__article" key={article.id}>
                        <Link className="blog-popular__link" to={`/blog/${article.id}`} title={article.title}>
                            <div className="blog-popular__number">{index + 1}</div>
                            <div className="blog-popular__details">
                                <h3 className="blog-popular__title">{article.title}</h3>
                                <p className="blog-popular__content">{article.content}</p>
                            </div>
                        </Link>
                    </article>
                )
            })}
        </section>
    );
}

export default memo(BlogPopular);
