import {
    UPDATE_PAGE,
    UPDATE_TAGS,
    UPDATE_PER_PAGE,
    UPDATE_SORT,
    UPDATE_ORDER,
    UPDATE_SEARCH
} from '../data/actions/BlogActions';

// Update Search Query from State > Blog
export const updateSearchFromBlogState = (state, dispatch) => {
    // Get Array Search Params from State
    const blogTags = `${state.tags.length ? '&' + state.tags.map(val => `tags[]=${val}`).join('&') : ''}`;

    // Combine Array Search Params into Query using URLSearchParams
    const newSearchParams = new URLSearchParams(blogTags);

    // Collect and Add Search Options to Query String
    if (state.page !== 1) newSearchParams.set("page", state.page * 1);
    if (state.perPage.id !== 20) newSearchParams.set("perPage", state.perPage.id * 1);
    if (state.sort) newSearchParams.set("sort", state.sort.id);
    if (state.order) newSearchParams.set("order", state.order.id);

    // Update Search State
    dispatch(UPDATE_SEARCH, `?${decodeURIComponent(newSearchParams.toString())}`);
}

// Update State from Search Query
export const updateBlogStateFromSearch = (search = '', dispatch) => {
    // Create and store search params object using URLSearchParams
    const searchParams = new URLSearchParams(search);

    // Update PropertiesContext state from searchParams
    dispatch(UPDATE_PAGE, searchParams.has('page') ? searchParams.get('page') : 1);
    dispatch(UPDATE_PER_PAGE, searchParams.has('perPage') ? searchParams.get('perPage') : 20);
    dispatch(UPDATE_SORT, searchParams.has('sort') ? searchParams.get('sort') : 'published_at');
    dispatch(UPDATE_ORDER, searchParams.has('order') ? searchParams.get('order') : 'desc');
    dispatch(UPDATE_TAGS, searchParams.has('tags[]') ? searchParams.getAll('tags[]') : []);
}