import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import InnerPageTop from '../components/sections/InnerPageTop';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const PrivacyPolicy = () => {
    return (
        <main className="privacy-policy">
            <Helmet>
                <title>Декларация за Поверителност - Перфект Консулт</title>
            </Helmet>
            <InnerPageTop pageTitle="Декларация за Поверителност" pageDescription="Декларация за Поверителност на Перфект Консулт 2009 ЕООД." />
            <section className="my-4">
                <Container>
                    <Row>
                        <Col>
                            <h2 className="mb-2">А. Въведение</h2>
                            <p>Поверителността на уебсайта ни е много важна за нас и сме ангажирани да я пазим. Тази полица обяснява какво ще направим с личните ви данни.</p>
                            <h2 className="mb-2 pt-2">Б. Заслуги</h2>
                            <p>Този документ бе създаден, използвайки шаблон от SEQ Legal (<a className="link" href="//seqlegal.com" title="SEQ Legal" target="_blank" rel="nofollow noopener noreferrer">seqlegal.com</a>) И модифициран от Website Planet (<a className="link" href="//www.websiteplanet.com" title="Website Planet" target="_blank" rel="nofollow noopener noreferrer">www.websiteplanet.com</a>).</p>
                            <h2 className="mb-2 pt-2">В. Събиране на лични данни</h2>
                            <p>Следните видове лична информация може да бъдат събирани, съхранявани и използвани:</p>
                            <ul>
                                <li><p>Информация като имейл адреса ви, който сте въвели, при абонамент към нашия бюлетин.</p></li>
                                <li><p>Информация, която въвеждате, докато използвате услугите на нашия уебсайт.</p></li>
                                <li><p>Информация, съдържаща се във всяка комуникация с нас, изпратена ни чрез имейл или чрез уебсайта ни, включително съдържанието на комуникацията и метаданните.</p></li>
                                <li><p>Всяка друга лична информация, която ни изпращате.</p></li>
                            </ul>
                            <p>Преди да ни разкриете личните данни на друг човек, трябва да получите съгласието му за това разкритие и за обработката на тази лична информация в съответствие с тази полица</p>
                            <h2 className="mb-2 pt-2">Г. Използване на личната ви информация</h2>

                            <p>Лична информация, подадена до нас чрез уебсайта ни, ще бъде използвана за целите, уточнени в тази полица или в съответните страници на уебсайта. Може да използваме личната ви информация за:</p>
                            <ul>
                                <li><p>Администриране на нашите уебсайт и бизнес.</p></li>
                                <li><p>Персонализиране на уебсайта ни за вас.</p></li>
                                <li><p>Да ви изпращаме немаркетингови рекламни комуникации.</p></li>
                                <li><p>Да ви изпращаме имейл известия, които конкретно сте заявили.</p></li>
                                <li><p>Да ви изпращаме нашия имейл бюлетин, ако сте го заявили (можете да ни информирате по всяко време, ако вече не желаете да получавате бюлетин).</p></li>
                                <li><p>Да ви изпращаме маркетингови комуникации, свързани с нашия бизнес или бизнесите на внимателно подбрани трети лица, които смятаме, че биха представлявали интерес за вас, чрез публикация или, ако конкретно сте се съгласили с това, чрез имейл или подобен канал (можете да ни информирате по всяко време, ако вече не желаете да получавате маркетингови комуникации).</p></li>
                                <li><p>Справяне със запитвания и оплаквания, направени от или за вас, които са свързани с уебсайта ни.</p></li>
                                <li><p>Предпазване на уебсайта ни и предотвратяване на измама.</p></li>
                                <li><p>Потвърждение на съгласие с нашите правила и условия, ръководени от употребата на уебсайта ни (включително показването на лични съобщения, изпратени чрез услугата на сайта ни за лични съобщения).</p></li>
                            </ul>
                            <p>Ако предоставите лична информация за публикуване в уебсайта ни, ние ще публикуваме или ще използваме тази информация в съответствие с разрешението, което ни давате.</p>
                            <p>Ние няма да предоставим личната ви информация на трети лица за техния или нечий друг директен маркетинг без вашето изрично съгласие.</p>
                            <h2 className="mb-2 pt-2">Д. Оповестяване на лични данни</h2>
                            <p>Може да оповестим личните ви данни на всеки от нашите служители, застрахователи, професионални съветници, агенти, доставчици или подизпълнители, както е необходимо за целите, изложени в тази полица.</p>
                            <p>Може да оповестим личните ви данни на всеки член от групата на нашите компании (това означава нашите дъщерни дружества, нашата крайна холдингова компания и всичките ѝ дъщерни дружества), както е необходимо за целите, изложени в тази полица.</p>
                            <p>Може да оповестим личната ви информация:</p>
                            <ul>
                                <li><p>До степента, в която сме длъжни да го направим по закон.</p></li>
                                <li><p>Във връзка с всеки процес или вероятни съдебни производства.</p></li>
                                <li><p>За да установим, упражним или защитим нашите права (включително осигуряване на информация на други лица за целите на предотвратяване на измама и намаляване на кредитен риск).</p></li>
                                <li><p>На купувача (или вероятния купувач) за всеки бизнес или актив, който ние продаваме (или възнамеряваме да продадем).</p></li>
                                <li><p>На всеки човек, който вярваме, че може да се яви пред съд или друг подобен орган, за оповестяване на тази лична информация, където според нашето разумно мнение подобен съд или орган може да нареди оповестяване на тази лична информация.</p></li>
                            </ul>
                            <p>Освен в случаите, посочени в тази полица, ние няма да предоставим личната ви информация на трети лица.</p>
                            <h2 className="mb-2 pt-2">Е. Международни трансфери на данни</h2>
                            <p>Информацията, която сме събрали, може да бъде съхранявана, обработена и прехвърляна между всяка една от държавите, с които работим, за да можем да използваме съответната информация в съответствие с тази полица.</p>
                            <p>Информацията, която сме събрали, може да бъде прехвърлена на следните държави, които нямат права за защита на данните, подобни на тези, които са в сила в Европейската Икономическа Зона: Съединените Американски Щати, Русия, Япония, Китай и Индия.</p>
                            <p>Личната информация, която публикувате или предоставяте в публикации в нашия уебсайт, може да бъде достъпна чрез интернет по целия свят. Ние не можем да предотвратим употребата или злоупотребата на подобна информация от други.</p>
                            <p>Вие изрично се съгласявате с трансферите на лични данни, описани в този раздел Е.</p>
                            <h2 className="mb-2 pt-2">Ж. Запазване на лична информация</h2>
                            <p>Този раздел Ж излага нашите политики и процедури за запаметяване на данни, които са разработени да подпомагат да гарантираме, че се съобразяваме с правните си задължения за запаметяване и изтриване на лична информация.</p>
                            <p>Личната информация, която обработваме за каквато и да е цел или цели, няма да бъде съхранявана за по-дълъг период от нужния за съответната цел или цели.</p>
                            <p>Без предразсъдъци към част Ж-2 обикновено изтриваме лични данни, спадащи към категориите, изложени отдолу, във времето или датата, изложени отдолу:</p>
                            <ul>
                                <li><p>Лични данни ще бъдат изтрити след завършване на сделката или затваряне на публикация.</p></li>
                                <li><p>Не повече от година след събирането.</p></li>
                            </ul>
                            <p>Въпреки другите разпоредби в Секция Ж ще запаметим документи (включително електронни документи), съдържащи лични данни:</p>
                            <ul>
                                <li><p>До степента, в която законът изисква от нас.</p></li>
                                <li><p>Ако вярваме, че документите може да са свързани с вероятни съдебни производства.</p></li>
                                <li><p>За да установим, упражним или защитим нашите права (включително осигуряване на информация на други лица с цел предотвратяване на измама или намаляване на кредитен риск).</p></li>
                            </ul>
                            <h2 className="mb-2 pt-2">З. Защита на личната ви информация</h2>
                            <p>Ще предприемем разумни технически и организационни мерки за предотвратяване на загуба, злоупотреба или промяна на личната ви информация.</p>
                            <p>Ще съхраняваме цялата лична информация, която осигурявате на нашите сигурни (защитени от парола и защитна стена) сървъри.</p>
                            <p>Вие потвърждавате, че предаването на информация по интернет само по себе си е несигурно, и ние не можем да гарантираме сигурността на данни, изпратени по интернет.</p>
                            <h2 className="mb-2 pt-2">И. Изменения</h2>
                            <p>От време на време може да актуализираме тази полица, като публикуваме нова версия в нашия уебсайт. Трябва да проверявате тази страница, за да се уверите, че разбирате всички промени на полицата. Може да ви известим за промените в тази полица, като ви изпратим имейл или чрез личната система за съобщения на уебсайта ни.</p>
                            <h2 className="mb-2 pt-2">Й. Вашите права</h2>
                            <p>Можете да ни инструктирате да ви осигурим всяка лична информация, която сме запаметили за вас – осигуряването на подобна информация ще бъде предмет на следните:</p>
                            <ul>
                                <li><p>Снабдяването с подходящи доказателства за вашата самоличност (за тази цел обикновено приемаме копие на паспорта ви, заверено от нотариус, плюс оригинално копие на битова сметка, показваща настоящия ви адрес).</p></li>
                            </ul>
                            <p>Можем да удържим лична информация, която сте поискали, до степента, позволена от закона.</p>
                            <p>По всяко време можете да ни инструктирате да не обработваме личната ви информация за маркетингови цели.</p>
                            <p>На практика или изрично ще се съгласите предварително за това да използваме личната ви информация за маркетингови цели, или ние ще ви осигурим възможност да се откажете от това да използваме личната ви информация за маркетингови цели.</p>
                            <h2 className="mb-2 pt-2">К. Уебсайтове на трети страни</h2>
                            <p>Нашият уебсайт включва кибервръзки към уебсайтове на трети страни и подробности за тях. Ние нямаме контрол и не сме отговорни за политиките за поверителност и практиките на трети страни.</p>
                            <h2 className="mb-2 pt-2">Л. Актуализиране на информация</h2>
                            <p>Молим да ни уведомите, ако личната ви информация, която съхраняваме, има нужда да бъде поправена или актуализирана.</p>
                            <h2 className="mb-2 pt-2">М. Бисквитки</h2>
                            Нашият уебсайт не използва бисквитки.
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
}

export default PrivacyPolicy;
