import React, { useReducer, createContext } from 'react';

// Reducers
import FeaturedPropertiesReducer from '../data/reducers/FeaturedPropertiesReducer';

export const FeaturedPropertiesContext = createContext();

export const FeaturedPropertiesProvider = ({ children, initialState }) => {
    const [state, dispatch] = useReducer(FeaturedPropertiesReducer, initialState);

    const updateFeaturedPropertiesState = (type, payload) => { dispatch({ type, payload }) }

    return (
        <FeaturedPropertiesContext.Provider value={{ ...state, updateFeaturedPropertiesState }}>
            {children}
        </FeaturedPropertiesContext.Provider>
    )
};