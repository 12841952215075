// React Components
import React from 'react';
//eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

// Styles
import '../scss/arrow-link.scss';

const Button = ({ className, children, to }) => {
    return (
        <Link className={`arrow-link${className ? ' ' + className : ''}`} to={to}>{children}<FontAwesomeIcon icon={faAngleRight}/></Link>
    )
}

export default Button
