import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';

// Styles
import '../scss/image-lightbox.scss';

const ImageLightbox = ({ isOpen = false, images = [], index = 0, onClose = () => { } }) => {
    const [imageIndex, setImageIndex] = useState(index);

    useEffect(() => {
        setImageIndex(index);
    }, [index])

    return isOpen ? <Lightbox
        mainSrc={images[imageIndex]}
        nextSrc={images.length > 1 ? images[(imageIndex + 1) % images.length] : null}
        prevSrc={images.length > 1 ? images[(imageIndex + images.length - 1) % images.length] : null}
        onCloseRequest={() => onClose()}
        onMovePrevRequest={() => setImageIndex((imageIndex + images.length - 1) % images.length)}
        onMoveNextRequest={() => setImageIndex((imageIndex + 1) % images.length)}
    /> : null;
};

export default ImageLightbox;
