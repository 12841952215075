// React Components
import React from 'react';
//eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

// Utility
import { descriptionShortener } from '../utility/descriptionShortener';

// Components
import PropertyExtras from './PropertyExtras';
import PropertyPrice from './PropertyPrice';

// Icons
import IconFeatures from './IconFeatures';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

// Assets
import noImage from '../assets/images/noimage.jpg';

// Styles
import '../scss/property-card.scss';

const PropertyCard = ({
    id,
    type,
    price,
    oldPrice,
    image,
    title = '',
    description = '',
    sizeTotal = 0,
    buildingType,
    designations = [],
    newOffer = false,
    topOffer = false,
    isSeen = false
}) => (
    <article className="property-card">
        <Link className="property-card__link" to={`/properties/${id}`} title={title}>
            <figure className="property-card__figure">
                <PropertyExtras oldPrice={oldPrice} newOffer={newOffer} topOffer={topOffer} />
                <img className="property-card__image" src={image ? image : noImage} alt={title} loading="lazy" />
            </figure>
            <div className="property-card__info">
                <div className="property-card__title">{title}</div>
                <div className="property-card__description">{descriptionShortener(description, 120)}</div>
                {sizeTotal || buildingType ? <div className="property-card__features">
                    {sizeTotal ? <IconFeatures type="area" value={sizeTotal} /> : null}
                    {buildingType ? <IconFeatures type="material" value={buildingType} /> : null}
                </div> : null}
                <div className="property-card__details">
                    <div className="property-card__details-top">
                        <div className="property-card__type">{type}</div>
                        <div className="property-card__designations">{designations.join(', ')}</div>
                    </div>
                    <div className="property-card__details-bottom">
                        <PropertyPrice currentPrice={price} oldPrice={oldPrice} />
                        <div className="property-card__icon-wrapper">
                            <div className={`property-card__icon-seen${isSeen ? ' seen' : ''}`}><FontAwesomeIcon icon={faEye} /></div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    </article>
);

export default PropertyCard;
