// React Components
import React from 'react';
//eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

// Styles
import '../scss/button.scss';

const Button = ({ className, children, to }) => {
    return (
        <Link className={`btn${className ? ' ' + className : ''}`} to={to}>{children}</Link>
    )
}

export default Button
