import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

// Components
import LastSeenProperties from '../components/sections/LastSeenProperties';
// import BeforeAfterSlider from "../components/sections/BeforeAfterSlider";
import InnerPageTop from '../components/sections/InnerPageTop';
import PromoServicePack from '../components/PromoServicePack';
import CustomModal from "../components/CustomModal";
import InquiryForm from "../components/InquiryForm";

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Assets
import ColoredLogoBig from '../assets/images/colored-logo-big.png';
// import BeforeTestImg from '../assets/images/before-test.jpg';
// import AfterTestImg from '../assets/images/after-test.jpg';
// import BeforeTestImg2 from '../assets/images/before-test-2.jpg';
// import AfterTestImg2 from '../assets/images/after-test-2.jpg';

// Icons
import {
    faQuidditch,
    faTools,
} from '@fortawesome/free-solid-svg-icons';

// Styles
import '../scss/services.scss';

const AdditionalServices = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [inquiryContent, setInquiryContent] = useState({
        title: '',
        items: [],
        packPrice: ''
    })

    const handleOpenModal = (title, items, packPrice) => {
        setInquiryContent({
            ...inquiryContent,
            title,
            items,
            packPrice
        })
        setModalOpen(true);
    }
    const handleCloseModal = () => {
        setInquiryContent({
            title: '',
            items: [],
            packPrice: ''
        })
        setModalOpen(false);
    }

    return (
        <main className="services">
            <Helmet>
                <title>Допълнителни Услуги - Перфект Консулт</title>
            </Helmet>
            <InnerPageTop pageTitle="Допълнителни Услуги" pageDescription="Разгледайте комплексните решения, създадени за ваше удобство." />
            <Container className="pt-4">
                <Row className="mb-4">
                    <Col className="d-flex flex-column flex-xxl-row align-items-center justify-content-center">
                        <img className="mb-4 mb-xxl-0" src={ColoredLogoBig} alt="Colored Logo" />
                        <p className="ml-xxl-4">Нека направим професионални нещата, които имат значение за вас! Ще превърнем вашата визия в реален дом.</p>
                    </Col>
                </Row>
                <Row className="mb-4">
                    {promoPacks.map(pack => {
                        return <Col className="col-12 col-lg-6 mb-4" key={pack.id}>
                            <PromoServicePack icon={pack.icon} title={pack.title} subTitle={pack.subTitle} btnText={pack.btnText} onBtnClick={handleOpenModal} items={pack.items} />
                        </Col>
                    })}
                </Row>
            </Container>
            {/* <BeforeAfterSlider beforeAfterData={beforeAfterData} /> */}
            <LastSeenProperties />

            <CustomModal isOpen={modalOpen} onClose={handleCloseModal}>
                <h3>Поръчай Допълнителна Услуга</h3>
                <h4 className="color-cyan">{inquiryContent.title}</h4>
                <ul style={{ fontSize: '0.875rem' }} className="ul-clean color-green font-weight-bold mb-2">
                    {inquiryContent.items.map(item => <li>{'+ ' + item}</li>)}
                </ul>
                <InquiryForm page="Страница Допълнителни Услуги" placeholder={`Здравейте, желая да поръчам допълнителна услуга "${inquiryContent.title}".`} onSuccess={handleCloseModal} onError={handleCloseModal} />
            </CustomModal>
        </main>
    );
}

export default AdditionalServices;

const promoPacks = [
    {
        id: 1,
        icon: faQuidditch,
        title: 'Подготовка за ремонт',
        subTitle: '(почистване)',
        btnText: 'Поръчай',
        items: [
            'Пести нерви',
            'Пести време',
            'Къртене',
            'Извозване',
            'Подготвяне за ремонт',
            'Почистване',
        ]
    },
    {
        id: 2,
        icon: faTools,
        title: 'Ремонт',
        subTitle: '(на нов и стар имот)',
        btnText: 'Поръчай',
        items: [
            'Без досадни срещи с майстори',
            'Идеен проект',
            'Помощ при избор на материали и мебели',
            'Подмяна на ВИК и Ел инсталации',
            'Вие определяте бюджета',
            'Реализация',
        ]
    },
]

// const beforeAfterData = [
//     {
//         id: 1,
//         beforeImg: BeforeTestImg,
//         afterImg: AfterTestImg,
//     },
//     {
//         id: 2,
//         beforeImg: BeforeTestImg2,
//         afterImg: AfterTestImg2,
//         beforeText: '1985',
//         afterText: '1986'
//     },
// ]