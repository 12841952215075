// React Components
import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import Button from '../components/Button';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Styles
import '../scss/not-found.scss'

// Assets
import OperaBlurredBG from "../assets/images/opera-blurred-bg.jpg";

const NotFound = ({ history }) => {
    return (
        <main className="not-found">
            <Helmet>
                <title>404 - Перфект Консулт</title>
            </Helmet>
            <section className="not-found__section" style={{ backgroundImage: `url('${OperaBlurredBG}')` }}>
                <Container className="position-relative">
                    <div className="not-found__graphic mb-4">404</div>
                    <h1 className="not-found__heading">Страницата не е намерена</h1>
                    <p className="mb-4">За съжаление, страницата, която търсите не съществува.</p>
                    <Row>
                        <Col className="not-found__buttons">
                            <button className="btn btn--cyan mb-2 mb-md-0 mr-md-2" onClick={() => history.goBack()}><FontAwesomeIcon icon={faArrowLeft} />Предишна Страаница</button>
                            <Button className="btn--orange" to="/"><FontAwesomeIcon icon={faHome} />Начална Страница</Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
}

export default NotFound;
