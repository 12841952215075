import React, { useState, useEffect, memo } from 'react';

// Components
import FormDropdown from './FormDropdown';
import CustomCheckbox from './CustomCheckbox';

const SubAreaFilter = ({ className, areaId, title, initialState = [], options = [], onFinish }) => {
    const [selected, setSelected] = useState(initialState);

    const handleCheck = val => {
        if(!selected.indexOf(val) !== -1) {
            setSelected(currSelected => [...currSelected, val]);
        }
    }

    const handleUncheck = val => {
        setSelected(currSelected => currSelected.filter(item => item !== val));
    }

    const clearState = () => {
        setSelected([]);
        onFinish([]);
    }

    const handleClose = () => {
        onFinish(selected);
    }

    useEffect(() => {
        setSelected(initialState)
    }, [initialState])

    return (
        <FormDropdown className={className} title={title} type="list" selectionLength={selected.length} onDelete={clearState} onClose={handleClose}>
            {options.filter(item => item.areaId === areaId).map(option => {
                return <CustomCheckbox key={option.id + option.name} title={option.name} value={option.id} reset={selected.length === 0} initSelected={selected.indexOf(option.id) !== -1} onCheck={handleCheck} onUncheck={handleUncheck} />
            })}
        </FormDropdown>
    );
}

export default memo(SubAreaFilter);
