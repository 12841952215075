import React, { useEffect, useReducer, createContext } from 'react';
import axios from 'axios';

// Actions
import {
    UPDATE_PROPERTY_TYPES,
    UPDATE_BUILDING_TYPES,
    UPDATE_HEATING_TYPES,
    UPDATE_INTERIOR_TYPES,
    UPDATE_VIEW_DIRECTIONS,
    UPDATE_BUILD_STAGES,
    UPDATE_AREAS,
    UPDATE_BLOG_TAGS
} from './actions/FiltersActions';

// Reducers
import FiltersReducer from '../data/reducers/FiltersReducer';

export const FiltersContext = createContext();

export const FiltersProvider = ({ children }) => {
    const [state, dispatch] = useReducer(FiltersReducer, initialState);

    const updateFiltersState = (type, payload) => { dispatch({ type, payload }) }

    useEffect(() => {
        async function fetchFilters() {
            if (!state.propertyTypes.length) {
                const { data: propertyTypes } = await axios.get(process.env.REACT_APP_API_PROPERTY_TYPES);
                updateFiltersState(UPDATE_PROPERTY_TYPES, propertyTypes);
                sessionStorage.setItem('property-types', JSON.stringify(propertyTypes));
            }
            if (!state.buildingTypes.length) {
                const { data: buildingTypes } = await axios.get(process.env.REACT_APP_API_BUILDING_TYPES);
                updateFiltersState(UPDATE_BUILDING_TYPES, buildingTypes);
                sessionStorage.setItem('building-types', JSON.stringify(buildingTypes));
            }
            if (!state.heatingTypes.length) {
                const { data: heatingTypes } = await axios.get(process.env.REACT_APP_API_HEATING_TYPES);
                updateFiltersState(UPDATE_HEATING_TYPES, heatingTypes);
                sessionStorage.setItem('heating-types', JSON.stringify(heatingTypes));
            }
            if (!state.interiorTypes.length) {
                const { data: interiorTypes } = await axios.get(process.env.REACT_APP_API_INTERIOR_TYPES);
                updateFiltersState(UPDATE_INTERIOR_TYPES, interiorTypes);
                sessionStorage.setItem('interior-types', JSON.stringify(interiorTypes));
            }
            if (!state.viewDirections.length) {
                const { data: viewDirections } = await axios.get(process.env.REACT_APP_API_VIEW_DIRECTIONS);
                updateFiltersState(UPDATE_VIEW_DIRECTIONS, viewDirections);
                sessionStorage.setItem('view-directions', JSON.stringify(viewDirections));
            }
            if (!state.buildStages.length) {
                const { data: buildStages } = await axios.get(process.env.REACT_APP_API_BUILD_STAGES);
                updateFiltersState(UPDATE_BUILD_STAGES, buildStages);
                sessionStorage.setItem('build-stages', JSON.stringify(buildStages));
            }
            if (!state.areas.length || !state.subAreas.length) {
                const { data: areas } = await axios.get(process.env.REACT_APP_API_AREAS);
                updateFiltersState(UPDATE_AREAS, areas);
                sessionStorage.setItem('areas', JSON.stringify(areas.areas));
                sessionStorage.setItem('subAreas', JSON.stringify(areas.subAreas));
            }
            if (!state.blogTags.length) {
                const { data: tags } = await axios.get(process.env.REACT_APP_API_BLOG_TAGS);
                updateFiltersState(UPDATE_BLOG_TAGS, tags);
                sessionStorage.setItem('blog-tags', JSON.stringify(tags));
            }
        }
        fetchFilters();
        //eslint-disable-next-line
    }, []);

    return (
        <FiltersContext.Provider value={{ ...state }}>
            {children}
        </FiltersContext.Provider>
    )
};

const initialState = {
    areas: JSON.parse(sessionStorage.getItem('areas')) || [],
    subAreas: JSON.parse(sessionStorage.getItem('subAreas')) || [],
    propertyTypes: JSON.parse(sessionStorage.getItem('property-types')) || [],
    buildingTypes: JSON.parse(sessionStorage.getItem('building-types')) || [],
    heatingTypes: JSON.parse(sessionStorage.getItem('heating-types')) || [],
    interiorTypes: JSON.parse(sessionStorage.getItem('interior-types')) || [],
    viewDirections: JSON.parse(sessionStorage.getItem('view-directions')) || [],
    buildStages: JSON.parse(sessionStorage.getItem('build-stages')) || [],
    blogTags: JSON.parse(sessionStorage.getItem('blog-tags')) || [],
    offerTypes: [
        {
            id: 1,
            title: 'Продажба',
            value: 'buy'
        },
        {
            id: 2,
            title: 'Под Наем',
            value: 'rent'
        }
    ],
    otherFilters: [
        {
            title: 'Домашни Любимци',
            value: 'pets'
        },
        {
            title: 'Паркинг',
            value: 'parking'
        },
        {
            title: 'Гараж',
            value: 'garage'
        },
        {
            title: 'Асансьор',
            value: 'elevator'
        },
        {
            title: 'Обяви с Видео',
            value: 'video'
        },
        {
            title: 'Намалена Цена',
            value: 'discount'
        },
        {
            title: 'Топ Оферти',
            value: 'featured'
        },
        {
            title: 'Нови Оферти',
            value: 'newoffer'
        }
    ],
    perPageOptions: [
        {
            id: 20,
            name: '20'
        },
        {
            id: 40,
            name: '40'
        },
        {
            id: 80,
            name: '80'
        },
        {
            id: 160,
            name: '160'
        },
    ],
    sortOptions: [
        {
            id: 'published',
            name: 'Публикувано'
        },
        {
            id: 'size_total',
            name: 'Квадратура'
        },
        {
            id: 'price',
            name: 'Цена'
        },
        {
            id: 'build_year',
            name: 'Година'
        },
    ],
    orderOptions: [
        {
            id: 'desc',
            name: 'Низходящо'
        },
        {
            id: 'asc',
            name: 'Възходящо'
        },
    ],
    blogPerPageOptions: [
        {
            id: 20,
            name: '20'
        },
        {
            id: 40,
            name: '40'
        },
        {
            id: 80,
            name: '80'
        },
        {
            id: 160,
            name: '160'
        },
    ],
    blogSortOptions: [
        {
            id: 'published_at',
            name: 'Публикувано'
        },
        {
            id: 'views',
            name: 'Популярност'
        },
    ]
}