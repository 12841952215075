import React, { useContext, useCallback, memo } from 'react';
//eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

// Actions
import {
    UPDATE_FILTERS_SIZE,
    UPDATE_FILTERS_PRICE,
    UPDATE_FILTERS_YEAR,
    UPDATE_FILTERS_TYPES,
    UPDATE_FILTERS_CONSTRUCTION,
    UPDATE_FILTERS_HEATING,
    UPDATE_FILTERS_FURNISHING,
    UPDATE_FILTERS_FACING,
    UPDATE_FILTERS_STAGE,
    UPDATE_FILTERS_OTHER,
    UPDATE_SELECTED_AREA,
    UPDATE_SELECTED_SUB_AREA,
    UPDATE_OFFER_TYPE,
    CLEAR_FILTERS
} from '../../data/actions/PropertiesActions';

// Hooks
import { useViewportWidth } from '../../hooks/useViewportWidth';

// Context
import { FiltersContext } from '../../data/FiltersContext';
import { PropertiesContext } from '../../data/PropertiesContext';

// Components
import RadioFilter from '../RadioFilter';
import SubAreaFilter from '../SubAreaFilter';
import FormDropdown from '../FormDropdown';
import RadioButtonFilter from '../RadioButtonFilter';
import MinMaxFilter from '../MinMaxFilter';
import CheckboxFilter from '../CheckboxFilter';
import OtherFilter from '../OtherFilter';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Styles
import '../../scss/search-bar.scss';

const SearchBar = ({ searchType, onFinish = () => { } }) => {
    const { search, type, filters, selectedArea, selectedSubAreas, updatePropertiesState } = useContext(PropertiesContext);
    const { offerTypes, propertyTypes, buildingTypes, heatingTypes, interiorTypes, viewDirections, buildStages, areas, subAreas, otherFilters } = useContext(FiltersContext);

    const viewportWidth = useViewportWidth();

    const isLaptop = viewportWidth < 1240;
    // const isTablet = viewportWidth < 992;
    // const isMobile = viewportWidth < 576;

    const hanldeOfferTypeUpdate = useCallback(val => {
        if (type !== val) {
            updatePropertiesState(UPDATE_OFFER_TYPE, val);
            onFinish();
        }
        //eslint-disable-next-line
    }, [type])

    const hanldeAreaUpdate = useCallback(obj => {
        if (selectedArea !== obj) {
            updatePropertiesState(UPDATE_SELECTED_AREA, obj);
            onFinish();
        }
        //eslint-disable-next-line
    }, [selectedArea])

    const handleSubAreaUpdate = useCallback(val => {
        if (JSON.stringify(selectedSubAreas) !== JSON.stringify(val)) {
            updatePropertiesState(UPDATE_SELECTED_SUB_AREA, val);
            onFinish();
        }
        //eslint-disable-next-line
    }, [selectedSubAreas])

    const handlePropertyTypesUpdate = useCallback(val => {
        if (JSON.stringify(filters.types) !== JSON.stringify(val)) {
            updatePropertiesState(UPDATE_FILTERS_TYPES, val);
            onFinish();
        }
        //eslint-disable-next-line
    }, [filters.types])

    const handleBuildingTypesUpdate = useCallback(val => {
        if (JSON.stringify(filters.construction) !== JSON.stringify(val)) {
            updatePropertiesState(UPDATE_FILTERS_CONSTRUCTION, val);
            onFinish();
        }
        //eslint-disable-next-line
    }, [filters.construction])

    const handleInteriorTypesUpdate = useCallback(val => {
        if (JSON.stringify(filters.furnishing) !== JSON.stringify(val)) {
            updatePropertiesState(UPDATE_FILTERS_FURNISHING, val);
            onFinish();
        }
        //eslint-disable-next-line
    }, [filters.furnishing])

    const handleHeatingTypesUpdate = useCallback(val => {
        if (JSON.stringify(filters.heating) !== JSON.stringify(val)) {
            updatePropertiesState(UPDATE_FILTERS_HEATING, val);
            onFinish();
        }
        //eslint-disable-next-line
    }, [filters.heating])

    const handleViewDirectionsUpdate = useCallback(val => {
        if (JSON.stringify(filters.facing) !== JSON.stringify(val)) {
            updatePropertiesState(UPDATE_FILTERS_FACING, val);
            onFinish();
        }
        //eslint-disable-next-line
    }, [filters.facing])

    const handleBuildStagesUpdate = useCallback(val => {
        if (JSON.stringify(filters.stage) !== JSON.stringify(val)) {
            updatePropertiesState(UPDATE_FILTERS_STAGE, val);
            onFinish();
        }
        //eslint-disable-next-line
    }, [filters.stage])

    const handleOtherFiltersUpdate = useCallback(val => {
        if (JSON.stringify(filters.other) !== JSON.stringify(val)) {
            updatePropertiesState(UPDATE_FILTERS_OTHER, val);
            onFinish();
        }
        //eslint-disable-next-line
    }, [filters.other])

    const handleFiltersSizeUpdate = useCallback(val => {
        if (JSON.stringify(filters.size) !== JSON.stringify(val)) {
            updatePropertiesState(UPDATE_FILTERS_SIZE, val);
            onFinish();
        }
        //eslint-disable-next-line
    }, [filters.size])

    const handleFiltersPriceUpdate = useCallback(val => {
        if (JSON.stringify(filters.price) !== JSON.stringify(val)) {
            updatePropertiesState(UPDATE_FILTERS_PRICE, val);
            onFinish();
        }
        //eslint-disable-next-line
    }, [filters.price])

    const handleFiltersYearUpdate = useCallback(val => {
        if (JSON.stringify(filters.year) !== JSON.stringify(val)) {
            updatePropertiesState(UPDATE_FILTERS_YEAR, val);
            onFinish();
        }
        //eslint-disable-next-line
    }, [filters.year])

    const handleClearAll = useCallback(() => {
        updatePropertiesState(CLEAR_FILTERS);
        onFinish();
        //eslint-disable-next-line
    }, [type, filters, selectedArea, selectedSubAreas])

    const areOptionsSelected = (type || selectedSubAreas.length ||  filters.construction.length || filters.facing.length || filters.furnishing.length || filters.heating.length || filters.other.length || filters.stage.length || filters.types.length || filters.size[0] || filters.size[1] || filters.price[0] || filters.price[1] || filters.year[0] || filters.year[1] );

    return (
        <section className={`search-bar${searchType ? ' search-bar--' + searchType : ''}`}>
            <Container>
                <Row>
                    <Col>
                        <div className="search-bar__wrapper">
                            <div className="search-bar__inner">
                                {areOptionsSelected ? (<button className="search-bar__clear-all" type="button" onClick={handleClearAll}><FontAwesomeIcon icon={faTrashAlt} /></button>) : null}
                                <RadioButtonFilter className="search-bar__offer-types" initialState={type} options={offerTypes} onSelect={hanldeOfferTypeUpdate} />
                                <CheckboxFilter className="search-bar__property-types" title="Вид на Имота" initialState={filters.types} options={propertyTypes} onFinish={handlePropertyTypesUpdate} />
                                <MinMaxFilter className="search-bar__size" title="Площ" type="size" initialState={filters.size} onFinish={handleFiltersSizeUpdate} />
                                <MinMaxFilter className="search-bar__price" title="Цена" type="price" initialState={filters.price} onFinish={handleFiltersPriceUpdate} />
                                <FormDropdown className="search-bar__other two-column-checkbox" title="Още Филтри">
                                    <RadioFilter title="Локация" initialState={selectedArea} options={areas} onSelect={hanldeAreaUpdate} />
                                    <SubAreaFilter title="Район" areaId={selectedArea.id} initialState={selectedSubAreas} options={subAreas} onFinish={handleSubAreaUpdate} />
                                    <CheckboxFilter title="Строителство" initialState={filters.construction} options={buildingTypes} onFinish={handleBuildingTypesUpdate} />
                                    <CheckboxFilter title="Обзавеждане" initialState={filters.furnishing} options={interiorTypes} onFinish={handleInteriorTypesUpdate} />
                                    <CheckboxFilter title="Отопление" initialState={filters.heating} options={heatingTypes} onFinish={handleHeatingTypesUpdate} />
                                    <CheckboxFilter title="Изложение" initialState={filters.facing} options={viewDirections} onFinish={handleViewDirectionsUpdate} />
                                    <CheckboxFilter title="Завършеност" initialState={filters.stage} options={buildStages} onFinish={handleBuildStagesUpdate} />
                                    <MinMaxFilter title="Година" type="year" maxPossible={new Date().getFullYear()} initialState={filters.year} onFinish={handleFiltersYearUpdate} />
                                    <OtherFilter title="Други" initialState={filters.other} options={otherFilters} onFinish={handleOtherFiltersUpdate} />
                                </FormDropdown>
                                {isLaptop && searchType === 'static' ? <Link className="search-bar__submit" to={`/properties${search}`}><FontAwesomeIcon icon={faSearch} />Търси</Link> : null}
                            </div>
                            {!isLaptop && searchType === 'static' ? <Link className="search-bar__submit" to={`/properties${search}`}><FontAwesomeIcon icon={faSearch} />Търси</Link> : null}
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default memo(SearchBar);
