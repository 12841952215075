import React, { useContext } from 'react';

// Context
import { BlogContext } from '../../data/BlogContext';

// Components
import ArticleCard from '../ArticleCard';
import NoResults from '../../components/NoResults';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Styles
import '../../scss/article-listing.scss';

const ArticleListing = ({ className }) => {
    const { data } = useContext(BlogContext);

    return (
        <section className={`article-listing${className ? ' ' + className : ''}`}>
            <Container>
                <Row>
                    {data.length ? null : <Col>
                        <NoResults />
                    </Col>}
                    {data.length ? data.map(article => {
                        return <Col className="col-12 col-md-6 mb-4" key={article.id}>
                            <ArticleCard
                                id={article.id}
                                image={article.thumbnail}
                                title={article.title}
                                description={article.content}
                                published={article.publishedAt}
                                tags={article.tags}
                            />
                        </Col>
                    }) : null}
                </Row>
            </Container>
        </section>
    );
}

export default ArticleListing;
