import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';

// Components
import CustomRadio from './CustomRadio';

const RadioButtonFilter = ({ className, initialState, options, onSelect }) => {
    const [selected, setSelected] = useState(initialState);

    const handleCheck = val => {
        setSelected(val);
    }

    useEffect(() => {
        onSelect(selected);
        //eslint-disable-next-line
    }, [selected])

    useEffect(() => {
        setSelected(initialState);
    }, [initialState])

    return (
        <div className={className}>
            {options.map(option => {
                return <CustomRadio key={option.id} type="button" title={option.title} value={option.value} initChecked={selected === option.value} onCheck={handleCheck} />
            })}
        </div>
    );
}


RadioButtonFilter.propTypes = {
    className: PropTypes.string,
    initialState: PropTypes.string,
    options: PropTypes.array,
    onSelect: PropTypes.func
}

export default memo(RadioButtonFilter);
