import React, { useEffect, useContext, useMemo, useReducer, createContext } from 'react';

// Actions
import { UPDATE_DATA } from './actions/LastSeenActions';

// Hooks
import useAxiosGet from '../hooks/useAxiosGet';

// Utility
import { addNewOfferData } from '../utility/addNewOfferData';

// Context
import { PropertyContext } from './PropertyContext';

// Reducers
import LastSeenReducer from './reducers/LastSeenReducer';

export const LastSeenContext = createContext();

export const LastSeenProvider = ({ children, initialState }) => {
    const [state, dispatch] = useReducer(LastSeenReducer, initialState);
    const updateLastSeenState = (type, payload) => { dispatch({ type, payload }) };

    const { lastSeen } = useContext(PropertyContext);

    const lastSeenQuery = useMemo(() => {
        return lastSeen.length ? `${process.env.REACT_APP_API_PROPERTIES}?${lastSeen.map(val => `lastViewed[]=${val}`).join('&')}` : ''
    }, [lastSeen])

    const { response } = useAxiosGet(lastSeenQuery);

    const updateLastSeen = (data = [], lastSeenData = []) => {
        if (data.length && lastSeenData.length) {
            const fetchedIDs = data.map(property => property.id);
            localStorage.setItem('lastSeen', JSON.stringify(fetchedIDs));
            updateLastSeenState(UPDATE_DATA, addNewOfferData(data));
        }
    }

    useEffect(() => {
        if (response !== null) {
            updateLastSeen(response.data.data, lastSeen);
        }
        //eslint-disable-next-line
    }, [response, lastSeen])

    return (
        <LastSeenContext.Provider value={{ ...state, updateLastSeenState }}>
            {children}
        </LastSeenContext.Provider>
    )
};