import { useState, useEffect } from 'react';
import axios from 'axios';

function useAxiosGet(url) {
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const data = await axios.get(url)
                setIsLoading(false);
                setResponse(data);
            } catch (err) {
                if (err.response) {
                    setError(err.response);
                    console.error('Client received an error response (5xx, 4xx)!');
                } else if (err.request) {
                    setError(err.request);
                    console.error('Client never received a response, or request never left!');
                } else {
                    console.error('Request failed for unknown reason!');
                }
            }
        }
        fetchData();
    }, [url])

    return { isLoading, response, error };
}

export default useAxiosGet;