import React from 'react';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Styles
import '../../scss/inner-page-top.scss';

// Assets
import InnerPageTopBg from '../../assets/images/inner-page-top-bg.jpg';

const InnerPageTop = ({ pageTitle, pageDescription }) => {
    return (
        <section className="inner-page-top" style={{ backgroundImage: `url('${InnerPageTopBg}')` }}>
            <div className="inner-page-top__content">
                <Container>
                    <Row>
                        <Col>
                            <h1 className="inner-page-top__heading">{pageTitle}</h1>
                            {pageDescription ? <p className="inner-page-top__description">{pageDescription}</p> : null}
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
}

export default InnerPageTop;
