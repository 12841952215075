import React, { useEffect, useContext } from 'react';
import sanitizeHtml from 'sanitize-html';
import { Helmet } from 'react-helmet';

// Actions
import {
    UPDATE_DATA
} from '../data/actions/BlogActions';

// Utility
import { descriptionShortener } from '../utility/descriptionShortener';
import { updateBlogStateFromSearch } from '../utility/manageSearchParamsBlog';
import { setQueryStringWithoutPageReload } from '../utility/setQueryStringWithoutPageReload';

// Hooks
import useAxiosGet from '../hooks/useAxiosGet';

// Context
import { BlogContext } from '../data/BlogContext';

// Components
import Loading from '../components/Loading';
import BlogFilters from '../components/BlogFilters';
import BlogPopular from '../components/BlogPopular';
import InnerPageTop from '../components/sections/InnerPageTop';
import InnerPageContent from '../components/sections/InnerPageContent';
import ArticleListing from '../components/sections/ArticleListing';
import BlogControls from '../components/sections/BlogControls';

// Styles
import '../scss/blog.scss';

const Blog = ({ location }) => {
    const { search, updateBlogState } = useContext(BlogContext);
    const { isLoading, response } = useAxiosGet(`${process.env.REACT_APP_API_BLOG_ARTICLES}${search ? search : '?sort=published_at&order=desc'}`);

    useEffect(() => {
        updateBlogStateFromSearch(location.search, updateBlogState);
        //eslint-disable-next-line
    }, [location])

    useEffect(() => {
        if (response) {
            window.scrollTo(0, 0);
            updateBlogState(UPDATE_DATA, {
                ...response.data, data: response.data.data.map(article => {
                    return {
                        ...article, content: descriptionShortener(sanitizeHtml(article.content, {
                            exclusiveFilter: (frame) => {
                                return frame.tag === 'h2' || frame.tag === 'h3' || frame.tag === 'h4' || frame.tag === 'h5' || frame.tag === 'h6';
                            }
                        }).replace(/(<([^>]+)>)/gi, '').replace(/(\r\n|\n|\r)/gm, '').replace('  ', ' ').replace('   ', ' '), 170)
                    }
                })
            });
        }
        //eslint-disable-next-line
    }, [response])

    useEffect(() => {
        if (search) {
            setQueryStringWithoutPageReload(search);
        }
        //eslint-disable-next-line
    }, [search])

    return (
        <main className="blog">
            <Helmet>
                <title>Блог - Перфект Консулт</title>
            </Helmet>
            <InnerPageTop pageTitle="Блог" pageDescription="Новини и полезна информация относно недвижими имоти." />
            <InnerPageContent asideChildren={(
                <>
                    <BlogFilters className="d-none d-xl-block" />
                    <BlogPopular />
                </>
            )}>
                <BlogFilters className="mb-4 d-block d-xl-none" />
                <div><h2 className="blog__listing-heading">Статии</h2></div>
                {isLoading ? <Loading /> : <ArticleListing className="row" />}
                <BlogControls className="row" />
            </InnerPageContent>
        </main>
    );
}

export default Blog;
