import React, { useContext, useCallback, memo } from 'react';

// Actions
import {
    UPDATE_TAGS
} from '../data/actions/BlogActions';

// Context
import { BlogContext } from '../data/BlogContext';
import { FiltersContext } from '../data/FiltersContext';

// Components
import CheckboxFilter from './CheckboxFilter';

// Styles
import '../scss/blog-filters.scss';

const BlogFilters = ({ className, onFinish = () => { } }) => {
    const { tags, updateBlogState } = useContext(BlogContext);
    const { blogTags } = useContext(FiltersContext);

    const handleTagsUpdate = useCallback(val => {
        if (JSON.stringify(tags) !== JSON.stringify(val)) {
            updateBlogState(UPDATE_TAGS, val);
            onFinish();
        }
        //eslint-disable-next-line
    }, [tags])

    return (
        <section className={`blog-filters${className ? ' ' + className : ''}`}>
            <h2 className="blog-filters__heading">Филтри</h2>
            <CheckboxFilter className="blog-filters__blog-tags" title="Тагове" initialState={tags} options={blogTags} onFinish={handleTagsUpdate} />
        </section>
    );
}

export default memo(BlogFilters);
