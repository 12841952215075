import React from 'react';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styles
import '../scss/service-card.scss';

const ServiceCard = ({ className, icon, title = '', description = '' }) => {
    return (
        <div className={`service-card${className ? ' ' + className : ''}`}>
            {icon ? <div className="service-card__icon"><FontAwesomeIcon icon={icon} /></div> : null}
            <h3 className="service-card__heading">{title}</h3>
            {description ? <p className="service-card__description">{description}</p> : null}
        </div>
    );
}

export default ServiceCard;
