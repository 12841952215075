import React from 'react';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Assets
import { ReactComponent as LoadingSvg } from '../assets/images/loading.svg';

// Styles
import '../scss/loading.scss';

const Loading = () => {
    return (
        <section className="loading">
            <Container>
                <Row>
                    <Col className="loading__col">
                        <LoadingSvg className="loading__svg" />
                        <div className="loading__text">Зареждане...</div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Loading;
