import React, { useReducer, createContext, useEffect } from 'react';

// Reducers
import PropertyReducer from '../data/reducers/PropertyReducer';

export const PropertyContext = createContext();

export const PropertyProvider = ({ children, initialState }) => {
    const [state, dispatch] = useReducer(PropertyReducer, initialState);

    const updatePropertyState = (type, payload) => { dispatch({ type, payload }) }

    useEffect(() => {
        if(state.lastSeen.length) localStorage.setItem('lastSeen', JSON.stringify(state.lastSeen))
    }, [state.lastSeen])

    return (
        <PropertyContext.Provider value={{ ...state, updatePropertyState }}>
            {children}
        </PropertyContext.Provider>
    )
};