import React, { useContext, useEffect, useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import parse from 'html-react-parser';
import axios from 'axios';
import { Helmet } from 'react-helmet';

// Share Buttons
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from 'react-share';

// Utility
import { formatPublished } from '../utility/formatPublished.js';

// Actions
import {
    UPDATE_ARTICLE,
    UPDATE_LAST_SEEN
} from '../data/actions/BlogActions';

// Hooks
import useAxiosGet from '../hooks/useAxiosGet';

// Context
import { BlogContext } from '../data/BlogContext';

// Components
import Loading from '../components/Loading';
import ImageLightbox from '../components/ImageLightbox';
import BlogPopular from '../components/BlogPopular';
import InnerPageTop from '../components/sections/InnerPageTop';
import InnerPageContent from '../components/sections/InnerPageContent';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebook,
    faLinkedin,
    faTwitter
} from '@fortawesome/free-brands-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';

// Styles
import '../scss/blog-article.scss';

const BlogArticle = ({ id }) => {
    const { article, lastSeen, updateBlogState } = useContext(BlogContext);
    const { isLoading, response } = useAxiosGet(`${process.env.REACT_APP_API_BLOG_ARTICLES}/${id}`);
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [lightboxImages, setLightboxImages] = useState([]);

    useEffect(() => {
        if (response) {
            window.scrollTo(0, 0);
            const cleanContent = sanitizeHtml(response.data.content.replace(/(\r\n|\n|\r)/gm, ''), {
                allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
                allowedAttributes: {
                    a: ['href', 'target', 'rel', 'title'],
                    img: ['src', 'alt', 'style', 'width', 'height']
                },
                transformTags: {
                    'a': (tagName, attribs) => {
                        return {
                            tagName: 'a',
                            attribs: {
                                ...attribs,
                                rel: 'nofollow noopener noreferrer'
                            }
                        };
                    }
                },
            });

            updateBlogState(UPDATE_ARTICLE, { ...response.data, content: cleanContent });
        }
        //eslint-disable-next-line
    }, [response])

    const addArticleView = async articleId => {
        await axios.post(process.env.REACT_APP_API_BLOG_ADD_VIEW + articleId)
            .then(res => {
                console.log(res.data);
            }).catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        if (!lastSeen.includes(id)) {
            updateBlogState(UPDATE_LAST_SEEN, id);
            addArticleView(id);
        };
        //eslint-disable-next-line
    }, [])

    const handleOpenLightbox = (e) => {
        if (e.target.tagName === 'IMG') {
            setLightboxImages([e.target.src]);
            setIsLightboxOpen(true);
        }
    }

    const handleCloseLightbox = () => {
        setIsLightboxOpen(false);
    }

    return (
        <main className="blog-article">
            {isLoading ? null : <Helmet>
                <meta property="og:image" content={process.env.REACT_APP_API_BLOG_IMAGES + article.thumbnail} />
                <meta property="og:title" content={`${article.title} - Перфект Консулт`} />
                <meta property="twitter:image" content={process.env.REACT_APP_API_BLOG_IMAGES + article.thumbnail} />
                <meta property="twitter:title" content={`${article.title} - Перфект Консулт`} />
                <title>{article.title} - Перфект Консулт</title>
            </Helmet>}
            <InnerPageTop pageTitle="Блог Статия" pageDescription="Новини и полезна информация относно недвижими имоти." />
            <InnerPageContent asideChildren={(
                <>
                    <BlogPopular />
                </>
            )}>
                {isLoading ? <Loading /> : (
                    <article className="blog-article__section" itemScope itemType="//schema.org/BlogPosting" onClick={handleOpenLightbox}>
                        <header>
                            <div className="blog-article__details">
                                {article.author ? <div className="blog-article__author mr-2"><span>Автор:</span> {article.author}</div> : null}
                                <div className="blog-article__published mr-2"><span>Публикувана:</span> <time itemProp="datePublished" dateTime={article.publishedAt}>{formatPublished(article.publishedAt)}</time></div>
                                <div className="blog-article__views mr-2"><div className="mr-1"><FontAwesomeIcon icon={faEye} /></div> {article.views}</div>
                                <div className="blog-article__share ml-md-auto">
                                    <span>Сподели:</span>
                                    <FacebookShareButton children={<FontAwesomeIcon icon={faFacebook} />} url={window.location.href} />
                                    <LinkedinShareButton children={<FontAwesomeIcon icon={faLinkedin} />} url={window.location.href} />
                                    <TwitterShareButton children={<FontAwesomeIcon icon={faTwitter} />} url={window.location.href} />
                                </div>
                            </div>
                            <h1 className="blog-article__heading" itemProp="headline">{article.title}</h1>
                        </header>
                        {article.thumbnail ? (
                            <figure className="blog-article__figure mb-4">
                                <img className="blog-article__thumbnail" src={process.env.REACT_APP_API_BLOG_IMAGES + article.thumbnail} alt={article.title} />
                            </figure>
                        ) : null}
                        <div className="entry-content">{parse(`${article.content}`)}</div>
                        <footer>
                            {article.tags.length ? <div className="blog-article__tags"><b>Тагове:</b> {article.tags.join(', ')}</div> : null}
                        </footer>
                    </article>
                )}
            </InnerPageContent>
            <ImageLightbox isOpen={isLightboxOpen} images={lightboxImages} onClose={handleCloseLightbox} />
        </main>
    );
}

export default BlogArticle;
