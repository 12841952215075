import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

// Styles
import '../scss/form-dropdown.scss';

const FormDropdown = ({ className, children, title, type, selectionLength, onOpen = () => {}, onClose = () => {}, onDelete = () => {} }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const classNames = `${className ? ' ' + className : ''}${dropdownOpen ? ' open' : ''}`;

    const clickHandler = () => {
        setDropdownOpen(!dropdownOpen)
    }

    const blurHandler = e => {
        const currentTarget = e.currentTarget;

        setTimeout(() => {
            if (!currentTarget.contains(document.activeElement)) {
                setDropdownOpen(false);
            }
        }, 0);
    }

    const deleteHandler = () => {
        onDelete();
        setDropdownOpen(false);
    }

    useEffect(() => {
        if (dropdownOpen) {
            onOpen();
        } else {
            onClose();
        }
        //eslint-disable-next-line
    }, [dropdownOpen])

    return (
        <div className={`form-dropdown${classNames}`} onBlur={blurHandler} tabIndex="0">
            {selectionLength ? <div className="form-dropdown__selection">
                <div className="form-dropdown__selection-text">{`${title}: ${selectionLength}`}</div>
                <div className="form-dropdown__selection-delete" onClick={deleteHandler}><FontAwesomeIcon icon={faTrashAlt} /></div>
            </div> : null}
            <button className="form-dropdown__toggler" onClick={clickHandler} type="button">
                <span className="form-dropdown__title">{title}</span>
                <span className="form-dropdown__icon"><FontAwesomeIcon icon={faAngleDown} /></span>
            </button>
            <div className="form-dropdown__dropdown">
                {type === 'list' ? (<ul className="form-dropdown__options ul-clean">
                    {children.map((child, index) => {
                        return <li className="form-dropdown__option" key={index + child.props.title}>{child}</li>
                    })}
                </ul>) : children}
            </div>
        </div>
    );
}

FormDropdown.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onDelete: PropTypes.func
}

export default memo(FormDropdown);
