import {
    UPDATE_PROPERTY_TYPES,
    UPDATE_BUILDING_TYPES,
    UPDATE_HEATING_TYPES,
    UPDATE_INTERIOR_TYPES,
    UPDATE_VIEW_DIRECTIONS,
    UPDATE_BUILD_STAGES,
    UPDATE_AREAS,
    UPDATE_BLOG_TAGS
} from '../actions/FiltersActions';

const FiltersReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_PROPERTY_TYPES:
            return {
                ...state,
                propertyTypes: action.payload
            }
        case UPDATE_BUILDING_TYPES:
            return {
                ...state,
                buildingTypes: action.payload
            }
        case UPDATE_HEATING_TYPES:
            return {
                ...state,
                heatingTypes: action.payload
            }
        case UPDATE_INTERIOR_TYPES:
            return {
                ...state,
                interiorTypes: action.payload
            }
        case UPDATE_VIEW_DIRECTIONS:
            return {
                ...state,
                viewDirections: action.payload
            }
        case UPDATE_BUILD_STAGES:
            return {
                ...state,
                buildStages: action.payload
            }
        case UPDATE_AREAS:
            return {
                ...state,
                areas: action.payload.areas,
                subAreas: action.payload.subAreas
            }
        case UPDATE_BLOG_TAGS:
            return {
                ...state,
                blogTags: action.payload,
            }
        default:
            return state
    }
}

export default FiltersReducer;