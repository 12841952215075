import React, { useState, useRef, useEffect } from 'react';

// Components
import FormDropdown from './FormDropdown';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVectorSquare } from '@fortawesome/free-solid-svg-icons';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

// Styles
import '../scss/min-max.scss';

const MinMaxFilter = ({ className, title, type, maxPossible, initialState, onFinish }) => {
    const [min, setMin] = useState(initialState[0]);
    const [max, setMax] = useState(initialState[1]);
    const [minWarning, setMinWarning] = useState(false);
    const [maxWarning, setMaxWarning] = useState(false);

    const minInputRef = useRef();

    const focusMinInput = () => minInputRef.current.focus();

    const handleMinMax = e => {
        switch (e.target.name) {
            case `min_${type}`:
                setMin(e.target.value !== '0' ? e.target.value : '');
                if (e.target.value * 1 >= max * 1 && max * 1 !== 0) {
                    setMinWarning(true)
                } else {
                    setMinWarning(false)
                    setMaxWarning(false)
                }
                break;
            case `max_${type}`:
                setMax(e.target.value !== '0' ? e.target.value : '');
                if (e.target.value * 1 <= min * 1 && e.target.value * 1 !== 0) {
                    setMaxWarning(true)
                } else {
                    setMinWarning(false)
                    setMaxWarning(false)
                }
                break;
            default:
                return null
        }
    };

    const inputIcon = () => {
        switch (type) {
            case 'size':
                return <FontAwesomeIcon icon={faVectorSquare} />;
            case 'price':
                return <FontAwesomeIcon icon={faEuroSign} />;
            case 'year':
                return <FontAwesomeIcon icon={faCalendarAlt} />;
            default:
                return null
        }
    }

    const handleDelete = () => {
        setMin('');
        setMax('');
        onFinish(['', '']);
    }

    const selectedValuesString = () => {
        switch (type) {
            case 'size':
                return `${min ? min + 'm²' : ''}${max ? ' - ' + max + 'm²' : ''}`;
            case 'price':
                return `${min ? '€' + min : ''}${max ? ' - €' + max : ''}`;
            case 'year':
                return `${min ? min + 'г.' : ''}${max ? ' - ' + max + 'г.' : ''}`;
            default:
                return null
        }
    }

    const handleClose = () => {
        onFinish([min, max]);
    }

    useEffect(() => {
        setMin(initialState[0]);
        setMax(initialState[1]);
    }, [initialState])

    return (
        <FormDropdown className={className} title={title} onOpen={focusMinInput} onClose={handleClose} onDelete={handleDelete} selectionLength={selectedValuesString()}>
            <div className="min-max">
                <div className="min-max__input mb-1">
                    <input className={`min-max__min w-100${minWarning || min > maxPossible ? ' warning' : ''}`} type="number" name={`min_${type}`} min="0" max={maxPossible} step="10" placeholder={`Мин. ${title}`} value={min} onChange={handleMinMax} ref={minInputRef} />
                    {inputIcon()}
                </div>
                <div className="min-max__input">
                    <input className={`min-max__max w-100${maxWarning || max > maxPossible ? ' warning' : ''}`} type="number" name={`max_${type}`} min="0" max={maxPossible} step="10" placeholder={`Макс. ${title}`} value={max} onChange={handleMinMax} />
                    {inputIcon()}
                </div>
            </div>
        </FormDropdown>
    );
}

export default MinMaxFilter;
