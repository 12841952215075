import React from 'react';

// Components
import SectionHeading from '../SectionHeading';
import ServiceCard from '../ServiceCard';
import ArrowLink from '../ArrowLink';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Styles
import '../../scss/why-us.scss';
import {
    faHourglass,
    faLaptopHouse,
    faThumbsUp,
} from '@fortawesome/free-solid-svg-icons';

const WhyUs = () => {
    return (
        <section className="why-us">
            <Container>
                <Row>
                    <Col>
                        <SectionHeading className="text-center" heading="Защо Перфект Консулт?" subHeading="С какво се отличаваме от нашите конкуренти." />
                    </Col>
                </Row>
                <Row>
                    {selectedServices.map(service => (
                        <Col className="col-12 col-xl-4 mb-4 mb-xl-0" key={service.title}>
                            <ServiceCard icon={service.icon} title={service.title} description={service.description} />
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col className="mt-4 text-center">
                        <ArrowLink to="/why-perfect-consult">Прочети Повече</ArrowLink>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default WhyUs;

const selectedServices = [
    {
        icon: faThumbsUp,
        title: 'Без риск. Не заплащате нищо предварително.',
        description: 'Всички инвестиции по рекламата и предлагането са за наша сметка. Вие заплащате стандартно комисионно възнаграждение само при сключване на сделка.'
    },
    {
        icon: faHourglass,
        title: 'Печелите време.',
        description: 'Делегирайте права на компанията ни и разполагайте със свободното си време. Ние правим всичко вместо вас.'
    },
    {
        icon: faLaptopHouse,
        title: 'Ние предлагаме хиляди актуални оферти.',
        description: 'Всеки, който се обърне към нас със запитване за имот достига моментално до мрежата ни от консултанти, които персонално подбират за него най-добрите оферти.'
    },
]
