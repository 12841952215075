import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';

// Components
import InnerPageTop from '../components/sections/InnerPageTop';
import ReviewCard from '../components/ReviewCard';
import LastSeenProperties from '../components/sections/LastSeenProperties';

// Context
import { GlobalContext } from '../data/GlobalContext';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Reviews = () => {
    const { reviews } = useContext(GlobalContext);

    return (
        <main className="reviews">
            <Helmet>
                <title>Отзиви - Перфект Консулт</title>
            </Helmet>
            <InnerPageTop pageTitle="Отзиви" pageDescription="Вижте какво казват клиентите ни за нас." />
            <section className="py-4" style={{backgroundColor: 'var(--brand-light-gray)'}}>
                <Container>
                    <Row>
                        {reviews.map(review => <Col className="col-12 col-lg-6 col-xxl-4 mb-4" key={review.id}><ReviewCard author={review.author} content={review.content} /></Col>)}
                    </Row>
                </Container>
            </section>
            <LastSeenProperties />
        </main>
    );
}

export default Reviews;
