import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import InnerPageTop from '../components/sections/InnerPageTop';
import InquiryForm from '../components/InquiryForm';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Support = () => {
    return (
        <main className="support">
            <Helmet>
                <title>Проблеми със Сайта - Перфект Консулт</title>
            </Helmet>
            <InnerPageTop pageTitle="Проблеми със Сайта" pageDescription="При проблеми със сайта можете да откриете помощ тук или да се свържете директно с отдел поддръжка." />
            <section className="my-4">
                <Container>
                    <Row>
                        <Col className="mb-2">
                            <h2 className="mb-4">Често срещани и известни проблеми</h2>
                            <h3>404 Страницата не е намерена</h3>
                            <p>Възможни причини:</p>
                            <ul>
                                <li><p>Въвели сте грешен адрес в адресната лента на браузъра.</p></li>
                                <li><p>Страницата, която търсите несъществува.</p></li>
                                <li><p>Страницата, която търсите е премахната.</p></li>
                            </ul>
                            <h3>Страница В Процес На Изграждане</h3>
                            <p>Възможни причини:</p>
                            <ul>
                                <li><p>Страницата, която търсите е спряна за поддръжка и скоро ще бъде възтановена.</p></li>
                                <li><p>Страницата, която търсите е в процес на изграждане.</p></li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-lg-8 col-xl-6 mb-4">
                            <InquiryForm page="Проблеми със Сайта" title="Свържете се с отдел поддръжка" description="Моля опишете проблемите, с които сте се сблъскали в тази форма." messageRows="5" />
                            {/* <InquiryForm toOther={[process.env.REACT_APP_DEVELOPER_EMAIL]} page="Проблеми със Сайта" title="Свържете се с отдел поддръжка" description="Моля опишете проблемите, с които сте се сблъскали в тази форма." messageRows="5" /> */}
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
}

export default Support;
