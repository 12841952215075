import React, { useReducer, createContext } from 'react';

// Reducers
import NewPropertiesReducer from '../data/reducers/NewPropertiesReducer';

export const NewPropertiesContext = createContext();

export const NewPropertiesProvider = ({ children, initialState }) => {
    const [state, dispatch] = useReducer(NewPropertiesReducer, initialState);

    const updateNewPropertiesState = (type, payload) => { dispatch({ type, payload }) }

    return (
        <NewPropertiesContext.Provider value={{...state, updateNewPropertiesState}}>
            {children}
        </NewPropertiesContext.Provider>
    )
};