// React Components
import React from 'react';

// Components
import Hero from '../components/sections/Hero';
import SearchBar from '../components/sections/SearchBar';
import NewProperties from '../components/sections/NewProperties';
import News from '../components/sections/News';
import WhyUs from '../components/sections/WhyUs';
import ReviewsSlider from '../components/sections/ReviewsSlider';
import LastSeenProperties from '../components/sections/LastSeenProperties';
// import PartnersSlider from '../components/sections/PartnersSlider';

// Style
import '../scss/home.scss';

const Home = () => {
    return (
        <main className="home">
            <Hero />
            <SearchBar searchType="static" />
            <NewProperties />
            <WhyUs />
            <ReviewsSlider />
            <News />
            <LastSeenProperties />
            {/* <PartnersSlider /> */}
        </main>
    );
}

export default Home;
