import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import LastSeenProperties from '../components/sections/LastSeenProperties';
import InnerPageTop from '../components/sections/InnerPageTop';
import InnerPageContent from '../components/sections/InnerPageContent';
import InquiryForm from '../components/InquiryForm';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Assets
import careersPlaceholder from '../assets/images/careers-placeholder.jpg';

// Styles
import '../scss/services.scss';

const Careers = () => {
    return (
        <main className="services">
            <Helmet>
                <title>Кариери - Перфект Консулт</title>
            </Helmet>
            <InnerPageTop pageTitle="Кариери" pageDescription="Вижте предлаганите от нас позиции." />
            <InnerPageContent asideChildren={(
                <>
                    <InquiryForm page="Страница Кариери" title="Имаш въпроси?" description="Пиши ни като попълниш формата долу." placeholder={`Здравейте имам въпрос относно позиция, която предлагате.`} messageRows="5" />
                </>
            )}>
                <Row>
                    <Col>
                        <h2 className="mb-4">Добър ден, уважаеми кандидати</h2>
                        <img className="float-lg-right ml-lg-4 mb-4" src={careersPlaceholder} alt="Property Control Placeholder" />
                        <p>Агентите на недвижими имоти се причисляват към лекарите, стоматолозите, адвокатите, счетоводителите и специалистите по финансово планиране в класацията на квалифицираните професионалисти, които предлагат насоки и съвети на клиентите си. Голямата разлика е в това, че повечето агенти не гледат на себе си като на първокласни професионалисти. Една голяма част от тях и голяма част от обществото възприема агентите или като екскурзоводи по недвижими имоти, или като доставчици на каталози с имущества за продан, или просто като неизбежна брънка по веригата на покупко-продажбата на имоти. Всъщност агентите на недвижими имоти са доверени представители, както и финансови и имуществени съветници – а не хора, на които се плаща да отключват входните врати на жилищата.</p>
                        <p>Ние ще ви научим да:</p>
                        <ul>
                            <li>Имате увереност в услугата и ползите, които предоставяте на клиентите си.</li>
                            <li>Предоставяте съществена професионална услуга.</li>
                            <li>Получавате признание като ценен професионалист, какъвто сте.</li>
                            <li>Представлявате интересите на клиентите си по най-добрите световни практики.</li>
                            <li>Имате репутация, етика и задължение да работите от името на клиента си.</li>
                            <li>Постигнете поставените и желаните резултати.</li>
                            <li>Работите като добър доверен представител, като дадете откровен и благонадежден съвет.</li>
                        </ul>
                        <p>Вашата работа е:</p>
                        <ul>
                            <li>Да бъдете активен, обикновено са необходими поне 60 телефонни разговора и 6 срещи с клиенти на ден, за да може да се превърнете в успешен агент.</li>
                            <li>Да работите с една от най-добрите база данни на имоти в България.</li>
                            <li>Да приемате с отворено съзнание нови подходи в бизнеса, да общувате свободно с хора във всяка ситуация.</li>
                            <li>Да предлагате откровен съвет, така че клиентите ви да постигнат желаните резултати, свързани с имотите си.</li>
                            <li>Да насочвате клиентите си към добрите решения, свързани със стойността на техните жилища, с ценовата стратегия, която трябва да се приеме.</li>
                        </ul>
                        <p>Ние предпочитаме да имате:</p>
                        <ul>
                            <li>Отговорност когато съветвате клиентите си при решенията им, свързани с недвижими имоти, съветът ви има дългосрочни ефекти върху финансовата стабилност и благосъстояние на клиентите ви.</li>
                            <li>Спокойно отношение, подход, базиран на търсенето на решения.</li>
                            <li>Богата сфера от лични контакти.</li>
                            <li>Способност да насочвате клиентите си към имоти, които съответстват на техните нужди и желания.</li>
                            <li>Непреклонна вяра в успеха и добрия изход от всяка ситуация.</li>
                        </ul>
                        <p>Възнаграждение:</p>
                        <p>Агентите на недвижими имоти са професионалистите с един от най-високите доходи по цял свят. При нас вие ще правите наистина добри пари.</p>
                        <p>Освен Твърда Заплата Вие получавате голям процент от всяка сделка, по която участвате. В повечето случаи става дума за хиляди.</p>
                        <p>За нас:</p>
                        <p>Ние сме компания, амбициозен екип, имаме опит както на пазара в България, така и в чужбина. Ние харесваме успешните. Очакваме да се запознаем!</p>
                    </Col>
                </Row>
            </InnerPageContent>
            <LastSeenProperties />
        </main>
    );
}

export default Careers;