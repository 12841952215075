import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import InnerPageTop from '../components/sections/InnerPageTop';
import AddPropertyForm from '../components/sections/AddPropertyForm';
import LastSeenProperties from '../components/sections/LastSeenProperties';

// Styles
import '../scss/add-property.scss';

const AddProperty = () => {
    return (
        <main className="add-property">
            <Helmet>
                <title>Добави Имот - Перфект Консулт</title>
            </Helmet>
            <InnerPageTop pageTitle="Добави Имот" pageDescription="Добавете имот и експерт от екипа на Перфект Консулт 2009 ще се свърже с вас, за да уточним детайлите и предстоящите стъпки, които са нужни за успешно позициониране на пазара." />
            <AddPropertyForm />
            <LastSeenProperties />
        </main>
    );
}

export default AddProperty;
