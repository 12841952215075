export const UPDATE_DATA = 'update-data';
export const UPDATE_FILTERS_SIZE = 'update-filters-size';
export const UPDATE_FILTERS_PRICE = 'update-filters-price';
export const UPDATE_FILTERS_YEAR = 'update-filters-year';
export const UPDATE_FILTERS_TYPES = 'update-filters-types';
export const UPDATE_FILTERS_CONSTRUCTION = 'update-filters-construction';
export const UPDATE_FILTERS_HEATING = 'update-filters-heating';
export const UPDATE_FILTERS_FURNISHING = 'update-filters-furnishing';
export const UPDATE_FILTERS_FACING = 'update-filters-facing';
export const UPDATE_FILTERS_STAGE = 'update-filters-stage';
export const UPDATE_FILTERS_OTHER = 'update-filters-other';
export const UPDATE_SELECTED_AREA = 'update-selected-area';
export const UPDATE_SELECTED_SUB_AREA = 'update-selected-sub-area';
export const UPDATE_OFFER_TYPE = 'update-offer-type';
export const UPDATE_PAGE = 'update-page';
export const UPDATE_PER_PAGE = 'update-per-page';
export const UPDATE_SORT = 'update-sort';
export const UPDATE_ORDER = 'update-order';
export const UPDATE_SEARCH = 'update-search';
export const CLEAR_FILTERS = 'clear-filters';