import React, { useState, useEffect } from 'react';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

// Styles
import '../scss/custom-checkbox.scss';

const CustomCheckbox = ({ className, title, value, initSelected = false, onCheck = () => { }, onUncheck = () => { } }) => {
    const [state, setState] = useState({
        selected: initSelected,
        value: value
    });

    const optionalClasses = `${className ? ' ' + className : ''}${state.selected ? ' selected' : ''}`;

    const selectedHandler = () => {
        setState(currState => {
            return {
                ...currState,
                selected: !state.selected
            }
        });
    }

    useEffect(() => {
        setState(currState => {
            return {
                ...currState,
                selected: initSelected,
            }
        })
        //eslint-disable-next-line
    }, [initSelected])

    useEffect(() => {
        if (state.selected) {
            onCheck(state.value);
        } else {
            onUncheck(state.value);
        }
        //eslint-disable-next-line
    }, [state.selected])

    return (
        <button type="button" className={`custom-checkbox${optionalClasses}`} onClick={selectedHandler}>
            <div className="custom-checkbox__check"><FontAwesomeIcon icon={faCheck} /></div>
            <div className="custom-checkbox__title">{title}</div>
        </button>
    );
}

export default CustomCheckbox;
