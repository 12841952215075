// React Components
import React, { memo } from 'react';
//eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

// Components
import Nav from '../Nav';
import Social from '../Social';

// Hooks
import { useScroll } from '../../hooks/useScroll';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Styles
import '../../scss/header.scss';

// Assets
import Logo from '../../assets/images/logo.png';

// Main
const Header = () => {
    //eslint-disable-next-line
    const { scrollY } = useScroll();
    const isScrolled = window.scrollY > 50;

    return (
        <header className={isScrolled ? 'header scrolled' : 'header'}>
            <Container>
                <Row className="align-items-center">
                    <Col className="header__logo-col col-sm-4 col-xl-2">
                        <Link className="header__logo" to="/"><img className="header__logo-img" src={Logo} alt="Logo" /></Link>
                    </Col>
                    <Col className="header__nav-col">
                        <Nav className="mr-xl-2 order-2 order-xl-0" />
                        <Social className="header__social mr-3 mr-xl-4 d-none d-lg-flex" />
                        <a className="header__phone d-none d-sm-inline-flex" href={`tel:${process.env.REACT_APP_PHONE_HREF}`}><FontAwesomeIcon icon={faPhoneAlt} />{process.env.REACT_APP_PHONE}</a>
                    </Col>
                </Row>
            </Container>
        </header>
    )
}

export default memo(Header);