import React from 'react';
import { Helmet } from 'react-helmet';
//eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

// Components
import LastSeenProperties from '../components/sections/LastSeenProperties';
import InnerPageTop from '../components/sections/InnerPageTop';
import InnerPageContent from '../components/sections/InnerPageContent';
import IconStepCard from '../components/IconStepCard';
import InquiryForm from '../components/InquiryForm';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Icons
import {
    faThumbsUp,
    faHourglass,
    faLaptopHouse,
    faUserPlus,
    faCommentsDollar,
    faBusinessTime,
    faHandsHelping
} from '@fortawesome/free-solid-svg-icons';

// Styles
import '../scss/services.scss';

const WhyPerfectConsult = () => {
    return (
        <main className="services why-perfect-consult">
            <Helmet>
                <title>Защо да изберем Перфект Консулт? - Перфект Консулт</title>
            </Helmet>
            <InnerPageTop pageTitle="Защо да изберем Перфект Консулт?" pageDescription="С какво се отличаваме от нашите конкуренти." />
            <InnerPageContent asideChildren={(
                <>
                    <InquiryForm page="Страница Защо да изберем Перфект Консулт?" title="Искаш безплатна консултация?" description="Изпрати въпросите си към нас." placeholder={`Здравейте имам въпрос...`} messageRows="5" />
                </>
            )}>
                <Row>
                    <Col className="col-12 mb-4">
                        <p>Изберете да работите с нас и ще спечелите качествени услуги с перфектно обслужване. Ние сме изградили пред годините екип от професионалисти, с които може да бъдете спокойни за вашите пари и решения. Нашата цел е клиентите ни да стават с всеки изминал ден все по доволни и многобройни. Потърсете ни и оставете всичко в наши ръце.</p>
                    </Col>
                    {whyPerfectConsultPoints.map((item, index) => (
                        <Col className="col-12 mb-4">
                            <IconStepCard className="icon-step-card--green icon-step-card--wide" number={index + 1} icon={item.icon} title={item.title} description={item.description} />
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col>
                        <p>На официалната ни страница са публикувани нашите <Link className="link" to="/terms-and-conditions">„Общи условия за осъществяване на посредническа дейност при сделки с недвижими имоти и за защита на личните данни на клиента”</Link>, където открито, ясно и точно са посочени нашите ангажименти - високо качество на юридическото обслужване и контрол на всеки етап от сделката, правата и отговорностите на нашите клиенти.</p>
                    </Col>
                </Row>
            </InnerPageContent>
            <LastSeenProperties />
        </main>
    );
}

export default WhyPerfectConsult;

const whyPerfectConsultPoints = [
    {
        icon: faThumbsUp,
        title: 'Без риск. Не заплащате нищо предварително.',
        description: 'Всички инвестиции по рекламата и предлагането са за наша сметка. Вие заплащате стандартно комисионно възнаграждение само при сключване на сделка.',
    },
    {
        icon: faHourglass,
        title: 'Печелите време.',
        description: 'Делегирайте права на компанията ни и разполагайте със свободното си време. Ние правим всичко вместо вас.',
    },
    {
        icon: faLaptopHouse,
        title: 'Ние предлагаме хиляди актуални оферти.',
        description: 'Всеки, който се обърне към нас със запитване за имот достига моментално до мрежата ни от консултанти, които персонално подбират за него най-добрите оферти.',
    },
    {
        icon: faUserPlus,
        title: 'Ние регистрираме стотици потенциални купувачи и наематели ежедневно.',
        description: 'Благодарение на модерната си информационна система, клиентите ни имат достъп до актуални оферти с подробна информация и снимки от всички видове имоти.',
    },
    {
        icon: faCommentsDollar,
        title: 'Комплексност на услугите',
        description: 'Ние се ангажираме да предоставим на клиентите си добри съвети, които ще им помогнат да сключат най-изгодната за тях сделка. Съдействаме за получаването на банков кредит, осигуряваме пълно юридическо обслужване и даваме актуална пазарна информация за района, който ви интересува.',
    },
    {
        icon: faBusinessTime,
        title: 'Ние работим в удобно за клиентите време',
        description: 'с възможност за консултации и огледи след работно време и през почивните дни.',
    },
    {
        icon: faHandsHelping,
        title: 'Съветваме ви дори след изповядване на сделката',
        description: 'Ние ще останем ваш доверен партньор дори след като приключим със сделката. Винаги ще сме насреща, ако изникнат някакви въпроси.',
    },
]