import React from 'react';
//eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styles
import '../scss/service-page-card.scss';

const ServicePageCard = ({ className, title = '', description = '', btnColor = 'orange', btnIcon, btnText = 'Button', btnTo = '/' }) => {
    return (
        <div className={`service-page-card${className ? ' ' + className : ''}`}>
            <div className="service-page-card__title">{title}</div>
            <div className="service-page-card__description">{description}</div>
            <Link className={`btn${btnColor ? ' btn--' + btnColor : ''}`} to={btnTo}><FontAwesomeIcon icon={btnIcon} />{btnText}</Link>
        </div>
    );
}

export default ServicePageCard;
