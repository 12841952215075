import React from 'react';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

// Styles
import '../scss/no-results.scss';

const NoResults = () => {
    return (
        <div className="no-results">
            <div className="no-results__icon"><FontAwesomeIcon icon={faTimesCircle} /></div>
            <div className="no-results__text">Не са открити резултати!</div>
        </div>
    );
}

export default NoResults;
