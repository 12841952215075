import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import LastSeenProperties from '../components/sections/LastSeenProperties';
import InnerPageTop from '../components/sections/InnerPageTop';
import InnerPageContent from '../components/sections/InnerPageContent';
import ServicePageCard from '../components/ServicePageCard';
import InquiryForm from '../components/InquiryForm';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Assets
import propertyControlPlaceholder from '../assets/images/property-control-placeholder.jpg';

// Icons
import {
    faStar,
    faUserTie,
    faHandshake,
    faHouseUser,
} from '@fortawesome/free-solid-svg-icons';

// Styles
import '../scss/services.scss';

const PropertyControl = () => {
    return (
        <main className="services property-control">
            <Helmet>
                <title>Управление на Имот - Перфект Консулт</title>
            </Helmet>
            <InnerPageTop pageTitle="Управление на Имот" pageDescription="Увеличете шансовете за успешна продажба на вашия имот!" />
            <InnerPageContent asideChildren={(
                <>
                    <InquiryForm page="Страница Управление на Имот" title="Искаш безплатна консултация?" description="Изпрати въпросите си към нас." placeholder={`Здравейте имам въпрос относно услуга.`} messageRows="5" />
                    <ServicePageCard title="Допълнителни услуги" description="Разгледайте комплексните решения, създадени за ваше удобство." btnColor="dark" btnIcon={faStar} btnText="Допълнителни Услуги" btnTo="/services/additional" />
                    <ServicePageCard title="За собственици" description="Разгледайте комплексните решения, създадени за ваше удобство." btnColor="cyan" btnIcon={faHouseUser} btnText="За Собственици" btnTo="/services/for-owners" />
                    <ServicePageCard title="Купуваме директно" description="Най-лесният начин да продадеш имота си в няколко стъпки." btnColor="orange" btnIcon={faHandshake} btnText="Купуваме директно" btnTo="/services/we-buy-directly" />
                    <ServicePageCard title="За инвеститори" description="Разгледайте комплексните решения, създадени за ваше удобство." btnColor="green" btnIcon={faUserTie} btnText="За инвеститори" btnTo="/services/for-investors" />
                </>
            )}>
                <Row>
                    <Col>
                        <h2 className="mb-4 block-heading block-heading--red">Какво представлява услугата "Управление на Имот"?</h2>
                        <img className="float-lg-right ml-lg-4 mb-4" src={propertyControlPlaceholder} alt="Property Control Placeholder" />
                        <p>При услугата <b>"Управление на Имот"</b>, собственика има възможност да определи цената на имота, дори тя да е завишена спрямо пазарната. В такъв случай, <b>„Перфект Консулт“</b> не може да поеме гаранция за заетост, но всички останали ангажименти са в сила от страна на фирмата.</p>
                        <p>Ние предлагаме най-ниската такса управление на пазара .Таксата за управление в този вариант е 10% от месечния наем, в случаите когато имота е зает.</p>
                        <p>Пример:  Договорен наем 400лв./ месец. – 40лв (10% такса обслужване) = 360лв чиста сума за получаване.</p>
                        <p>Услугите включват списък с 35 анагажимента за които се грижим. Част от тях са: проверки на плащания, инспекции на състоянието на имота, комуникация с домоуправители и касиери, казуси с комунални дружества, вкл. надписани сметки, жалби, молби, контрол по ремонти и поддръжка и още много. Перфект Консулт  2009 ЕООД  писмено гарантира за имота Ви. Предоставя Ви се договор, където се описва всяка точка по която фирмата ни гарантира за поетите ангажименти:</p>
                        <ul>
                            <li>Състоянието на имота</li>
                            <li>Липса на консумативни задължения</li>
                            <li>Плащане към домоуправителя на етажната собственост</li>
                            <li>Плащане на наема към собственика в тридневен срок след получаването му от наемателя</li>
                            <li>Съдействие за пълното отстраняване на щети, причинени от наематели</li>
                            <li>Актуализация на наемната цена спрямо пазарната обстановка</li>
                            <li>Инспекции на имота</li>
                        </ul>
                        <p>Може да ни се доверите тъй като сме доказали коректността на компанията, можем да гарантираме с цифри: в рамките на последните 10 години <b>Перфект Консулт</b> е работила с хиляди клиенти.</p>
                    </Col>
                </Row>
            </InnerPageContent>
            <LastSeenProperties />
        </main>
    );
}

export default PropertyControl;
