import React from 'react';

// Components
import ArrowLink from './ArrowLink';
import Slider from './Slider';

// Styles
import '../scss/card-slider.scss';

const CardSlider = ({ className, children, settings, linkText, linkUrl }) => {
    const hasLink = linkText || linkUrl;

    return (
        <div className={`card-slider${className ? ' ' + className : ''}${hasLink ? '' : ' card-slider--no-link'}`}>
            <Slider settings={settings}>
                {children}
            </Slider>
            {hasLink ? <ArrowLink to={linkUrl}>{linkText}</ArrowLink> : null}
        </div>
    );
}

export default CardSlider;
