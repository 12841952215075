import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import LastSeenProperties from '../components/sections/LastSeenProperties';
import InnerPageTop from '../components/sections/InnerPageTop';
import InnerPageContent from '../components/sections/InnerPageContent';
import ServicePageCard from '../components/ServicePageCard';
import ServiceCard from '../components/ServiceCard';
import IconStepCard from '../components/IconStepCard';
import InquiryForm from '../components/InquiryForm';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faKey,
    faEye,
    faStar,
    faPlus,
    faMinus,
    faUserTie,
    faThumbsUp,
    faPiggyBank,
    faHouseUser,
    faHandshake,
    faBalanceScale,
    faFileSignature,
    faHandHoldingUsd,
    faFileInvoiceDollar
} from '@fortawesome/free-solid-svg-icons';

// Styles
import '../scss/services.scss';

const WeBuyDirectly = () => {
    return (
        <main className="services we-buy-directly">
            <Helmet>
                <title>Купуваме Директно - Перфект Консулт</title>
            </Helmet>
            <InnerPageTop pageTitle="Купуваме Директно" pageDescription="Най-лесният начин да продадеш имота си в няколко стъпки." />
            <InnerPageContent asideChildren={(
                <>
                    <InquiryForm page="Страница Купуваме Директно" title="Искаш безплатна консултация?" description="Изпрати въпросите си към нас." placeholder={`Здравейте имам въпрос относно услуга.`} messageRows="5" />
                    <ServicePageCard title="Допълнителни услуги" description="Разгледайте комплексните решения, създадени за ваше удобство." btnColor="dark" btnIcon={faStar} btnText="Допълнителни Услуги" btnTo="/services/additional" />
                    <ServicePageCard title="За собственици" description="Разгледайте комплексните решения, създадени за ваше удобство." btnColor="cyan" btnIcon={faHouseUser} btnText="За Собственици" btnTo="/services/for-owners" />
                    <ServicePageCard title="Управление на Имот" description="Разгледайте комплексните решения, създадени за ваше удобство." btnColor="red" btnIcon={faKey} btnText="Управление на Имот" btnTo="/services/propety-control" />
                    <ServicePageCard title="За инвеститори" description="Разгледайте комплексните решения, създадени за ваше удобство." btnColor="green" btnIcon={faUserTie} btnText="За инвеститори" btnTo="/services/for-investors" />
                </>
            )}>
                <Row>
                    <Col className="col-12">
                        <h2 className="block-heading block-heading--green">Най-лесният начин да продадеш имота си в няколко стъпки:</h2>
                    </Col>
                    {propertySaleSteps.map((item, index) => (
                        <Col className="col-12 col-lg-6 mb-4">
                            <IconStepCard className="icon-step-card--green" number={index + 1} icon={item.icon} description={item.description} />
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col className="col-12">
                        <h2 className="block-heading block-heading--orange">Какво печелиш, когато избереш Перфект Консулт?</h2>
                    </Col>
                    {whatYouWin.map(service => (
                        <Col className="col-12 col-lg-6 mb-4">
                            <ServiceCard icon={service.icon} title={service.title} description={service.description} />
                        </Col>
                    ))}
                </Row>
                <Row className="advantages-list">
                    <Col className="col-12 col-md-6 mb-2 mb-md-0 advantages-list__col advantages-list__col--positive">
                        <h3 className="advantages-list__heading">Сделка с Перфект Консулт</h3>
                        <ul className="ul-clean">
                            {dealPerfectConsult.map(item => (
                                <li className="advantages-list__item mb-2">
                                    <div className="advantages-list__content"><span className="advantages-list__icon"><FontAwesomeIcon icon={faPlus} /></span>{item.description}</div>
                                </li>
                            ))}
                        </ul>
                    </Col>
                    <Col className="col-12 col-md-6 advantages-list__col advantages-list__col--negative">
                        <h3 className="advantages-list__heading">Традиционна Сделка</h3>
                        <ul className="ul-clean">
                            {dealStandard.map(item => (
                                <li className="advantages-list__item mb-2">
                                    <div className="advantages-list__content"><span className="advantages-list__icon"><FontAwesomeIcon icon={faMinus} /></span>{item.description}</div>
                                </li>
                            ))}
                        </ul>
                    </Col>
                </Row>
            </InnerPageContent>
            <LastSeenProperties />
        </main>
    );
}

export default WeBuyDirectly;

const propertySaleSteps = [
    {
        icon: faFileSignature,
        description: 'Попълваш регистрационната форма и изпращаш запитване.',
    },
    {
        icon: faHouseUser,
        description: 'Наш представител ще се свърже с теб и ще посети имота в удобно време.',
    },
    {
        icon: faFileInvoiceDollar,
        description: 'До 24 часа получаваш нашата оферта.',
    },
    {
        icon: faHandshake,
        description: 'Свързваме се с теб за да получиш своето капаро и за да уточним всички детайли по приключване на сделката.',
    },
    {
        icon: faHandHoldingUsd,
        description: 'Получаваш пълната сума за имота си.',
    },
]

const whatYouWin = [
    {
        icon: faEye,
        title: 'Прозрачност',
        description: 'Имаш пълна яснота през целия процес!'
    },
    {
        icon: faBalanceScale,
        title: 'Справедлива оферта',
        description: 'Ще изчислим спрямо пазара!'
    },
    {
        icon: faPiggyBank,
        title: 'Удобство',
        description: 'Разполагай с парите си веднага без ангажимент по сделката!'
    },
    {
        icon: faThumbsUp,
        title: 'Имаш планове?',
        description: 'Освободи имота, когато пожелаеш!'
    },
]

const dealPerfectConsult = [
    {
        description: 'Няма значение състоянието на имота. Ние ще се погрижим за него.'
    },
    {
        description: 'Не губиш време и пари за лична реклама на имота. Ние ще го рекламираме вместо теб!'
    },
    {
        description: 'Посещението за оценка на имота е еднократно и в удобно за теб време.'
    },
    {
        description: 'Разходите ви се равняват на 0%. Няма скрити такси, данъци или комисионни.'
    },
    {
        description: 'Сам решаваш кога да изповядаш сделката и да освободиш жилището.'
    },
    {
        description: 'Сделката ще е в кратък срок.'
    },
    {
        description: 'Ние ще свършим всичко до приключване на сделката.'
    },
    {
        description: 'Няма да има нужда да се извършва експертна оценка.'
    },
    {
        description: 'Плащането ти е гарантирано на 100%!'
    },
]

const dealStandard = [
    {
        description: 'Трябва да правиш ремонти основни или освежаващ. Трябва да чистиш за да има имотът ти търговски вид. Харчиш куп излишни средства!'
    },
    {
        description: 'Дълго съставяш атрактивна обява, плащаш за нея, отговаряш на хилядите запитвания.'
    },
    {
        description: 'Куп непознати хора влизат в дома ти. Съмнителни и несериозни купувачи искат безброй ненужни огледи. Телефонът ти прегрява от обаждания. Постоянно си под напрежение, че трябва графикът ти да е съобразен с хилядите огледи.'
    },
    {
        description: 'Хиляди левове ненужни разходи = 3% брокерска комисионна.'
    },
    {
        description: 'Зависиш от настроението на купувача или чакаш одобрение от банката му.'
    },
    {
        description: 'В най-добрият случай седмици докато намериш купувач и след това поне месец докато изповядаш сделката.'
    },
    {
        description: 'Сам организираш всичко, обикаляш по всички институции и губиш време в бюрократщина.'
    },
    {
        description: 'Ако купувачът ти има нужда от кредит, за да закупи имота ти, то трябва да бъде изготвена оценка от експерт- оценител, която оценка да определи цената и размера на кредита.'
    },
    {
        description: 'Купувачът ти може да не получи кредит или оценката да е прекалено ниска и да има финансови затруднение. И в двата случая сделката отпада.'
    },
]