import React from 'react';

// Styles
import '../scss/section-heading.scss';

const SectionHeading = ({ className, heading, subHeading }) => {
    return (
        <div className={`section-heading${className ? ' ' + className : ''}`}>
            <h2 className="section-heading__heading">{heading}</h2>
            {subHeading ? <p className="section-heading__sub-heading">{subHeading}</p> : null}
        </div>
    );
}

export default SectionHeading;
