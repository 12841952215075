import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import InnerPageTop from '../components/sections/InnerPageTop';
import ContactForm from '../components/sections/ContactForm';
import LastSeenProperties from '../components/sections/LastSeenProperties';

// Styles
import '../scss/contacts.scss';

const Contacts = () => {
    return (
        <main className="contacts">
            <Helmet>
                <title>Контакти - Перфект Консулт</title>
            </Helmet>
            <InnerPageTop pageTitle="Контакти" pageDescription="Свържете се с нас или ни посетете." />
            <ContactForm />
            <LastSeenProperties />
        </main>
    );
}

export default Contacts;
