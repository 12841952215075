import React from 'react';
import { Helmet } from 'react-helmet';
//eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

// Components
import InnerPageTop from '../components/sections/InnerPageTop';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Styles
import '../scss/sitemap.scss';

const Sitemap = () => {
    return (
        <main className="sitemap">
            <Helmet>
                <title>Карта на Сайта - Перфект Консулт</title>
            </Helmet>
            <InnerPageTop pageTitle="Карта на Сайта" pageDescription="Тук можете да откриете пълна карта на сайта." />
            <section className="my-4">
                <Container>
                    <Row>
                        <Col>
                            <h2>Карта на Сайта</h2>
                            <ul>
                                <li><Link className="link" to="/">Начало</Link></li>
                                <li><Link className="link" to="/properties">Имоти</Link></li>
                                <ul className="ul-clean">
                                    <li><Link className="link" to="/properties?forRent=true">Под Наем</Link></li>
                                    <li><Link className="link" to="/properties?forSale=true">За Продажба</Link></li>
                                </ul>
                                <li><Link className="link" to="/add-property">Добави Имот</Link></li>
                                <li><Link className="link" to="/services">Услуги</Link></li>
                                <ul className="ul-clean">
                                    <li><Link className="link" to="/services/additional">Допълнителни Услуги</Link></li>
                                    <li><Link className="link" to="/services/for-owners">За Собственици</Link></li>
                                    <li><Link className="link" to="/services/we-buy-directly">Купуваме Директно</Link></li>
                                    <li><Link className="link" to="/services/propety-control">Управление на Имот</Link></li>
                                    <li><Link className="link" to="/services/for-investors">За Инвеститори</Link></li>
                                </ul>
                                <li><Link className="link" to="/reviews">Отзиви</Link></li>
                                <li><Link className="link" to="/careers">Кариери</Link></li>
                                <li><Link className="link" to="/why-perfect-consult">Защо да изберем Перфект Консулт?</Link></li>
                                <li><Link className="link" to="/blog">Блог</Link></li>
                                <li><Link className="link" to="/contacts">Контакти</Link></li>
                                <li><Link className="link" to="/terms-and-conditions">Условия за Ползване</Link></li>
                                <li><Link className="link" to="/privacy-policy">Декларация за Поверителност</Link></li>
                                <li><Link className="link" to="/sitemap">Карта на Сайта</Link></li>
                                <li><Link className="link" to="/support">Проблеми със Сайта</Link></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
}

export default Sitemap;
