export const UPDATE_DATA = 'update-data';
export const UPDATE_POPULAR_DATA = 'update-popular-data';
export const UPDATE_NEWS_DATA = 'update-news-data';
export const UPDATE_TAGS = 'update-tags';
export const UPDATE_ARTICLE = 'update-article';
export const UPDATE_LAST_SEEN = 'update-last-seen';
export const UPDATE_PAGE = 'update-page';
export const UPDATE_PER_PAGE = 'update-per-page';
export const UPDATE_SORT = 'update-sort';
export const UPDATE_ORDER = 'update-order';
export const UPDATE_SEARCH = 'update-search';