// React Components
import React, { useState, useEffect } from 'react';
//eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link, useLocation } from 'react-router-dom';

// Hooks
import { useViewportWidth } from '../hooks/useViewportWidth';

// Components
import NavDropdown from './NavDropdown';
import Social from './Social';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

// Styles
import '../scss/nav.scss';

// Main
const Nav = ({ className }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const viewportWidth = useViewportWidth();
    const location = useLocation();

    const isLaptop = viewportWidth < 1240;
    // const isTablet = viewportWidth < 992;
    // const isMobile = viewportWidth < 576;

    useEffect(() => {
        if (menuOpen) {
            document.body.classList.add('menu-open');
        } else {
            document.body.classList.remove('menu-open');
        }
    }, [menuOpen])

    useEffect(() => {
        setMenuOpen(false)
    }, [location, isLaptop])

    const handleMenuOpen = () => setMenuOpen(currMenuOpen => !currMenuOpen);
    const handleMenuClose = () => setMenuOpen(false);
    const isCurrent = path => location.pathname === path ? ' current' : '';

    return (
        <div className={`nav${className ? ' ' + className : ''}${isLaptop ? ' nav--tablet' : ''}${isLaptop && menuOpen ? ' visible' : ''}`}>
            <nav className="nav-wrapper" id="navigation" role="navigation">
                <Link className={`nav__link${isCurrent('/')}`} to="/">Начало</Link>
                <NavDropdown mainLink={<Link className={`nav__link${isCurrent('/properties')}`} to="/properties">Имоти</Link>} isLaptop={isLaptop} onWrapperClose={menuOpen}>
                    <Link className={`nav__link${isCurrent('/add-property')}`} to="/add-property">Добави Имот</Link>
                    <Link className={`nav__link${isCurrent('/properties?forRent=true')}`} to="/properties?forRent=true">Под Наем</Link>
                    <Link className={`nav__link${isCurrent('/properties?forSale=true')}`} to="/properties?forSale=true">За Продажба</Link>
                </NavDropdown>
                <NavDropdown mainLink={<Link className={`nav__link${isCurrent('/services')}`} to="/services">Услуги</Link>} isLaptop={isLaptop} onWrapperClose={menuOpen}>
                    <Link className={`nav__link${isCurrent('/services/for-owners')}`} to="/services/for-owners">За Собственици</Link>
                    <Link className={`nav__link${isCurrent('/services/we-buy-directly')}`} to="/services/we-buy-directly">Купуваме Директно</Link>
                    <Link className={`nav__link${isCurrent('/services/propety-control')}`} to="/services/propety-control">Управление на Имот</Link>
                    <Link className={`nav__link${isCurrent('/services/for-investors')}`} to="/services/for-investors">За Инвеститори</Link>
                    <Link className={`nav__link${isCurrent('/services/additional')}`} to="/services/additional">Допълнителни Услуги</Link>
                </NavDropdown>
                <NavDropdown title="За Нас" isLaptop={isLaptop} onWrapperClose={menuOpen}>
                    <Link className={`nav__link${isCurrent('/reviews')}`} to="/reviews">Отзиви</Link>
                    <Link className={`nav__link${isCurrent('/careers')}`} to="/careers">Кариери</Link>
                    <Link className={`nav__link${isCurrent('/why-perfect-consult')}`} to="/why-perfect-consult">Защо да изберем Перфект Консулт?</Link>
                </NavDropdown>
                <Link className={`nav__link${isCurrent('/blog')}`} to="/blog">Блог</Link>
                <Link className={`nav__link${isCurrent('/contacts')}`} to="/contacts">Контакти</Link>
                <a className="header__phone mt-4 d-inline-flex d-sm-none" href={`tel:${process.env.REACT_APP_PHONE_HREF}`}><FontAwesomeIcon icon={faPhoneAlt} />{process.env.REACT_APP_PHONE}</a>
                <Social className="nav__social mt-3 mb-4 d-flex d-lg-none" />
            </nav>

            {isLaptop ? (
                <>
                    <div className="nav-overlay" onClick={handleMenuClose}></div>
                    <button className={`hamburger hamburger--squeeze${menuOpen ? ' is-active' : ''}`} type="button" aria-label="Menu" aria-controls="navigation" onClick={handleMenuOpen}>
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>
                </>
            ) : null}
        </div>
    );
}

export default Nav;
