// React Components
import React, { useState, useEffect } from 'react';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

// Styles
import '../scss/nav-dropdown.scss';

const NavDropdown = ({ className, children, title, mainLink, isLaptop, onWrapperClose }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        if (isLaptop) {
            setIsOpen(!isOpen);
        }
    }

    const handleFocus = () => {
        if (!isLaptop) {
            setIsOpen(true);
        }
    }

    const handleBlur = e => {
        const currentTarget = e.currentTarget;

        setTimeout(() => {
            if (!currentTarget.contains(document.activeElement)) {
                setIsOpen(false);
            }
        }, 0);
    }

    const handleMouseEnter = () => {
        if (!isLaptop) {
            setIsOpen(true);
        }
    }

    const handleMouseLeave = () => {
        if (!isLaptop) {
            setIsOpen(false);
        }
    }

    useEffect(() => {
        setIsOpen(false);
    }, [isLaptop, onWrapperClose])

    return (
        <div className={`nav-dropdown${className ? ' ' + className : ''}${isOpen ? ' open' : ''}`} onClick={handleClick} onFocus={handleFocus} onBlur={handleBlur} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} tabIndex="0">
            <div className="nav-dropdown__main">
                {mainLink ? mainLink : <span className="nav-dropdown__title">{title}</span>}
                <span className="nav-dropdown__icon"><FontAwesomeIcon icon={faAngleDown} /></span>
            </div>
            <div className="nav-dropdown__dropdown">
                {children}
            </div>
        </div>
    );
}

export default NavDropdown;
